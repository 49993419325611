import Login from "@/views/auth/Login.vue";
import OAuthLogin from "@/views/auth/OauthLogin.vue";
import { Route } from "vue-router";
import { RouteMeta } from "../types";

// All of these routes have this meta setup in common
const meta: RouteMeta = {
  disallowAuthed: true,
  appClass: "appModeLogin",
  appBar: "none"
};

export default [
  {
    // Reset Password
    path: "/resetpass/:userId/:token",
    name: "ResetPass",
    component: () => import(/* webpackChunkName: "auth" */ "@/views/auth/ResetPassword.vue"),
    meta,
    props: (route: Route): Record<string, any> => ({
      userId: route.params.userId,
      token: route.params.token,
      mode: "resetpass"
    })
  },
  {
    // Reset Password
    path: "/newpass/:userId/:token",
    name: "NewPass",
    component: () => import(/* webpackChunkName: "auth" */ "@/views/auth/ResetPassword.vue"),
    meta,
    props: (route: Route): Record<string, any> => ({
      userId: route.params.userId,
      token: route.params.token,
      mode: "newpass"
    })
  },
  {
    // Reset Password
    path: "/resetpass",
    name: "ResetPassRequest",
    component: () => import(/* webpackChunkName: "auth" */ "@/views/auth/RequestPasswordReset.vue"),
    meta
  },
  {
    // Login page
    path: "/login",
    name: "Login",
    component: Login,
    meta
  },
  {
    // CSR Login page
    path: "/csr",
    name: "CSRLogin",
    component: OAuthLogin,
    meta
  }
];
