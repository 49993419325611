import { UserType } from "@/config";

import NewQuery from "@/views/NewQuery/NewQueryTwoColumn.vue";

export default [
  {
    path: "/newQuery",
    name: "NewQuery",
    // Not yet for team consumption...
    component: NewQuery,
    meta: {
      // appClass: process.env.VUE_APP_PROD ? undefined : "appModeUserRight",
      appBar: "user",
      hideFooter: true,
      disallowedUsers: [UserType.CLIENT], // TO Be removed
      appClass: "appModeUser"
    }
  },
  {
    path: "/newQuery/:userId/:draftId?",
    name: "NewQueryProxy",
    // Not yet for team consumption...
    component: NewQuery,
    meta: {
      // appClass: process.env.VUE_APP_PROD ? undefined : "appModeUserRight",
      appBar: "user",
      hideFooter: true,
      allowedUsers: [UserType.CSR, UserType.BLINDED],
      appClass: "appModeUser"
    },
    props: true
  }
];

