/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Query Filters List
 */
export type QueryFilters = {
    /**
     * Comma delimited list of 1:many Ids.
     */
    queryIds?: string;
    /**
     * Comma delimited list of 1:many Ids.
     */
    membershipPlanIds?: string;
    /**
     * Comma delimited list of 1:many Ids.
     */
    grantIds?: string;
    /**
     * Comma delimited list of 1:many Ids.
     */
    poolIds?: string;
    /**
     * User with any access to the query.
     */
    userId?: number;
    /**
     * Comma delimited list of 1:many Ids.
     */
    userTypes?: string;
    /**
     * Owner of query.
     */
    ownerId?: number;
    /**
     * Comma delimited list of 1:many Ids.
     */
    diagnosisGroupIds?: string;
    /**
     * Comma delimited list of 1:many Ids.
     */
    diagnosisDomainIds?: string;
    /**
     * Comma delimited list of 1:many Ids.
     */
    planIds?: string;
    /**
     * Comma delimited list of 1:many Ids.
     */
    diagnosisIds?: string;
    /**
     * Comma delimited list of 1:many Ids.
     */
    userTagIds?: string;
    /**
     * Comma delimited list of 1:many Ids.
     */
    teamTagIds?: string;
    /**
     * Comma delimited list of 1:many Ids.
     */
    globalTagIds?: string;
    /**
     * Query Status
     */
    status?: QueryFilters.status;
    /**
     * Query Type
     */
    type?: QueryFilters.type;
    /**
     * Media Type
     */
    mediaType?: QueryFilters.mediaType;
    /**
     * Find records searching by Text. Use ID to get specific Query.
     */
    text?: string;
    /**
     * Search Start time - Unix Timestamp
     */
    dateSearchStart?: number;
    /**
     * Search End time - Unix Timestamp
     */
    dateSearchEnd?: number;
    /**
     * Meeting Start time for range selection - Unix Timestamp
     */
    meetingStart?: number;
    /**
     * Meeting End time for range selection - Unix Timestamp
     */
    meetingEnd?: number;
    /**
     * Start time for range of invitation
     */
    inviteStart?: number;
    /**
     * End time for range of invitation
     */
    inviteEnd?: number;
    /**
     * Created Start time - range selection - Unix Timestamp
     */
    createdStart?: number;
    /**
     * Created End time - range selection - Unix Timestamp
     */
    createdEnd?: number;
    /**
     * Show only Queries for Teams user is part of
     */
    teamQueriesOnly?: boolean;
}

export namespace QueryFilters {

    /**
     * Query Status
     */
    export enum status {
        OPEN = 'open',
        CLOSED = 'closed',
        ANSWERED = 'answered',
        HELD = 'held',
        PENDING_CHARGE = 'pending_charge',
        CHARGED = 'charged',
        CREATE = 'create',
        CSRHOLD = 'csrhold',
        CANCELLED = 'cancelled',
        PENDING_SURVEY = 'pending_survey',
    }

    /**
     * Query Type
     */
    export enum type {
        NORMAL = 'normal',
        BLINDED = 'blinded',
        COMMUNAL = 'communal',
        PRIVATE = 'private',
    }

    /**
     * Media Type
     */
    export enum mediaType {
        MESSENGER = 'messenger',
        AUDIO = 'audio',
        VIDEO = 'video',
    }


}
