import { UserType } from "@/config";

export default [
  {
    path: "/admin/reports/expertQueryStats",
    name: "ExpertAggStats",
    component: () => import(/* webpackChunkName: "reports" */ "@/views/admin/reports/ExpertQueryStats.vue"),
    meta: { allowedUsers: [UserType.CSR] }
  },
  {
    path: "/admin/reports/common",
    name: "CommonReports",
    component: () => import(/* webpackChunkName: "reports" */ "@/views/admin/reports/CommonReports.vue"),
    meta: { allowedUsers: [UserType.CSR] }
  },
  {
    path: "/admin/reports/queryStats",
    name: "QueryAggStats",
    component: () => import(/* webpackChunkName: "reports" */ "@/views/admin/reports/QueryAggregateStats.vue"),
    meta: { allowedUsers: [UserType.CSR] }
  }
];
