/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * User Notification Preferences
 */
export type NotificationPreferencesRecordItem = {
    /**
     * Record Unique ID
     */
    id?: number;
    /**
     * User ID
     */
    userId?: number;
    /**
     * Preferred contact method
     */
    preferred?: NotificationPreferencesRecordItem.preferred;
    /**
     * Do not disturb start time
     */
    dndStartTime?: string;
    /**
     * Do not disturb end time
     */
    dndEndTime?: string;
    /**
     * API Response Version
     */
    _ver?: number;
}

export namespace NotificationPreferencesRecordItem {

    /**
     * Preferred contact method
     */
    export enum preferred {
        EMAIL = 'email',
        MOBILE = 'mobile',
        APP = 'app',
    }


}
