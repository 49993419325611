/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExpertAggregate } from '../models/ExpertAggregate';
import type { ExpertQueryDetail } from '../models/ExpertQueryDetail';
import type { meta } from '../models/meta';
import type { QueryAggregate } from '../models/QueryAggregate';
import type { ReportDefinition } from '../models/ReportDefinition';
import { request as __request } from '../core/request';

export class ReportService {

    /**
     * Return a list of available reports.
     * Retuens the format described in eventHandlers.php
     * @result ReportDefinition successful operation
     * @throws ApiError
     */
    public static async listCommonExports(): Promise<Array<ReportDefinition>> {
        const result = await __request({
            method: 'GET',
            path: `/server/report/commonExports`,
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Prepare a common report given the provided options.
     * Triggers the preparation and delivery of a common report.
     * @result any successful operation
     * @throws ApiError
     */
    public static async prepareCommonReport({
        report,
        format,
        diagnosisIds,
        startTime,
        endTime,
        includeCsr = false,
        surgeOnly = false,
        diagnosisGroupId,
        sort,
    }: {
        /** Report Identifier  **/
        report: string,
        /** Format of report, if applicable. xls or csv  **/
        format?: string,
        /** Comma sepaarate list if diagnosis ids  **/
        diagnosisIds?: string,
        /** Start time for range selection  **/
        startTime?: number,
        /** End time for range selection  **/
        endTime?: number,
        /** Include CSR items  **/
        includeCsr?: boolean,
        /** Include Only Queries released during a surge **/
        surgeOnly?: boolean,
        /** Find records associated with a given Diagnosis Group **/
        diagnosisGroupId?: number,
        /** Sort Options - Comma delimited list of key:order. order optional - default ASC  **/
        sort?: string,
    }): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/server/report/commonExports`,
            query: {
                'report': report,
                'format': format,
                'diagnosisIds': diagnosisIds,
                'startTime': startTime,
                'endTime': endTime,
                'includeCsr': includeCsr,
                'surgeOnly': surgeOnly,
                'diagnosisGroupId': diagnosisGroupId,
                'sort': sort,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * List Experts query stats, filterable by diagnosis, diagnosis group, and date range
     * Returns one or more matching records
     * @result any successful operation
     * @throws ApiError
     */
    public static async getExpertAggregateStats({
        diagnosisIds,
        startTime,
        endTime,
        includeCsrAdds = false,
        inviteAction,
        diagnosisGroupId,
        userId,
        planIds,
        sort,
        page = 1,
        pageSize = 10,
    }: {
        /** Comma delimited list of diagnosis ids.  Match to any  **/
        diagnosisIds?: string,
        /** Start time for range selection **/
        startTime?: number,
        /** End time for range selection **/
        endTime?: number,
        /** Include CSR added experts  **/
        includeCsrAdds?: boolean,
        /** Experts Action for the Invite **/
        inviteAction?: 'accept' | 'decline' | 'missedout' | 'cancelled' | 'closed' | 'invited',
        /** Find records associated with a given Diagnosis Group **/
        diagnosisGroupId?: number,
        /** Find records associated with a given User **/
        userId?: number,
        /** Comma delimited list of plan ids.  Match to any  **/
        planIds?: string,
        /** Sort Options - Comma delimited list of key:order. order optional - default ASC  **/
        sort?: string,
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
    }): Promise<{
        data?: Array<ExpertAggregate>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/report/expertaggstats`,
            query: {
                'diagnosisIds': diagnosisIds,
                'startTime': startTime,
                'endTime': endTime,
                'includeCsrAdds': includeCsrAdds,
                'inviteAction': inviteAction,
                'diagnosisGroupId': diagnosisGroupId,
                'userId': userId,
                'planIds': planIds,
                'sort': sort,
                'page': page,
                'pageSize': pageSize,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * List Experts query stats, filterable by diagnosis, diagnosis group, and date range
     * Returns one or more matching records
     * @result any successful operation
     * @throws ApiError
     */
    public static async downloadExpertAggregateStats({
        diagnosisIds,
        startTime,
        endTime,
        includeCsrAdds = false,
        diagnosisGroupId,
        sort,
    }: {
        /** Comma sepaarate list if diagnosis ids  **/
        diagnosisIds?: string,
        /** Start time for range selection  **/
        startTime?: number,
        /** End time for range selection  **/
        endTime?: number,
        /** Include CSR added experts  **/
        includeCsrAdds?: boolean,
        /** Find records associated with a given Diagnosis Group **/
        diagnosisGroupId?: number,
        /** Sort Options - Comma delimited list of key:order. order optional - default ASC  **/
        sort?: string,
    }): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/server/report/download/expertaggstats`,
            query: {
                'diagnosisIds': diagnosisIds,
                'startTime': startTime,
                'endTime': endTime,
                'includeCsrAdds': includeCsrAdds,
                'diagnosisGroupId': diagnosisGroupId,
                'sort': sort,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * List Experts query detail records for a single user
     * Returns one or more matching records
     * @result any successful operation
     * @throws ApiError
     */
    public static async getExpertStatsDetails({
        id,
        diagnosisIds,
        startTime,
        endTime,
        includeCsrAdds = false,
        diagnosisGroupId,
        sort,
        page = 1,
        pageSize = 10,
    }: {
        /** Record ID **/
        id: number,
        /** Comma sepaarate list if diagnosis ids  **/
        diagnosisIds?: string,
        /** Start time for range selection  **/
        startTime?: number,
        /** End time for range selection  **/
        endTime?: number,
        /** Include CSR added experts  **/
        includeCsrAdds?: boolean,
        /** Find records associated with a given Diagnosis Group **/
        diagnosisGroupId?: number,
        /** Sort Options - Comma delimited list of key:order. order optional - default ASC  **/
        sort?: string,
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
    }): Promise<{
        data?: Array<ExpertQueryDetail>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/report/expertdetailstats/${id}`,
            query: {
                'diagnosisIds': diagnosisIds,
                'startTime': startTime,
                'endTime': endTime,
                'includeCsrAdds': includeCsrAdds,
                'diagnosisGroupId': diagnosisGroupId,
                'sort': sort,
                'page': page,
                'pageSize': pageSize,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * List query stats, filterable by diagnosis, diagnosis group, and date range
     * Returns one or more matching records
     * @result any successful operation
     * @throws ApiError
     */
    public static async getQueryAggregateStats({
        groupBy,
        startTime,
        endTime,
        diagnosisDomainId,
        diagnosisGroupId,
        diagnosisIds,
        planIds,
        grantIds,
        countries,
        queryTypes,
        planTypes,
        userTypes,
        queryCategories,
        sort,
        page = 1,
        pageSize = 10,
    }: {
        /** Group Query Results **/
        groupBy?: 'diagnosis' | 'diagnosisGroup' | 'diagnosisDomain' | 'userType' | 'country' | 'planType' | 'plan' | 'grant',
        /** Start time for range selection **/
        startTime?: number,
        /** End time for range selection **/
        endTime?: number,
        /** Find records associated with a given Diagnosis Domain **/
        diagnosisDomainId?: number,
        /** Find records associated with a given Diagnosis Group **/
        diagnosisGroupId?: number,
        /** Comma delimited list of diagnosis ids.  Match to any  **/
        diagnosisIds?: string,
        /** Comma delimited list of plan ids.  Match to any  **/
        planIds?: string,
        /** Comma delimited list of grant ids.  Match to any  **/
        grantIds?: string,
        /** Comma delimited list of country abbreviations  **/
        countries?: string,
        /** Comma delimited list of Query Types.  Match to any **/
        queryTypes?: string,
        /** Comma delimited list of Plan Types.  Match to any  **/
        planTypes?: string,
        /** Comma delimited list of User Type Ids.  Match to any **/
        userTypes?: string,
        /** Comma delimited list of query categories **/
        queryCategories?: string,
        /** Sort Options - Comma delimited list of key:order. order optional - default ASC  **/
        sort?: string,
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
    }): Promise<{
        data?: Array<QueryAggregate>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/report/queryaggstats`,
            query: {
                'groupBy': groupBy,
                'startTime': startTime,
                'endTime': endTime,
                'diagnosisDomainId': diagnosisDomainId,
                'diagnosisGroupId': diagnosisGroupId,
                'diagnosisIds': diagnosisIds,
                'planIds': planIds,
                'grantIds': grantIds,
                'countries': countries,
                'queryTypes': queryTypes,
                'planTypes': planTypes,
                'userTypes': userTypes,
                'queryCategories': queryCategories,
                'sort': sort,
                'page': page,
                'pageSize': pageSize,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * List query details, filterable by diagnosis, diagnosis group, and date range
     * Returns one or more matching records
     * @result any successful operation
     * @throws ApiError
     */
    public static async getQueryAggregateDetail({
        groupBy,
        startTime,
        endTime,
        diagnosisDomainId,
        diagnosisGroupId,
        diagnosisId,
        planId,
        grantId,
        diagnosisIds,
        planIds,
        grantIds,
        countries,
        queryTypes,
        planTypes,
        userTypes,
        queryCategories,
        sort,
        page = 1,
        pageSize = 10,
    }: {
        /** Group Query Results **/
        groupBy?: 'diagnosis' | 'diagnosisGroup' | 'diagnosisDomain' | 'userType' | 'country' | 'planType' | 'plan' | 'grant',
        /** Start time for range selection **/
        startTime?: number,
        /** End time for range selection **/
        endTime?: number,
        /** Find records associated with a given Diagnosis Domain **/
        diagnosisDomainId?: number,
        /** Find records associated with a given Diagnosis Group **/
        diagnosisGroupId?: number,
        /** Find records associated with a given Diagnosis  **/
        diagnosisId?: number,
        /** Find records associated with a given Plan **/
        planId?: number,
        /** Find records associated with a given Grant **/
        grantId?: number,
        /** Comma delimited list of diagnosis ids.  Match to any  **/
        diagnosisIds?: string,
        /** Comma delimited list of plan ids.  Match to any  **/
        planIds?: string,
        /** Comma delimited list of grant ids.  Match to any  **/
        grantIds?: string,
        /** Comma delimited list of country abbreviations  **/
        countries?: string,
        /** Comma delimited list of Query Types.  Match to any **/
        queryTypes?: string,
        /** Comma delimited list of Plan Types.  Match to any  **/
        planTypes?: string,
        /** Comma delimited list of User Type Ids.  Match to any **/
        userTypes?: string,
        /** Comma delimited list of query categories **/
        queryCategories?: string,
        /** Sort Options - Comma delimited list of key:order. order optional - default ASC  **/
        sort?: string,
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
    }): Promise<{
        data?: Array<QueryAggregate>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/report/queryaggdetail`,
            query: {
                'groupBy': groupBy,
                'startTime': startTime,
                'endTime': endTime,
                'diagnosisDomainId': diagnosisDomainId,
                'diagnosisGroupId': diagnosisGroupId,
                'diagnosisId': diagnosisId,
                'planId': planId,
                'grantId': grantId,
                'diagnosisIds': diagnosisIds,
                'planIds': planIds,
                'grantIds': grantIds,
                'countries': countries,
                'queryTypes': queryTypes,
                'planTypes': planTypes,
                'userTypes': userTypes,
                'queryCategories': queryCategories,
                'sort': sort,
                'page': page,
                'pageSize': pageSize,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

}