/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChatBotEvent } from '../models/ChatBotEvent';
import type { ChatBotResponse } from '../models/ChatBotResponse';
import { request as __request } from '../core/request';

export class NewQueryService {

    /**
     * Create a new Query
     * Supports a chat-bot interaction for a new query
     * @result ChatBotResponse successful operation
     * @throws ApiError
     */
    public static async newQueryChat({
        requestBody,
    }: {
        /** Interaction Event **/
        requestBody: ChatBotEvent,
    }): Promise<ChatBotResponse> {
        const result = await __request({
            method: 'POST',
            path: `/user/newQuery`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Create a new Query for userId
     * Supports a chat-bot interaction for a new query
     * @result ChatBotResponse successful operation
     * @throws ApiError
     */
    public static async newQueryChatProxy({
        userId,
        requestBody,
    }: {
        /** Create Query for this user **/
        userId: number,
        /** Interaction Event **/
        requestBody: ChatBotEvent,
    }): Promise<ChatBotResponse> {
        const result = await __request({
            method: 'POST',
            path: `/user/newQuery/${userId}`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

}