/* TODO Some of these items may be better loaded from server at login time so they can be dynaimc at the server level and not require a
  new client build. These are good candidates for the global store, and may be a part of the login process. */

// User types from server
export const UserType = {
  CLIENT: 1,
  EXPERT: 2,
  CSR: 3,
  NETWORK: 5,
  VIRTUAL: 6,
  PLAN_MANAGER: 11,
  BLINDED: 12
};

export const PlanTypeName: Record<string, string> = {
  census: "Census",
  sso: "SSO",
  region: "Region",
  advocate: "Advocate",
  blinded: "Blinded",
  expert: "Expert",
  vtb: "Virtual Tumor Board"
};

export const Languages: Record<string, any> = {
  en: {
    name: "English",
    nativeName: "English",
    locale: "en-us"
  },

  zh_CN: {
    name: "Chinese (Simplified)",
    nativeName: "简体中文",
    locale: "zh-cn"
  }
};

// Define icons. Name values are localization keys
export const MainIcons = {
  home: {
    icon: "$home",
    name: "nav.home",
    to: { name: "Home" },
    exact: true
  },
  discussions: {
    icon: "$discussions",
    name: "nav.discussions",
    to: {
      name: "Queries"
    }
  },
  support: {
    icon: "$support",
    name: "nav.support",
    to: { name: "Support" }
  },
  account: {
    icon: "$account",
    name: "nav.account",
    to: { name: "Account" }
  },
  logout: {
    icon: "$admin",
    name: "nav.account"
    // to: { name: "Account" }
  }
  /*
  admin: {
    icon: "$admin",
    name: "nav.admin",
    to: { name: "Admin" }
  }
  */
};

export const CommonReportOpts = {
  FORMAT_CSV: 1,
  FORMAT_XLS: 2,
  DATE_RANGE: 4,
  USER_TYPES: 8,
  BLINDED_OPTION: 16,
  OMIT_CSR: 32,
  SURGE_ONLY: 64,
  MONTH_YEAR: 128
}

export const Domains = {
  CEN: "cancerexpertnow",
  ONVIV: "onviv",
  INSIGHT: "noeticinsight"
}
