<template>
  <div class="cb-message" v-if="message">
    <div
      class="cb-message--content"
      :class="{
        sent: !isBot,
        received: isBot
      }"
    >
      <div class="cb-message--text" :class="styles">
        <div class="cb-message--text-content" ref="textDiv" :class="{ expanded }">
          <div v-if="message.type == 'text'" v-text="displayText" />
          <div v-else v-html="displayText" />
        </div>

        <!-- <p v-if="message.type == 'text'" class="cb-message--text-content" ref="textDiv" v-text="displayText" />
        <div v-else class="cb-message--text-content" ref="htmlDiv" v-html="displayText" /> -->
        <div ref="overflowSentinel" style="height: 1px"></div>
      </div>
      <div class="d-flex justify-end">
        <caption-text
          v-if="overflow || expanded"
          actionContext="showMore"
          :text="expanded ? 'Show Less' : 'Show More'"
          @actionClick="expanded = !expanded"
        />
        <caption-text
          v-if="message.actionContext"
          :actionContext="message.actionContext"
          text="Edit"
          @actionClick="$emit('actionClick', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ChatBotMessage } from "@/service";
import { ThemeStoreInterface } from "@/store/theme/types";
import Vue, { PropType, VueConstructor } from "vue";
import { createHelpers } from "vuex-typescript-interface";
import CaptionText from "./CaptionText.vue";
const { mapGetters } = createHelpers<ThemeStoreInterface>();
const getters = mapGetters(["getReplacementText"]);

export default (Vue as VueConstructor<
  Vue & {
    $refs: {
      overflowSentinel: InstanceType<typeof HTMLElement>;
      textDiv: InstanceType<typeof HTMLElement>;
      htmlDiv: InstanceType<typeof HTMLElement>;
    };
  }
>).extend({
  name: "TextMessage",
  components: {
    CaptionText
  },
  props: {
    message: {
      type: Object as PropType<ChatBotMessage>
    }
  },
  data: () => ({
    expanded: false,
    overflow: false
  }),
  computed: {
    ...getters,
    isBot(): boolean {
      return !this.message?.user;
    },
    styles(): Record<string, boolean> {
      return {
        textFormat: this.message.type == "text",
        htmlFormat: this.message.type == "html",
        bannerFormat: this.message.type == "banner"
      };
    },
    displayText(): string {
      return this.patternMatcher(this.message.content ?? "");
    }
  },
  methods: {
    patternMatcher(content: string): string {
      return content.replace(/::([^:]+)::/g, (m, s) => {
        return this.getReplacementText(s);
      });
    },
    check(): void {
      const el = this.$refs.textDiv;

      this.overflow = el ? el.clientHeight < el.scrollHeight : false;
    }
  },
  mounted() {
    this.check();
  },
  watch: {
    message: {
      handler() {
        this.check();
      }
    }
  }
});
</script>

<style lang="scss" scoped>
@import "@/scss/local.scss";

.cb-message--text {
  padding: 12px 20px;
  box-shadow: $o-secondary-shadow;
  border-radius: 6px;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.4;
  position: relative;
  -webkit-font-smoothing: subpixel-antialiased;
  .cb-message--text-content {
    min-width: 130px;
    max-height: 300px;
    overflow-y: hidden;
    white-space: pre-wrap;
    margin: 4px 0;
    :deep(p) {
      margin-bottom: 0px;
    }
  }
  .cb-message--text-content.expanded {
    max-height: inherit;
  }
}

.cb-message--content.sent .cb-message--text {
  color: white;
  background-color: var(--v-primary-base);
  max-width: calc(100% - 120px);
  word-wrap: break-word;
}

.cb-message--content.received .cb-message--text {
  color: #263238;
  background-color: #F2E3FF; //#D4C8DE; // #f4f7f9;
  margin-right: 40px;
}
</style>