<template>
  <action :action="action" showText xshowText="!event" v-on="$listeners" v-resize="onResize">
    <template v-slot:default="{ show }">
      <div v-if="show && action.choices && !event">
        <v-expand-transition>
          <div class="d-flex flex-row align-baseline">
            <v-autocomplete
              ref="autocomplete"
              v-model="selected"
              item-text="name"
              content-class="t-grey"
              style="flex:1"
              persistent-hint
              return-object
              :items="action.choices"
              :hint="action.hint || ' '"
              :menu-props="{ 'max-width': listMenuWidth }"
              @click="onResize"
            >
              <template v-slot:item="{ item }">
                <template>
                  <v-list-item-content>
                    <v-list-item-title class="text-wrap">
                      {{ typeof item !== 'object' ? item : item.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
            <v-btn text :disabled="!selected" @click="click">Select</v-btn>
          </div>
        </v-expand-transition>
      </div>
    </template>
  </action>
</template>

<script lang="ts">
import Vue, { VueConstructor, PropType } from "vue";
import { ChatBotAction, ChatBotChoice, ChatBotEvent } from "@/service";
import Action from "./Action.vue";
import { VAutocomplete } from "vuetify/lib";

export default (Vue as VueConstructor<
  Vue & {
    $refs: {
      autocomplete: InstanceType<typeof VAutocomplete>;
    };
  }
>).extend({
  name: "AutoCompleteActionComponent",
  components: {
    Action
  },
  props: {
    action: {
      type: Object as PropType<ChatBotAction>,
      required: true
    }
  },
  data: () => ({
    event: undefined as ChatBotEvent | undefined,
    selected: undefined as ChatBotChoice | undefined,
    listMenuWidth: undefined as number | undefined
  }),
  computed: {
    mode(): string {
      return "vertical";
    }
  },
  methods: {
    click(): void {
      if (this.selected) {
        this.event = {
          type: "respond",
          ts: Date.now(),
          clientData: this.action.clientData,
          responses: [this.selected.value]
        };
        this.$emit("callback", this.event);
      }
    },
    onResize() {
      this.listMenuWidth = this.$refs.autocomplete?.$el.clientWidth || undefined
    }
  },
  created() {
    if (this.action.responses?.length && this.action.responses[0] && this.action.choices) {
      this.selected = this.action.choices.find(c => c.value == this.action.responses![0]);
    }
  }
});
</script>

<style lang="scss" scoped>
// There is some issue with scoped styles in slotes with v-if
.chat-bot-action-buttons {
  display: flex;
  &.horizontal {
    flex-direction: row;
    justify-content: space-evenly;
  }
  &.vertical {
    flex-direction: column;
    align-items: center;
    .chat-bot-button {
      margin-bottom: 4px;
    }
  }
}
</style>
