<template>
  <v-sheet class="o-sheet" :class="classes" :style="sheetStyle" v-bind="$attrs">
    <slot></slot>
  </v-sheet>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "OSheet",
  props: {
    shadow: {
      type: String,
      default: "primary"
    },
    border: {
      type: String,
      default: ""
    },
    borderWidth: {
      type: Number,
      default: 0
    }
  },
  computed: {
    classes(): Record<string, any> {
      const cl: Record<string, any> = {
        primaryShadow: this.shadow !== "secondary",
        secondaryShadow: this.shadow === "secondary" && this.border === ""
      };
      if (this.border.trim() !== "") {
        cl["border--" + this.border] = true;
        cl["border-weight--bold"] = this.shadow !== "secondary";
      }
      return cl;
    },
    sheetStyle(): Record<string, string> {
      let st: Record<string, string> = {};
      if (this.borderWidth) {
        st["border-width"] = `${this.borderWidth}px !important`;
      }
      return st;
    }
  }
});
</script>

<style lang="scss" scoped>
@import "@/scss/local.scss";
.o-sheet {
  border-radius: $o-border-radius;
  padding: 16px;
}
.primaryShadow {
  box-shadow: $o-primary-shadow;
}
.secondaryShadow {
  box-shadow: $o-secondary-shadow;
}
</style>
