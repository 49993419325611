<template>
  <div class="mx-sm-12">
    <v-card
      class="mx-auto mt-12 pb-10 login-card"
      style="background-color: rgba(0,0,0,0) !important; border-radius: 10px"
      elevation="0"
      x-rounded="xl"
      max-width="530"
    >
      <v-card-title v-text="title"> </v-card-title>
      <v-container class="pt-0">
        <v-row>
          <v-col><slot /> </v-col>
        </v-row>
        <v-row
          ><v-col class="text-center text--sm" style="color:rgba(0,0,0,0.5)">
            {{ $t("loginScreen.terms-1") }}
            <a :href="`${$t('theme.url')}/terms-of-use`" target="_blank" id="loginTerms" rel="noopener">{{
              $t("loginScreen.terms-2a", { _company_: $t("theme.company") })
            }}</a
            >.
          </v-col></v-row
        >
      </v-container>
      <v-overlay absolute :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-card>
    <slot name="below"></slot>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  props: {
    title: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
});
</script>

<style lang="scss" scoped>
.login-card {
  background-color: transparent !important;
}
</style>
