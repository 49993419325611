<template>
  <action :action="action" showText xshowText="!event" v-on="$listeners">
    <template v-slot:default="{ show }">
      <div v-if="show">
        <v-expand-transition>
          <div v-if="!event && action.choices">
            <v-list>
              <v-list-item-group v-model="selected" multiple active-class="">
                <v-list-item v-for="(c, i) in action.choices" :key="i">
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox :input-value="active"></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title v-text="c.name" />
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <v-btn text @click="select" :disabled="!hasMinimum">
              {{ minCount && !hasMinimum ? `Choose at least ${minCount}` : "Done" }}
            </v-btn>
          </div>
        </v-expand-transition>
      </div></template
    >
  </action>
</template>

<script lang="ts">
import { ChatBotAction, ChatBotChoice, ChatBotEvent } from "@/service";
import Vue, { PropType } from "vue";
import Action from "./Action.vue";

export default Vue.extend({
  name: "ChooseMultipleActionComponent",
  components: {
    Action
  },
  props: {
    action: {
      type: Object as PropType<ChatBotAction>,
      required: true
    }
  },
  data: () => ({
    event: undefined as ChatBotEvent | undefined,
    selected: []
  }),
  computed: {
    minCount(): number {
      return this.action?.minimum ?? 1;
    },
    hasMinimum(): boolean {
      return this.selected.length >= this.minCount;
    }
  },
  methods: {
    select(): void {
      // TODO Add option to require at least one
      this.event = {
        type: "respond",
        ts: Date.now(),
        clientData: this.action.clientData,
        // action: this.action,
        responses: this.action.choices ? this.selected.map(i => (this.action.choices as ChatBotChoice[])[i].value) : []
      };
      this.$emit("callback", this.event);
    }
  }
});
</script>

<style lang="scss" scoped>
.action-buttons {
  display: flex;

  &.horizontal {
    flex-direction: row;
    justify-content: space-evenly;
  }
  &.vertical {
    flex-direction: column;
  }
}
</style>
