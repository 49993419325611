import Vue from "vue";
import { Module, StateFor } from "vuex-typescript-interface";
import { Document } from "@prismicio/client/types/documents";
import { Languages } from "@/config";
import { DashboardStoreInterface } from "./types";
import { RootStoreInterface } from "../types";

const dashboard: Module<DashboardStoreInterface, RootStoreInterface> = {
  state: {
    document: undefined
  },

  getters: {
    currentDocument: state => state.document,
    docContent: state => state.document?.data,
    docUpdated: state => state.document?.last_publication_date || undefined,
    docLanguage: state => state.document?.lang,
    docId: state => state.document?.id
  },

  mutations: {
    setDocument(state: StateFor<DashboardStoreInterface>, doc: Document | undefined): void {
      state.document = doc;
    }
  },

  actions: {
    async loadCms(
      { commit, rootState, rootGetters },
      docType: string
    ): Promise<Document | undefined> {
      if (!docType || !docType.length) return undefined;
      try {
        const currentUserType = rootGetters.activeUserType;
        if (!currentUserType || !(rootGetters.userTypes[currentUserType] ?? null)) {
          commit("setDocument", undefined);
          return undefined;
        }
        const currentLocale = Languages[rootState.lang]?.locale ?? "en-us";
        const userType = rootGetters.userTypes[currentUserType].toLowerCase().replace(' ', '')
        const prismic = Vue.prototype.$prismic;
        const query = [
          prismic.Predicates.at("document.type", docType),
          prismic.Predicates.at(`my.${docType}.user-types.user-type`, userType),
        ];
        let resp = await prismic.client.query(query, {
          lang: currentLocale,
          orderings: "[document.last_publication_date desc]"
        });
        if ((!resp || !resp.results.length) && currentLocale != "en-us") {
          resp = await prismic.client.query(query, {
            lang: "en-us",
            orderings: "[document.last_publication_date desc]"
          });
        }
        const doc = resp?.results?.length ? resp.results[0] : undefined;
        commit("setDocument", doc);
        return doc;
      } catch (e) {
        console.log({ e });
        commit("setDocument", undefined);
        return undefined;
      }
    },
    async clearCms({ commit }): Promise<void> {
      commit("setDocument", undefined);
    }
  }
};

export default dashboard;
