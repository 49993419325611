/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AttachmentRecordItem } from '../models/AttachmentRecordItem';
import type { AttachmentRecordItemUpdate } from '../models/AttachmentRecordItemUpdate';
import type { meta } from '../models/meta';
import { request as __request } from '../core/request';

export class UserfilesService {

    /**
     * Retreive an existing user document
     * Retreive a document record based on data given
     * @result AttachmentRecordItem successful operation
     * @throws ApiError
     */
    public static async getUserDocument({
        id,
        download = false,
    }: {
        /** Record ID **/
        id: number,
        /** Download file **/
        download?: boolean,
    }): Promise<AttachmentRecordItem> {
        const result = await __request({
            method: 'GET',
            path: `/user/files/${id}`,
            query: {
                'download': download,
            },
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update an existing user document
     * Update a document record based on data given
     * @result AttachmentRecordItem successful operation
     * @throws ApiError
     */
    public static async updateUserDocument({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** Document object that needs to be updated **/
        requestBody: AttachmentRecordItemUpdate,
    }): Promise<AttachmentRecordItem> {
        const result = await __request({
            method: 'PUT',
            path: `/user/files/${id}`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Delete an existing user document
     * Delete a document record based on data given
     * @result any successful operation
     * @throws ApiError
     */
    public static async deleteUserDocument({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/user/files/${id}`,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Get list of user uploaded documents.  Filterable by patient, uploader, region, created date, document name, document desc, repo, stored file name.
     * Sort by ID, Date, Name and Description.
     * Returns one or more matching records
     * @result any successful operation
     * @throws ApiError
     */
    public static async getDocumentList({
        userId,
        name,
        description,
        createdStart,
        createdEnd,
        activeOnly = true,
        uploadedById,
        repoId,
        repoFileName,
        page = 1,
        pageSize = 10,
        sort,
    }: {
        /** Find records associated with a given User **/
        userId?: number,
        /** Find records searching by Name.  Enter ID for specific record **/
        name?: string,
        /** Find records searching by description. **/
        description?: string,
        /** Start time for range of record creation **/
        createdStart?: number,
        /** End time for range of record creation **/
        createdEnd?: number,
        /** Records to Include based on Active Flag. **/
        activeOnly?: boolean,
        /** Search by user that uploaded file **/
        uploadedById?: number,
        /** Files in a specific document repository **/
        repoId?: number,
        /** Filename as stored in the document repository **/
        repoFileName?: string,
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
        /** Sort Options - Comma delimited list of key:order. order optional - default ASC  **/
        sort?: string,
    }): Promise<{
        data?: Array<AttachmentRecordItem>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/user/files/list`,
            query: {
                'userId': userId,
                'name': name,
                'description': description,
                'createdStart': createdStart,
                'createdEnd': createdEnd,
                'activeOnly': activeOnly,
                'uploadedById': uploadedById,
                'repoId': repoId,
                'repoFileName': repoFileName,
                'page': page,
                'pageSize': pageSize,
                'sort': sort,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Get File Upload URL
     * Get File Upload Url
     * @result any successful operation
     * @throws ApiError
     */
    public static async getFileUploadUrl({
        requestBody,
    }: {
        /** Post **/
        requestBody: {
            /**
             * Target User Id
             */
            userId: number,
            /**
             * File Name
             */
            filename: string,
            /**
             * Description
             */
            desc?: string,
        },
    }): Promise<{
        /**
         * Document
         */
        id: number,
        /**
         * Document
         */
        url: string,
        /**
         * Content Type
         */
        mimeType?: string,
    }> {
        const result = await __request({
            method: 'POST',
            path: `/user/files/upload`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

}