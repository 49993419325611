import { ThemeDefinition } from "./types";

export const defaultTheme = "onviv";

export const Themes: Record<string, ThemeDefinition> = {
  cen: {
    name: "CEN",
    colors: {
      primary: "#754199",
      secondary: "#8C377D",
      tertiary: "#664D87",

      // Pill Color/Highlight on Time Picker
      accent1: "#EBDAF7",
      accent2: "#F&DAF2",
      accent3: "#EBDAF7"
    },
    text: {
      name: "Cancer Expert Now",
      company: "CancerExpertNow, Inc.",
      url: "https://www.cancerexpertnow.com",
      domain: "www.cancerexpertnow.com",
      careNavigator: "carenavigator@cancerexpertnow.com"
    },
    logo: "/assets/themes/CEN/cen-onviv-logo.png",
    favicon: "",
    accent: "/assets/themes/CEN/0002_corner-element-CEN.png",
    accentFactor: 1,
    match: /cancerexpertnow\.com$/
  },
  insight: {
    name: "NoeticInsight",
    colors: {
      primary: "#754199",
      secondary: "#8C377D",
      tertiary: "#664D87",

      // Pill Color/Highlight on Time Picker
      accent1: "#EBDAF7",
      accent2: "#F&DAF2",
      accent3: "#EBDAF7"
    },
    text: {
      name: "NoeticInsight",
      company: "NoeticInsight, LLC",
      url: "https://www.noeticinsight.com",
      domain: "www.noeticinsight.com",
      careNavigator: "carenavigator@noeticinsight.com"
    },
    logo: "/assets/themes/Noetic/ni-onviv-logo.png",
    favicon: "",

    accent: "/assets/themes/Noetic/0003_corner-element-NI-wht.png",
    accentFactor: 1.5,

    // match: /localhost$/
    match: /noeticinsight\.com$/
  },
  onviv: {
    name: "Onviv",
    colors: {
      primary: "#754199",
      secondary: "#8C377D",
      tertiary: "#664D87",

      // Pill Color/Highlight on Time Picker
      accent1: "#EBDAF7",
      accent2: "#F&DAF2",
      accent3: "#EBDAF7"
    },
    text: {
      name: "Onviv",
      company: "Onviv, Inc.",
      url: "https://www.onviv.com",
      domain: "www.onviv.com",
      careNavigator: "carenavigator@onviv.com"
    },
    logo: "/assets/themes/Onviv/ONVIV-logo.png",
    favicon: "",

    accent: "/assets/themes/Onviv/0000_corner-element-Onviv-wht.png",
    accentFactor: 1.5,

    match: /onviv\.com$/
  }
};
