<template>
  <login-page>
    <login-card></login-card>
  </login-page>
</template>

<script lang="ts">
import Vue from "vue";
import LoginCard from "@/components/auth/OAuthLoginCard.vue";
import LoginPage from "./LoginPage.vue";

import { createHelpers } from "vuex-typescript-interface";
import { RootStoreInterface } from "@/store/types";

const { mapGetters } = createHelpers<RootStoreInterface>();

const getters = mapGetters(["isLoggedIn", "user"]);

export default Vue.extend({
  components: { LoginCard, LoginPage },
  computed: {
    ...getters
  }
});
</script>
