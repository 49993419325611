<template>
  <div>
    <div class="d-flex align-baseline my-4">
      <div v-if="icon" class="mr-2 text-center" style="min-width: 26px;">
        <v-icon color="secondary" :size="iconSize">{{ icon }}</v-icon>
      </div>
      <div class="t-4 font-weight-bold">{{ title }}</div>
      <v-spacer />
      <div>
        <o-btn variant="secondary" :to="to">{{ $t("captions.go") }}</o-btn>
      </div>
    </div>
    <v-divider v-if="divider" class="ml-8 flex-0" />
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import OBtn from "@/framework/OBtn.vue";

export default Vue.extend({
  components: {
    OBtn
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      required: false
    },
    iconSize: {
      type: Number,
      required: false
    },
    to: {
      type: Object,
      required: false
    },
    divider: {
      type: Boolean,
      default: false
    }
  }
});
</script>
