/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * TagsDefinition
 */
export type TagsDefinitionRecordItem = {
    /**
     * Record Unique ID
     */
    id?: number;
    /**
     * Tags Definition name
     */
    name?: string;
    /**
     * Tags Definition Description
     */
    desc?: string;
    /**
     * Created by UserId
     */
    userId?: number;
    /**
     * Definition Type
     */
    type?: TagsDefinitionRecordItem.type;
    /**
     * Optional String Validator
     */
    validator?: TagsDefinitionRecordItem.validator;
    /**
     * Flag for record being active
     */
    active?: boolean;
    /**
     * Flag for record being default definition
     */
    default?: boolean;
    /**
     * Flag for record being shown even if not assigned to an object
     */
    showOnAll?: boolean;
    /**
     * Definition Scope Entity Type
     */
    entityType?: TagsDefinitionRecordItem.entityType;
    /**
     * Definition Scope Entity Sub-Type
     */
    entityTypeSub?: string;
    /**
     * Current user allowed to delete?  Helper field.  Confirmation logic in backend.
     */
    userCanDelete?: boolean;
    /**
     * Optional field Min.  Will be length for string, values for int, and selections for enum.
     */
    min?: number;
    /**
     * Optional field Max.  Will be length for string, values for int, and selections for enum.
     */
    max?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    created?: number;
    /**
     * Options for field.  Required for field type Enum.  Json encoded Array.  Will also accept comma seperated list for processing.
     */
    enumOptions?: Array<any>;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    updated?: number;
    /**
     * Scope of the tag
     */
    scope?: TagsDefinitionRecordItem.scope;
    /**
     * API Response Version
     */
    _ver?: number;
}

export namespace TagsDefinitionRecordItem {

    /**
     * Definition Type
     */
    export enum type {
        STRING = 'string',
        INT = 'int',
        FLOAT = 'float',
        ENUM = 'enum',
        BOOLEAN = 'boolean',
        TIMESTAMP = 'timestamp',
    }

    /**
     * Optional String Validator
     */
    export enum validator {
        EMAIL = 'email',
        PHONE = 'phone',
        URL = 'url',
        ZIP = 'zip',
    }

    /**
     * Definition Scope Entity Type
     */
    export enum entityType {
        USER = 'user',
        PLAN = 'plan',
        POOL = 'pool',
        GRANT = 'grant',
        QUERY = 'query',
        OTHER = 'other',
    }

    /**
     * Scope of the tag
     */
    export enum scope {
        USER = 'user',
        TEAM = 'team',
        GLOBAL = 'global',
        CEN = 'cen',
    }


}
