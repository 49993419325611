<template>
  <div v-show="showLightbox">
    <CoolLightBox
      :items="[lightBoxUrl]"
      :index="showLightbox ? 0 : null"
      :slideshow="false"
      :useZoomBar="true"
      @close="dismiss"
    >
    </CoolLightBox>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import CoolLightBox from "vue-cool-lightbox";

import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

export default Vue.extend({
  name: "LightBox",

  components: {
    CoolLightBox
  },
  data: () => ({
    value: false,
    showLightbox: false,
  }),
  props: {
    url: {
      type: String,
      required: true
    },
    mediaType: {
      type: String,
      required: false
    }
  },
  computed: {
    lightBoxUrl(): string | { src: string; mediaType: string } {
      if (this.mediaType) {
        return { src: this.url, mediaType: this.mediaType };
      }
      return this.url;
    }
  },
  methods: {
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    onKeyPressed(e: any) {
      if (e.keyCode === 13) {
        e.stopPropagation();
        this.dismiss();
      }
    },
    dismiss() {
      this.showLightbox = false;
      this.$emit("dismiss");
      this.$destroy();
    },
    showImage(val: string) {
      if (val && val.trim().length) {
        this.showLightbox = true;
      }
    }
  },
  watch: {
    url(val: string) {
      this.showImage(val);
    },
    lightBoxUrl(val) {
      console.log(val)
    }
  },
  mounted() {
    this.showImage(this.url);
    document.addEventListener("keyup", this.onKeyPressed);
  },
  destroyed() {
    document.removeEventListener("keyup", this.onKeyPressed);
  },
});
</script>
