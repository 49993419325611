/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PoolPreviewItem } from './PoolPreviewItem';
import type { PreviewExpertItem } from './PreviewExpertItem';

/**
 * Pool Preview State
 */
export type PreviewStateItem = {
    /**
     * State
     */
    state?: string;
    /**
     * How experts are selected for this plan
     */
    expertSelectionMode?: PreviewStateItem.expertSelectionMode;
    /**
     * Method
     */
    method?: string;
    /**
     * API Response Version
     */
    _ver?: number;
    /**
     * Experts in Preview
     */
    experts?: {
        data?: Array<PreviewExpertItem>,
    };
    /**
     * Pool Preview Items
     */
    pools?: {
        data?: Array<PoolPreviewItem>,
    };
}

export namespace PreviewStateItem {

    /**
     * How experts are selected for this plan
     */
    export enum expertSelectionMode {
        GRANT_FIRST = 'grant_first',
        PLAN_FIRST = 'plan_first',
        ALL = 'all',
        USER_CHOICE = 'user_choice',
        PLAN_ONLY = 'plan_only',
        GRANT_ONLY = 'grant_only',
        PLAN_SELECT = 'plan_select',
    }


}
