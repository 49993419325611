import { UserType } from "@/config";

export default [
  {
    path: "/admin/tags",
    name: "TagIndex",
    component: () => import(/* webpackChunkName: "tags" */ "@/views/admin/tags/TagIndex.vue"),
    children: [
      {
        name: "TagForm",
        path: ":tagId",
        component: () => import(/* webpackChunkName: "tags" */ "@/views/admin/tags/TagForm.vue"),
        props: true
      }
    ],
    meta: { allowedUsers: [UserType.CSR] }
  },
  {
    path: "/admin/customFields",
    name: "CustomField",
    component: () => import(/* webpackChunkName: "tags" */ "@/views/admin/customFields/CustomFieldIndex.vue"),
    children: [
      {
        name: "CustomFieldForm",
        path: ":definitionId",
        component: () => import(/* webpackChunkName: "tags" */ "@/views/admin/customFields/CustomFieldForm.vue"),
        props: true
      }
    ],
    meta: { allowedUsers: [UserType.CSR] }
  }
];
