import { render, staticRenderFns } from "./NewQueryTwoColumn.vue?vue&type=template&id=db56e758&scoped=true"
import script from "./NewQueryTwoColumn.vue?vue&type=script&lang=ts"
export * from "./NewQueryTwoColumn.vue?vue&type=script&lang=ts"
import style0 from "./NewQueryTwoColumn.vue?vue&type=style&index=0&id=db56e758&prod&lang=scss&scoped=true"
import style1 from "./NewQueryTwoColumn.vue?vue&type=style&index=1&id=db56e758&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db56e758",
  null
  
)

export default component.exports