/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GrantItem } from '../models/GrantItem';
import type { GrantPartialItem } from '../models/GrantPartialItem';
import type { GrantRecordItem } from '../models/GrantRecordItem';
import type { GrantRegionRecordItem } from '../models/GrantRegionRecordItem';
import type { meta } from '../models/meta';
import { request as __request } from '../core/request';

export class GrantService {

    /**
     * Find GrantRecord by ID
     * Return a single GrantRecord
     * @result GrantRecordItem successful operation
     * @throws ApiError
     */
    public static async getGrantById({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<GrantRecordItem> {
        const result = await __request({
            method: 'GET',
            path: `/server/grant/${id}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update an existing grant
     * Update a record based on data given
     * @result GrantRecordItem Successful operation
     * @throws ApiError
     */
    public static async updateGrant({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** Grant object that needs to be updated **/
        requestBody: GrantRecordItem,
    }): Promise<GrantRecordItem> {
        const result = await __request({
            method: 'PUT',
            path: `/server/grant/${id}`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find grant by grant ID with any associated diagnosis data
     * Find grant by grant ID with any associated diagnosis data
     * @result GrantItem successful operation
     * @throws ApiError
     */
    public static async getGrantDiagnosisById({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<GrantItem> {
        const result = await __request({
            method: 'GET',
            path: `/server/grant/${id}/diagnosis`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find multiple grants.  Filterable by name, Diagnosis Id, Diagnosis Domain Id, Plan Id.
     * Returns one or more matching grants
     * @result any successful operation
     * @throws ApiError
     */
    public static async getGrantList({
        name,
        diagnosisId,
        diagnosisDomainId,
        poolId,
        effectiveDateSearchStart,
        effectiveDateSearchEnd,
        expiresOnOrBeforeDate,
        effectiveDateStart,
        effectiveDateEnd,
        activeInclude = 'all',
        activeNowOnly = true,
        planId,
        userType,
        excludeDiagnosisId,
        excludeDiagnosisDomainId,
        excludePlanId,
        excludePoolId,
        page = 1,
        pageSize = 10,
        sort,
    }: {
        /** Find records searching by Name.  Enter ID for specific record **/
        name?: string,
        /** Find records associated with a given Diagnosis  **/
        diagnosisId?: number,
        /** Find records associated with a given Diagnosis Domain **/
        diagnosisDomainId?: number,
        /** Find records associated with a given Pool **/
        poolId?: number,
        /** Date Search Start.  Will return any records where effective date was active in range **/
        effectiveDateSearchStart?: number,
        /** Date Search End.  Will return any records where effective date was active in range **/
        effectiveDateSearchEnd?: number,
        /** Find expiring records.  Range from now() till date given **/
        expiresOnOrBeforeDate?: number,
        /** Effective Date Start time.  Used mainly to find specific records  **/
        effectiveDateStart?: number,
        /** Effective Date End time.  Used mainly to find specific records  **/
        effectiveDateEnd?: number,
        /** Records to Include based on Active Flag.  Allows for inactive only if desired. **/
        activeInclude?: 'all' | 'active' | 'inactive',
        /** Include only records active now - will look at active flag and date ranges **/
        activeNowOnly?: boolean,
        /** Find records associated with a given Plan **/
        planId?: number,
        /** Find records associated with a given User Type **/
        userType?: number,
        /** Exclude records associated with a given Diagnosis **/
        excludeDiagnosisId?: number,
        /** Exclude records associated with a given Diagnosis Domain **/
        excludeDiagnosisDomainId?: number,
        /** Exclude records associated with a given Plan **/
        excludePlanId?: number,
        /** Exclude records associated with a given Pool **/
        excludePoolId?: number,
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
        /** Sort Options - Comma delimited list of key:order. order optional - default ASC  **/
        sort?: string,
    }): Promise<{
        data?: Array<GrantPartialItem>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/grant/list`,
            query: {
                'name': name,
                'diagnosisId': diagnosisId,
                'diagnosisDomainId': diagnosisDomainId,
                'poolId': poolId,
                'effectiveDateSearchStart': effectiveDateSearchStart,
                'effectiveDateSearchEnd': effectiveDateSearchEnd,
                'expiresOnOrBeforeDate': expiresOnOrBeforeDate,
                'effectiveDateStart': effectiveDateStart,
                'effectiveDateEnd': effectiveDateEnd,
                'activeInclude': activeInclude,
                'activeNowOnly': activeNowOnly,
                'planId': planId,
                'userType': userType,
                'excludeDiagnosisId': excludeDiagnosisId,
                'excludeDiagnosisDomainId': excludeDiagnosisDomainId,
                'excludePlanId': excludePlanId,
                'excludePoolId': excludePoolId,
                'page': page,
                'pageSize': pageSize,
                'sort': sort,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Get detailed Grant list for plan
     * Returns one or more matching grants
     * @result any successful operation
     * @throws ApiError
     */
    public static async getGrantPlanDetailList({
        activeInclude = 'all',
        activeNowOnly = true,
        planId,
    }: {
        /** Records to Include based on Active Flag.  Allows for inactive only if desired. **/
        activeInclude?: 'all' | 'active' | 'inactive',
        /** Include only records active now - will look at active flag and date ranges **/
        activeNowOnly?: boolean,
        /** Find records associated with a given Plan **/
        planId?: number,
    }): Promise<{
        data?: Array<GrantItem>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/grant/plan/list`,
            query: {
                'activeInclude': activeInclude,
                'activeNowOnly': activeNowOnly,
                'planId': planId,
            },
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find Grant Region Record by ID
     * Return a single Grant Region Record
     * @result GrantRegionRecordItem successful operation
     * @throws ApiError
     */
    public static async getGrantRegionById({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<GrantRegionRecordItem> {
        const result = await __request({
            method: 'GET',
            path: `/server/grant/region/${id}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update an existing grant region
     * Update a record based on data given
     * @result GrantRegionRecordItem Successful operation
     * @throws ApiError
     */
    public static async updateGrantRegion({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** Grant object that needs to be updated **/
        requestBody: GrantRegionRecordItem,
    }): Promise<GrantRegionRecordItem> {
        const result = await __request({
            method: 'PUT',
            path: `/server/grant/region/${id}`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find multiple grant regions.  Filterable by grant id
     * Returns one or more matching grants
     * @result any successful operation
     * @throws ApiError
     */
    public static async getGrantRegionList({
        grantId,
        page = 1,
        pageSize = 10,
    }: {
        /** Find records associated with a given Grant **/
        grantId?: number,
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
    }): Promise<{
        data?: Array<GrantRegionRecordItem>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/grant/region/list`,
            query: {
                'grantId': grantId,
                'page': page,
                'pageSize': pageSize,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Create a new grant
     * Creates a record based on data given
     * @result GrantRecordItem Successful operation
     * @throws ApiError
     */
    public static async createGrant({
        requestBody,
    }: {
        /** Grant object to be created **/
        requestBody: GrantRecordItem,
    }): Promise<GrantRecordItem> {
        const result = await __request({
            method: 'POST',
            path: `/server/grant`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Add a diagnosis to a grant
     * Creates a record based on data given
     * @result any Successful operation
     * @throws ApiError
     */
    public static async addGrantDiagnosis({
        id,
        diagnosisId,
    }: {
        /** Record ID **/
        id: number,
        /** ID of diagnosis to add to grant **/
        diagnosisId: number,
    }): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/server/grant/${id}/diagnosis/${diagnosisId}`,
            errors: {
                400: `Error: Bad Request`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Remove a diagnosis from a grant
     * Remove a record based on data given
     * @result any Successful operation
     * @throws ApiError
     */
    public static async deleteGrantDiagnosis({
        id,
        diagnosisId,
    }: {
        /** Record ID **/
        id: number,
        /** ID of diagnosis to remove from grant **/
        diagnosisId: number,
    }): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/server/grant/${id}/diagnosis/${diagnosisId}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Add a domain to a grant
     * Creates a record based on data given
     * @result any Successful operation
     * @throws ApiError
     */
    public static async addGrantDiagnosisDomain({
        id,
        domainId,
    }: {
        /** Record ID **/
        id: number,
        /** ID of domain to add to grant **/
        domainId: number,
    }): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/server/grant/${id}/domain/${domainId}`,
            errors: {
                400: `Error: Bad Request`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Remove a domain from a grant
     * Remove a record based on data given
     * @result any Successful operation
     * @throws ApiError
     */
    public static async deleteGrantDiagnosisDomain({
        id,
        domainId,
    }: {
        /** Record ID **/
        id: number,
        /** ID of domain to remove from grant **/
        domainId: number,
    }): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/server/grant/${id}/domain/${domainId}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Add a pool to a grant
     * Creates a record based on data given
     * @result any Successful operation
     * @throws ApiError
     */
    public static async addGrantPool({
        id,
        poolId,
    }: {
        /** Record ID **/
        id: number,
        /** ID of pool to add to grant **/
        poolId: number,
    }): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/server/grant/${id}/pool/${poolId}`,
            errors: {
                400: `Error: Bad Request`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Remove a pool from a grant
     * Removes a record based on data given
     * @result any Successful operation
     * @throws ApiError
     */
    public static async deleteGrantpool({
        id,
        poolId,
    }: {
        /** Record ID **/
        id: number,
        /** ID of pool to remove from grant **/
        poolId: number,
    }): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/server/grant/${id}/pool/${poolId}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Create a new grant Region
     * Creates a record based on data given
     * @result GrantRegionRecordItem Successful operation
     * @throws ApiError
     */
    public static async createGrantRegion({
        requestBody,
    }: {
        /** Grant object to be created **/
        requestBody: GrantRegionRecordItem,
    }): Promise<GrantRegionRecordItem> {
        const result = await __request({
            method: 'POST',
            path: `/server/grant/region`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

}