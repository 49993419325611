import { ThemeDefinition, ThemeStoreInterface } from "./types";
import { Module, StateFor } from "vuex-typescript-interface";
import { RootStoreInterface } from "../types";
import { defaultTheme, Themes } from "./themes";
import { i18n } from "@/plugins/i18n";
import Vuetify from "@/plugins/vuetify";

const theme: Module<ThemeStoreInterface, RootStoreInterface> = {
  state: {
    theme: Themes[defaultTheme]
  },

  getters: {
    getCurrentTheme: state => state.theme,
    name: state => state.theme.text.company,
    nameText: state => state.theme.text.name,
    domain: state => state.theme.text.domain,
    infoDomain: state => state.theme.text.url,
    careNavigator: state => state.theme.text.careNavigator,
    logo: state => state.theme.logo,
    getFavIcon: state => state.theme.favicon,
    accentImage: state => state.theme.accent,
    accentFactor: state => state.theme.accentFactor,

    getReplacementText: state => (key: string) => {
      switch (key.toLowerCase()) {
        case "company":
          return state.theme.text.company;
        case "domain":
          return state.theme.text.domain;
        case "infodomain":
          return state.theme.text.url;
        case "careNavigator":
            return state.theme.text.careNavigator;
        default:
          return "";
      }
    },

    themeNames: _state => {
      return Object.entries(Themes).map(t => ({ value: t[0], text: t[1].name.replace("CEN", "Cancer Expert Now") }));
    },
  },
  mutations: {
    setTheme(state: StateFor<ThemeStoreInterface>, t: ThemeDefinition): void {
      state.theme = t;
    }
  },
  actions: {
    // Actions
    async applyTheme({ commit }, themeName: string): Promise<void> {
      if (!Themes[themeName]) {
        // Fallback to domain based theme
        themeName = Object.keys(Themes).find(th => Themes[th].match.test(location.hostname)) ?? defaultTheme;
      }
      const selected = Themes[themeName];
      console.log(`Initializing theme to ${themeName}`);
      commit("setTheme", selected);
      const lm = i18n.getLocaleMessage("en");
      lm.theme = selected.text;
      i18n.setLocaleMessage("en", lm);
      for (const n in selected.colors) {
        Vuetify.framework.theme.themes.light[n] = selected.colors[n];
      }
      Vuetify.framework.theme.themes.light["success"] = selected.colors["primary"];
    },

    async initTheme({ dispatch }): Promise<void> {
      await dispatch("applyTheme", "default");
    }
  }
};

export default theme;
