<template>
  <div>
    <v-overlay v-if="overlay" absolute :value="loading">{{ overlayText }}</v-overlay>
    <slot name="title" :title="document ? document.data.title_text : undefined">
      <div :class="titleClass" v-if="document && document.data.title_text" v-text="document.data.title_text" />
    </slot>
    <prismic-rich-text
      v-if="document"
      :key="getCurrentTheme.name"
      :class="bodyClass"
      :field="content"
      :htmlSerializer="htmlSerializer"
    />
    <div v-if="!document && missing">Missing CMS Document '{{ docType }}:{{ docId }}'.</div>
  </div>
</template>

<script lang="ts">
/*
    This is a simple rich-text content component which makes the following assumptions:
    1. The content document has a single rich text field named "content"
    2. If overlay is true there is a relative or absolutely positioned parent in which the overlay can be bound
    3. No assumption is made about the size of the control, and this will reflow based on content if not constrained

    This automatically replaces thematic text surrounded by :: - ::
    Current tags are:
      company
      domain
      infodomain

      :key in the tag above forces a refresh if the theme changes

*/
import Vue from "vue";

import { createHelpers } from "vuex-typescript-interface";
import { ThemeStoreInterface } from "@/store/theme/types";
import { RootStoreInterface } from "@/store/types";

const { mapGetters: mapRootGetters } = createHelpers<RootStoreInterface>();
const { mapGetters } = createHelpers<ThemeStoreInterface>();
const getters = mapGetters(["getReplacementText"]);
const rootGetters = mapRootGetters(["currentLocale"]);
const { mapGetters: mapThemeGetters } = createHelpers<ThemeStoreInterface>();
const themeGetters = mapThemeGetters(["getCurrentTheme"]);

export default Vue.extend({
  props: {
    docId: {
      type: String,
      required: false
    },
    // If docType is absent this uses single more
    docType: {
      type: String,
      required: true
    },
    titleClass: {
      type: String,
      default: "text-h5"
    },
    bodyClass: {
      type: String,
      default: ""
    },
    // If overlay is true there should be a relative or absolute parent
    overlay: {
      type: Boolean,
      default: false
    },
    overlayText: {
      type: String,
      default: "Loading..."
    }
  },
  data() {
    return {
      loading: false,
      document: null as any,
      missing: false
    };
  },
  computed: {
    ...getters,
    ...rootGetters,
    ...themeGetters,
    content(): any {
      return this.document?.data.content;
    }
  },
  methods: {
    async getContent() {
      this.loading = true;
      let doc: any = null;

      if (this.docId) {
        doc = await this.$prismic.client.getByUID(this.docType, this.docId, { lang: this.currentLocale });
        if (!doc && this.currentLocale != "en-us") {
          doc = await this.$prismic.client.getByUID(this.docType, this.docId, { lang: "en-us" });
        }
        this.missing = !doc;
      } else {
        doc = await this.$prismic.client.getSingle(this.docType, { lang: this.currentLocale });
        if (!doc && this.currentLocale != "en-us") {
          doc = await this.$prismic.client.getSingle(this.docType, { lang: "en-us" });
        }
        this.missing = !doc || !doc.data.enabled;
      }

      if (doc && typeof doc.data.enabled !== "undefined" && !doc.data.enabled) {
        // Hide if there is a falsy enabled flag
        doc = null;
      }
      this.document = doc;
      this.$emit("loaded", Boolean(doc), this.document);
      this.loading = false;
    },
    htmlSerializer(type: string, _element: any, content: string, _children: string[]) {
      // Text is in spans
      // Default span implementation does a nl2br
      if (type == "span") {
        return content ? this.patternMatcher(content).replace(/\n/g, "<br />") : "";
      }
      return null;
    },
    patternMatcher(content: string): string {
      return content.replace(/::([^:]+)::/g, (_m, s) => {
        return this.getReplacementText(s);
      });
    }
  },
  watch: {
    docId: {
      handler(): void {
        console.log("Loading");
        this.getContent();
      },
      immediate: true
    }
  }
});
</script>
