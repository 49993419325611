/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DiagnosisRecordItem } from '../models/DiagnosisRecordItem';
import type { meta } from '../models/meta';
import type { PoolItem } from '../models/PoolItem';
import type { PoolRecordItem } from '../models/PoolRecordItem';
import type { PoolUserStatsItem } from '../models/PoolUserStatsItem';
import { request as __request } from '../core/request';

export class PoolService {

    /**
     * Find pool  by ID.
     * Returns matching record
     * @result PoolItem successful operation
     * @throws ApiError
     */
    public static async getPoolById({
        id,
        includeStats,
    }: {
        /** Record ID **/
        id: number,
        /** Include additional statistics **/
        includeStats?: boolean,
    }): Promise<PoolItem> {
        const result = await __request({
            method: 'GET',
            path: `/server/pool/${id}`,
            query: {
                'includeStats': includeStats,
            },
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update an existing pool
     * Update a record based on data given
     * @result PoolRecordItem Successful operation
     * @throws ApiError
     */
    public static async updatePool({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** Pool object that needs to be updated **/
        requestBody: PoolRecordItem,
    }): Promise<PoolRecordItem> {
        const result = await __request({
            method: 'PUT',
            path: `/server/pool/${id}`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find Distinct Diagnosis List for this pool
     * Returns one or more matching records
     * @result any successful operation
     * @throws ApiError
     */
    public static async getPoolDxList({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<{
        data?: Array<DiagnosisRecordItem>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/pool/${id}/diagnosis/list`,
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find multiple pools.  Filterable by name, Grant ID, and Plan ID.  Only one allowed
     * Returns one or more matching records
     * @result any successful operation
     * @throws ApiError
     */
    public static async getPoolList({
        name,
        userId,
        grantId,
        planId,
        diagnosisId,
        diagnosisDomainId,
        planType,
        grantUserType,
        excludeGrantId,
        excludePlanId,
        excludeUserId,
        excludeDiagnosisDomainId,
        activeOnly = true,
        page = 1,
        pageSize = 10,
        sort,
    }: {
        /** Find records searching by Name.  Enter ID for specific record **/
        name?: string,
        /** Find records associated with a given User **/
        userId?: number,
        /** Find records associated with a given Grant **/
        grantId?: number,
        /** Find records associated with a given Plan **/
        planId?: number,
        /** Find records associated with a given Diagnosis  **/
        diagnosisId?: number,
        /** Find records associated with a given Diagnosis Domain **/
        diagnosisDomainId?: number,
        /** Type of plan **/
        planType?: 'census' | 'sso' | 'region' | 'advocate' | 'blinded' | 'expert' | 'vtb',
        /** Find records associated with a given User Type **/
        grantUserType?: number,
        /** Exclude records associated with a given Grant **/
        excludeGrantId?: number,
        /** Exclude records associated with a given Plan **/
        excludePlanId?: number,
        /** Exclude records associated with a given User **/
        excludeUserId?: number,
        /** Exclude records associated with a given Diagnosis Domain **/
        excludeDiagnosisDomainId?: number,
        /** Records to Include based on Active Flag. **/
        activeOnly?: boolean,
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
        /** Sort Options - Comma delimited list of key:order. order optional - default ASC  **/
        sort?: string,
    }): Promise<{
        data?: Array<PoolRecordItem>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/pool/list`,
            query: {
                'name': name,
                'userId': userId,
                'grantId': grantId,
                'planId': planId,
                'diagnosisId': diagnosisId,
                'diagnosisDomainId': diagnosisDomainId,
                'planType': planType,
                'grantUserType': grantUserType,
                'excludeGrantId': excludeGrantId,
                'excludePlanId': excludePlanId,
                'excludeUserId': excludeUserId,
                'excludeDiagnosisDomainId': excludeDiagnosisDomainId,
                'activeOnly': activeOnly,
                'page': page,
                'pageSize': pageSize,
                'sort': sort,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find expert stats for this pool
     * Returns counts for Experts registered and non
     * @result PoolUserStatsItem successful operation
     * @throws ApiError
     */
    public static async getPoolExpertStats({
        id,
        userType,
    }: {
        /** Record ID **/
        id: number,
        /** Find records associated with a given User Type **/
        userType?: number,
    }): Promise<PoolUserStatsItem> {
        const result = await __request({
            method: 'GET',
            path: `/server/pool/${id}/userStats`,
            query: {
                'userType': userType,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Create a new pool
     * Creates a record based on data given
     * @result PoolRecordItem Successful operation
     * @throws ApiError
     */
    public static async createPool({
        requestBody,
    }: {
        /** Pool object to be created **/
        requestBody: PoolRecordItem,
    }): Promise<PoolRecordItem> {
        const result = await __request({
            method: 'POST',
            path: `/server/pool`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Add a diagnosis to a pool
     * Creates a record based on data given
     * @result any Successful operation
     * @throws ApiError
     */
    public static async addPoolDiagnosis({
        id,
        diagnosisId,
    }: {
        /** Record ID **/
        id: number,
        /** ID of diagnosis to add to pool **/
        diagnosisId: number,
    }): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/server/pool/${id}/diagnosis/${diagnosisId}`,
            errors: {
                400: `Error: Bad Request`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Remove a diagnosis from a pool
     * Remove a record based on data given
     * @result any Successful operation
     * @throws ApiError
     */
    public static async deletePoolDiagnosis({
        id,
        diagnosisId,
    }: {
        /** Record ID **/
        id: number,
        /** ID of diagnosis to remove from pool **/
        diagnosisId: number,
    }): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/server/pool/${id}/diagnosis/${diagnosisId}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Add an expert to a pool
     * Creates a record based on data given
     * @result any Successful operation
     * @throws ApiError
     */
    public static async addPoolExpert({
        id,
        expertId,
    }: {
        /** Record ID **/
        id: number,
        /** ID of expert to add to pool **/
        expertId: number,
    }): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/server/pool/${id}/expert/${expertId}`,
            errors: {
                400: `Error: Bad Request`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Remove an expert from a pool
     * Remove a record based on data given
     * @result any Successful operation
     * @throws ApiError
     */
    public static async deletePoolExpert({
        id,
        expertId,
    }: {
        /** Record ID **/
        id: number,
        /** ID of expert to remove from pool **/
        expertId: number,
    }): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/server/pool/${id}/expert/${expertId}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Add a plan to a pool
     * Creates a record based on data given
     * @result any Successful operation
     * @throws ApiError
     */
    public static async addPlan({
        id,
        planId,
    }: {
        /** Record ID **/
        id: number,
        /** ID of plan to add to pool **/
        planId: number,
    }): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/server/pool/${id}/plan/${planId}`,
            errors: {
                400: `Error: Bad Request`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Remove a plan from a pool
     * Remove a record based on data given
     * @result any Successful operation
     * @throws ApiError
     */
    public static async deletePlan({
        id,
        planId,
    }: {
        /** Record ID **/
        id: number,
        /** ID of plan to remove from pool **/
        planId: number,
    }): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/server/pool/${id}/plan/${planId}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

}