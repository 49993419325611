/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Audit Item
 */
export type AuditItem = {
    /**
     * Record Unique ID
     */
    id?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    created?: number;
    /**
     * Subject ID
     */
    subject?: number;
    /**
     * User ID
     */
    userId?: number;
    /**
     * Audit Action
     */
    action?: AuditItem.action;
    /**
     * Audit Table
     */
    table?: string;
    /**
     * Audit Item
     */
    item?: number;
    /**
     * Audit Text
     */
    text?: string;
    /**
     * API Response Version
     */
    _ver?: number;
}

export namespace AuditItem {

    /**
     * Audit Action
     */
    export enum action {
        CREATE = 'create',
        REVISE = 'revise',
        VIEW = 'view',
        DELETE = 'delete',
        AMMEND = 'ammend',
        TEXT = 'text',
        OTHER = 'other',
    }


}
