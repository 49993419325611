import _Vue from "vue";
import vuetify from "./vuetify";

// install $shoowImage to vue instances
// Usage
//     $showImage(url: string | { src: string; mediaType: string }): Promise<void>;

import LightBox from "@/components/util/LightBox.vue";


export function LightBoxPlugin(Vue: typeof _Vue, url: string, mediaType?: string): void {
  const ctor = LightBox.extend({ vuetify });
  const defaultOpts = {url: url, mediaType: mediaType};

  function createLightBoxCmp(url: string, mediaType?: string): Promise<void> {
    const container = document.querySelector("[data-app=true]") || document.body;
    return new Promise(resolve => {
      const cmp = new ctor(
        Object.assign({},
          {
            propsData: Object.assign({}, {url: url, defaultOpts, mediaType: mediaType}),
            destroyed: () => {
              container.removeChild(cmp.$el);
              resolve(cmp.$data.value);
            }
          }
        )
      );
      container.appendChild(cmp.$mount().$el);
    });
  }
  function showImage(url: string | { src: string; mediaType: string }): Promise<void> {
    if (typeof url === "string" ) {
      return createLightBoxCmp(url, mediaType);
    } else {
      return createLightBoxCmp(url.src, url.mediaType);
    }
  }

  // do stuff with options
  Vue.prototype.$showImage = showImage;
}

_Vue.use(LightBoxPlugin);
