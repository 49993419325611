/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { meta } from '../models/meta';
import type { QueryFilters } from '../models/QueryFilters';
import type { TagsDefinitionRecordItem } from '../models/TagsDefinitionRecordItem';
import type { TagsListItem } from '../models/TagsListItem';
import type { TagsListQueryFilters } from '../models/TagsListQueryFilters';
import type { TagsRecordItem } from '../models/TagsRecordItem';
import type { TagsTargetCountRecordItem } from '../models/TagsTargetCountRecordItem';
import type { TagsTargetListItem } from '../models/TagsTargetListItem';
import type { TagsTargetRecordItem } from '../models/TagsTargetRecordItem';
import { request as __request } from '../core/request';

export class TagsService {

    /**
     * Find tags by ID.
     * Returns matching record
     * @result TagsListItem successful operation
     * @throws ApiError
     */
    public static async getTagsById({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<TagsListItem> {
        const result = await __request({
            method: 'GET',
            path: `/server/tags/${id}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update an existing tag
     * Update a record based on data given
     * @result TagsRecordItem Successful operation
     * @throws ApiError
     */
    public static async updateTags({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** Tags object that needs to be updated **/
        requestBody: TagsRecordItem,
    }): Promise<TagsRecordItem> {
        const result = await __request({
            method: 'PUT',
            path: `/server/tags/${id}`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Remove a tag from database.  Internal only
     * Removes a tag from the database.  Normal use should be to set Active Flag to false
     * @result any Successful operation
     * @throws ApiError
     */
    public static async deleteTagById({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/server/tags/${id}`,
            errors: {
                400: `Error: Update Failed`,
                401: `Authentication error response`,
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Remove a tag from a target by direct ID
     * Remove a tag from a target by direct ID
     * @result any Successful operation
     * @throws ApiError
     */
    public static async deleteTagTargetById({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/server/tags/target/${id}`,
            errors: {
                400: `Error: Update Failed`,
                401: `Authentication error response`,
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find tag target counts by Tag ID.
     * Returns matching records
     * @result any successful operation
     * @throws ApiError
     */
    public static async getTagTargetCountsById({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<{
        data?: Array<TagsTargetCountRecordItem>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/tags/${id}/targetCounts`,
            errors: {
                401: `Error: Unauthorized`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find tags by ID.
     * Returns matching record
     * @result TagsDefinitionRecordItem successful operation
     * @throws ApiError
     */
    public static async getTagsDefinitionById({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<TagsDefinitionRecordItem> {
        const result = await __request({
            method: 'GET',
            path: `/server/tags/definitions/${id}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update a tag definition
     * Update a tag definition
     * @result TagsDefinitionRecordItem Successful operation
     * @throws ApiError
     */
    public static async updateTagDefinition({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** Tags definition object that needs to be updated **/
        requestBody: TagsDefinitionRecordItem,
    }): Promise<TagsDefinitionRecordItem> {
        const result = await __request({
            method: 'PUT',
            path: `/server/tags/definitions/${id}`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find multiple tags or get tags for a specific entity.
     * Returns one or more matching records
     * @result any successful operation
     * @throws ApiError
     */
    public static async getTagsDefinitionsList({
        name,
        description,
        type,
        tagEntityType,
        tagEntitySubType,
        entityTypeAny = false,
        scope,
        validator,
        userId,
        activeOnly = true,
        page = 1,
        pageSize = 10,
    }: {
        /** Find records searching by Name.  Enter ID for specific record **/
        name?: string,
        /** Find records searching by description. **/
        description?: string,
        /** Definition type **/
        type?: 'string' | 'int' | 'float' | 'blob' | 'enum',
        /** Entity Type that the Definition is available for **/
        tagEntityType?: 'user' | 'plan' | 'grant' | 'pool' | 'query' | 'other' | 'any',
        /** Entity Sub-Type that Definition is available for.  Entity Type Required **/
        tagEntitySubType?: string,
        /** Include Definition with entity type any as well as specific target.  Only used when an Entity Type is specified. **/
        entityTypeAny?: boolean,
        /** Tag Definition Scope **/
        scope?: 'user' | 'global' | 'team' | 'cen',
        /** Tag Definition validator **/
        validator?: 'email' | 'phone' | 'url' | 'zip',
        /** Find records associated with a given User **/
        userId?: number,
        /** Records to Include based on Active Flag. **/
        activeOnly?: boolean,
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
    }): Promise<{
        data?: Array<TagsDefinitionRecordItem>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/tags/definitions/list`,
            query: {
                'name': name,
                'description': description,
                'type': type,
                'tagEntityType': tagEntityType,
                'tagEntitySubType': tagEntitySubType,
                'entityTypeAny': entityTypeAny,
                'scope': scope,
                'validator': validator,
                'userId': userId,
                'activeOnly': activeOnly,
                'page': page,
                'pageSize': pageSize,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find multiple tags for a specific entity.
     * Returns one or more matching records
     * @result any successful operation
     * @throws ApiError
     */
    public static async getTagsForEntityList({
        entityType,
        entityId,
        tagType = 'tags',
        page = 1,
        pageSize = 10,
    }: {
        /** Type of entity being retreived **/
        entityType: 'user' | 'plan' | 'grant' | 'pool' | 'query' | 'other' | 'any',
        /** Id of object to retreive tags for **/
        entityId: number,
        /** Type of tags being retreived **/
        tagType?: 'tags' | 'custom' | 'all',
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
    }): Promise<{
        data?: Array<TagsListItem>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/tags/list/${entityType}/${entityId}`,
            query: {
                'tagType': tagType,
                'page': page,
                'pageSize': pageSize,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Elastic: Search Queries to get Tags for Filter List
     * Returns a list of Tags from Elastic, with document counts
     * @result TagsListQueryFilters successful operation
     * @result any No Content Found -- Operation Completed Successfully.
     * @throws ApiError
     */
    public static async getTagListForQueryFilter({
        requestBody,
    }: {
        /** Filters **/
        requestBody?: QueryFilters,
    }): Promise<TagsListQueryFilters | any> {
        const result = await __request({
            method: 'POST',
            path: `/server/tags/list/queryFilters`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find multiple tags or get tags for a specific entity.
     * Returns one or more matching records
     * @result any successful operation
     * @throws ApiError
     */
    public static async getTagsList({
        name,
        description,
        tagDefinitionId,
        includeDefinition = true,
        includeUser = false,
        returnAllUsers = false,
        tagTargetId,
        tagTargetType,
        tagScope,
        tagTargetScope,
        tagType = 'all',
        userId,
        activeOnly = true,
        page = 1,
        pageSize = 10,
    }: {
        /** Find records searching by Name.  Enter ID for specific record **/
        name?: string,
        /** Find records searching by description. **/
        description?: string,
        /** Id of defintion type to retreive tags for. **/
        tagDefinitionId?: number,
        /** Should definition details be included in result? **/
        includeDefinition?: boolean,
        /** Should user owner details be included in result? **/
        includeUser?: boolean,
        /** Should tags owned by users other than current be returned.  Used in CSR views **/
        returnAllUsers?: boolean,
        /** Id of object to retreive tags for.  Type required if this is used. **/
        tagTargetId?: number,
        /** Target type required for target id **/
        tagTargetType?: 'user' | 'plan' | 'grant' | 'pool' | 'query' | 'other' | 'any',
        /** Tag Scope **/
        tagScope?: 'user' | 'global' | 'team' | 'cen',
        /** Tag Target Scope **/
        tagTargetScope?: 'user' | 'global' | 'team' | 'cen',
        /** Type of tags being retreived **/
        tagType?: 'tags' | 'custom' | 'all',
        /** Find records associated with a given User **/
        userId?: number,
        /** Records to Include based on Active Flag. **/
        activeOnly?: boolean,
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
    }): Promise<{
        data?: Array<TagsListItem>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/tags/list`,
            query: {
                'name': name,
                'description': description,
                'tagDefinitionId': tagDefinitionId,
                'includeDefinition': includeDefinition,
                'includeUser': includeUser,
                'returnAllUsers': returnAllUsers,
                'tagTargetId': tagTargetId,
                'tagTargetType': tagTargetType,
                'tagScope': tagScope,
                'tagTargetScope': tagTargetScope,
                'tagType': tagType,
                'userId': userId,
                'activeOnly': activeOnly,
                'page': page,
                'pageSize': pageSize,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find multiple tags or get tags for a specific entity.
     * Returns one or more matching records
     * @result any successful operation
     * @throws ApiError
     */
    public static async getTagsTargetList({
        tagTargetId,
        tagTargetType,
        tagTargetScope,
        tagType = 'all',
        userId,
        activeOnly = true,
        page = 1,
        pageSize = 10,
    }: {
        /** Id of object to retreive tags for.  Type required if this is used. **/
        tagTargetId?: number,
        /** Target type required for target id **/
        tagTargetType?: 'user' | 'plan' | 'grant' | 'pool' | 'query' | 'other',
        /** Tag Target Scope **/
        tagTargetScope?: 'user' | 'global' | 'team',
        /** Type of tags being retreived **/
        tagType?: 'tags' | 'custom' | 'all',
        /** Find records associated with a given User **/
        userId?: number,
        /** Records to Include based on Active Flag. **/
        activeOnly?: boolean,
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
    }): Promise<{
        data?: Array<TagsTargetListItem>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/tags/target/list`,
            query: {
                'tagTargetId': tagTargetId,
                'tagTargetType': tagTargetType,
                'tagTargetScope': tagTargetScope,
                'tagType': tagType,
                'userId': userId,
                'activeOnly': activeOnly,
                'page': page,
                'pageSize': pageSize,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Assign a tag to a target
     * Assign a tag to a target
     * @result TagsTargetRecordItem Successful operation
     * @throws ApiError
     */
    public static async createTagTarget({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** Tags target object that needs to be updated **/
        requestBody: TagsTargetRecordItem,
    }): Promise<TagsTargetRecordItem> {
        const result = await __request({
            method: 'POST',
            path: `/server/tags/${id}/target`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Create a new tag
     * Creates a record based on data given
     * @result TagsRecordItem Successful operation
     * @throws ApiError
     */
    public static async createTags({
        requestBody,
    }: {
        /** Tags object to be created **/
        requestBody: TagsRecordItem,
    }): Promise<TagsRecordItem> {
        const result = await __request({
            method: 'POST',
            path: `/server/tags`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Create a tag definition
     * Create a tag definition
     * @result TagsDefinitionRecordItem Successful operation
     * @throws ApiError
     */
    public static async createTagDefinition({
        requestBody,
    }: {
        /** Tags definition object that needs to be updated **/
        requestBody: TagsDefinitionRecordItem,
    }): Promise<TagsDefinitionRecordItem> {
        const result = await __request({
            method: 'POST',
            path: `/server/tags/definitions`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

}