import { UserType } from "@/config";

export default [
  {
    path: "/admin/grants",
    name: "GrantIndex",
    component: () => import(/* webpackChunkName: "grants" */ "@/views/admin/grants/GrantIndex.vue"),
    meta: { allowedUsers: [UserType.CSR] },
    children: [
      {
        name: "GrantForm",
        path: ":grantId",
        component: () => import(/* webpackChunkName: "grants" */ "@/views/admin/grants/GrantForm.vue"),
        props: true
      },
      {
        name: "GrantPools",
        path: ":grantId/pools",
        component: () => import(/* webpackChunkName: "grants" */ "@/views/admin/grants/GrantPools.vue"),
        props: true
      },
      {
        name: "GrantDiagnoses",
        path: ":grantId/diagnoses",
        component: () => import(/* webpackChunkName: "grants" */ "@/views/admin/grants/GrantDiagnoses.vue"),
        props: true
      },
      {
        name: "GrantDiagnosisDomains",
        path: ":grantId/domains",
        component: () => import(/* webpackChunkName: "grants" */ "@/views/admin/grants/GrantDiagnosisDomains.vue"),
        props: true
      },
      {
        name: "GrantAssociatedPlans",
        path: ":grantId/associatedPlans",
        component: () => import(/* webpackChunkName: "grants" */ "@/views/admin/grants/GrantAssociatedPlans.vue"),
        props: true
      },
      {
        name: "GrantAdditionalInfo",
        path: ":grantId/additional",
        component: () => import(/* webpackChunkName: "grants" */ "@/views/admin/grants/GrantAdditionalInfo.vue"),
        props: true
      }
    ]
  },
  {
    path: "/admin/diagnosis",
    name: "DiagnosisDomain",
    component: () => import(/* webpackChunkName: "diagnosis" */ "@/views/admin/diagnosis/DiagnosisDomain.vue"),
    meta: { allowedUsers: [UserType.CSR] }
  },
  {
    path: "/admin/plans",
    name: "PlanIndex",
    meta: { allowedUsers: [UserType.CSR] },
    component: () => import(/* webpackChunkName: "plans" */ "@/views/admin/plans/PlanIndex.vue"),
    children: [
      {
        name: "PlanSummary",
        path: ":planId",
        component: () => import(/* webpackChunkName: "plans" */ "@/views/admin/plans/PlanSummary.vue"),
        props: true
      },
      {
        name: "PlanForm",
        path: ":planId/details",
        component: () => import(/* webpackChunkName: "plans" */ "@/views/admin/plans/PlanForm.vue"),
        props: true
      },
      {
        name: "PlanPools",
        path: ":planId/pools",
        component: () => import(/* webpackChunkName: "plans" */ "@/views/admin/plans/PlanPools.vue"),
        props: true
      },
      {
        name: "PlanDiagnoses",
        path: ":planId/diagnoses",
        component: () => import(/* webpackChunkName: "plans" */ "@/views/admin/plans/PlanDiagnoses.vue"),
        props: true
      },
      {
        name: "PlanDiagnosisDomains",
        path: ":planId/domains",
        component: () => import(/* webpackChunkName: "plans" */ "@/views/admin/plans/PlanDiagnosisDomains.vue"),
        props: true
      },
      {
        name: "PlanFundingSources",
        path: ":planId/grants",
        component: () => import(/* webpackChunkName: "plans" */ "@/views/admin/plans/PlanFundingSources.vue"),
        props: true
      },
      {
        name: "PlanLinks",
        path: ":planId/links",
        component: () => import(/* webpackChunkName: "plans" */ "@/views/admin/plans/PlanLinks.vue"),
        props: true
      },
      {
        name: "PlanSurge",
        path: ":planId/surge",
        component: () => import(/* webpackChunkName: "plans" */ "@/views/admin/plans/PlanSurge.vue"),
        props: true
      },
      {
        name: "PlanManagers",
        path: ":planId/managers",
        component: () => import(/* webpackChunkName: "plans" */ "@/views/admin/plans/PlanManagers.vue"),
        props: true
      },
      {
        name: "PlanMembers",
        path: ":planId/members",
        component: () => import(/* webpackChunkName: "plans" */ "@/views/admin/plans/PlanMembers.vue"),
        props: true
      },
      {
        name: "PlanAdditionalInfo",
        path: ":planId/additional",
        component: () => import(/* webpackChunkName: "plans" */ "@/views/admin/plans/PlanAdditionalInfo.vue"),
        props: true
      }
    ]
  },
  {
    path: "/admin/pools",
    name: "PoolIndex",
    component: () => import(/* webpackChunkName: "poolSetup" */ "@/views/admin/pools/PoolIndex.vue"),
    children: [
      {
        name: "PoolForm",
        path: ":poolId/details",
        component: () => import(/* webpackChunkName: "poolSetup" */ "@/views/admin/pools/PoolForm.vue"),
        props: true
      },
      {
        name: "PoolDiagnoses",
        path: ":poolId/diagnoses",
        component: () => import(/* webpackChunkName: "poolSetup" */ "@/views/admin/pools/PoolDiagnoses.vue"),
        props: true
      },
      {
        name: "PoolExperts",
        path: ":poolId/experts",
        component: () => import(/* webpackChunkName: "poolSetup" */ "@/views/admin/pools/PoolExperts.vue"),
        props: true
      },
      {
        name: "PoolPlans",
        path: ":poolId/plans",
        component: () => import(/* webpackChunkName: "poolSetup" */ "@/views/admin/pools/PoolPlans.vue"),
        props: true
      },
      {
        name: "PoolGrants",
        path: ":poolId/grants",
        component: () => import(/* webpackChunkName: "poolSetup" */ "@/views/admin/pools/PoolFundingSources.vue"),
        props: true
      }
    ],
    meta: { allowedUsers: [UserType.CSR] }
  }
];
