/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { forgotPassword } from '../models/forgotPassword';
import type { Login } from '../models/Login';
import type { OAuthAppsItem } from '../models/OAuthAppsItem';
import type { Token } from '../models/Token';
import type { userChangePassword } from '../models/userChangePassword';
import type { UserPartialItem } from '../models/UserPartialItem';
import { request as __request } from '../core/request';

export class AuthService {

    /**
     * Revert to authoirzed user from login-as
     * Cancel Login-as
     * @result any successful operation
     * @throws ApiError
     */
    public static async cancelLoginAs(): Promise<{
        /**
         * Session token
         */
        token: string,
        /**
         * Token Expiration
         */
        exp: number,
        /**
         * Users theme
         */
        theme?: string,
        /**
         * User can post new queries
         */
        canPost?: boolean,
        user: UserPartialItem,
    }> {
        const result = await __request({
            method: 'POST',
            path: `/auth/cancelloginas`,
            errors: {
                400: `Authentication error response`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Request a password reset
     * Generate a password reset based on email
     * @result any successful operation
     * @throws ApiError
     */
    public static async forgotPassword({
        requestBody,
    }: {
        /** Forgot Password Request **/
        requestBody: forgotPassword,
    }): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/auth/forgotPassword`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Login As another User
     * Login as a specified user
     * @result any successful operation
     * @throws ApiError
     */
    public static async loginAs({
        userId,
    }: {
        /** The User Id of the target user **/
        userId: string,
    }): Promise<{
        /**
         * Session token
         */
        token: string,
        /**
         * Token Expiration
         */
        exp: number,
        /**
         * Users theme
         */
        theme?: string,
        /**
         * User can post new queries
         */
        canPost?: boolean,
        user: UserPartialItem,
        authUser: UserPartialItem,
    }> {
        const result = await __request({
            method: 'POST',
            path: `/auth/loginas/${userId}`,
            errors: {
                400: `Authentication error response`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * User Logout
     * Logs out current logged in user session
     * @result any Successful Operation - No Content
     * @throws ApiError
     */
    public static async logout(): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/auth/logout`,
            errors: {
                403: `Error: Forbidden`,
            },
        });
        return result.body;
    }

    /**
     * List OAuth App connectors
     * Data for MS365 OUath integration
     * @result any successful operation
     * @throws ApiError
     */
    public static async getOauthApps(): Promise<{
        data: Array<OAuthAppsItem>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/auth/oauthApps`,
        });
        return result.body;
    }

    /**
     * Register User Device - Requires Valid WebLogin Session
     * Stores device registration for long lived sessions.
     * @result any successful operation
     * @throws ApiError
     */
    public static async registerDevice({
        requestBody,
    }: {
        /** Device Registration **/
        requestBody: Token,
    }): Promise<{
        /**
         * Device token
         */
        deviceToken?: string,
        /**
         * Token Expiration
         */
        exp?: number,
    }> {
        const result = await __request({
            method: 'POST',
            path: `/auth/registerDevice`,
            body: requestBody,
            errors: {
                400: `Authentication error response`,
                401: `Authentication error response`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Validate Users Reset Password Token
     * This should be called before presenting user with a reset password page
     * @result any Successful operation
     * @throws ApiError
     */
    public static async validateUserPasswordToken({
        id,
        token,
    }: {
        /** Record ID **/
        id: number,
        /** Authentication Token **/
        token: string,
    }): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/auth/resetpass/${id}/${token}`,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update an existing users password
     * Update a record based on data given
     * @result any successful operation
     * @throws ApiError
     */
    public static async updateUserPasswordToken({
        id,
        token,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** Authentication Token **/
        token: string,
        /** user object that needs to be updated **/
        requestBody: userChangePassword,
    }): Promise<{
        /**
         * Username
         */
        username?: string,
    }> {
        const result = await __request({
            method: 'PUT',
            path: `/auth/resetpass/${id}/${token}`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * User Login
     * Logs user into the system
     * @result any successful operation
     * @throws ApiError
     */
    public static async webLogin({
        requestBody,
        authorization,
    }: {
        /** Web Login **/
        requestBody: Login,
        /**  **/
        authorization?: string,
    }): Promise<{
        /**
         * Session token
         */
        token?: string,
        /**
         * Token Expiration
         */
        exp?: number,
        /**
         * Password reset token
         */
        resetToken?: string,
        /**
         * User must reset password
         */
        requirePassReset?: boolean,
        user?: UserPartialItem,
        /**
         * User can post new queries
         */
        canPost?: boolean,
        /**
         * Users theme
         */
        theme?: string,
    }> {
        const result = await __request({
            method: 'POST',
            path: `/auth/webLogin`,
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            errors: {
                400: `Authentication error response`,
                401: `Authentication error response`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

}