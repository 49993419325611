import { UserType } from "@/config";

const UserIndex = () => import(/* webpackChunkName: "userSetup" */ "@/views/admin/users/UserIndex.vue");
const UserProfileSummary = () =>
  import(/* webpackChunkName: "userSetup" */ "@/views/admin/users/UserProfileSummary.vue");
const UserAccountForm = () => import(/* webpackChunkName: "userSetup" */ "@/views/admin/users/UserAccountForm.vue");
const UserAddressForm = () => import(/* webpackChunkName: "userSetup" */ "@/views/admin/users/UserAddressForm.vue");
const UserContactForm = () => import(/* webpackChunkName: "userSetup" */ "@/views/admin/users/UserContactForm.vue");
const UserBioForm = () => import(/* webpackChunkName: "userSetup" */ "@/views/admin/users/UserBioForm.vue");
const UserProfilePictureForm = () =>
  import(/* webpackChunkName: "userSetup" */ "@/views/admin/users/UserProfilePictureForm.vue");
const UserUpcomingVacations = () =>
  import(/* webpackChunkName: "userSetup" */ "@/views/admin/users/UserUpcomingVacations.vue");
const UserBankTransactions = () =>
  import(/* webpackChunkName: "userSetup" */ "@/views/admin/users/UserBankTransactions.vue");
const UserNotificationPreferencesForm = () =>
  import(/* webpackChunkName: "userSetup" */ "@/views/admin/users/UserNotificationPreferencesForm.vue");
const UserSpecialities = () => import(/* webpackChunkName: "userSetup" */ "@/views/admin/users/UserSpecialities.vue");
const UserCommunicationLog = () =>
  import(/* webpackChunkName: "userSetup" */ "@/views/admin/users/UserCommunicationLog.vue");
const UserNoeticSessions = () =>
  import(/* webpackChunkName: "userSetup" */ "@/views/admin/users/UserNoeticSessions.vue");
const UserPlanMembership = () =>
  import(/* webpackChunkName: "userSetup" */ "@/views/admin/users/UserPlanMembership.vue");
  const UserPoolMembership = () =>
  import(/* webpackChunkName: "userSetup" */ "@/views/admin/users/UserPoolMembership.vue");
const UserFileManagement = () => import(/* webpackChunkName: "members" */ "@/views/admin/users/UserFiles.vue");

export default [
  {
    path: "/account",
    name: "Account",
    component: () => import(/* webpackChunkName: "userSetup" */ "@/views/account/Account.vue"),
    meta: { sameUser: true, appClass: "appModeUser", title: "My Account" },
    children: [
      {
        name: "AccountForm",
        path: ":userId/account",
        component: UserAccountForm,
        meta: { title: "Account Info" },
        props: true
      },
      {
        name: "AddressForm",
        path: ":userId/address",
        component: UserAddressForm,
        meta: { title: "Address Info" },
        props: true
      },
      {
        name: "ContactForm",
        path: ":userId/contact",
        component: UserContactForm,
        meta: { title: "Contact Info" },
        props: true
      },
      {
        name: "MyBio",
        path: ":userId/bio",
        component: UserBioForm,
        props: true,
        meta: { disallowedUsers: [UserType.CLIENT], title: "My Bio" }
      },
      {
        name: "ProfilePictureForm",
        path: ":userId/profilePicture",
        component: UserProfilePictureForm,
        props: true,
        meta: { disallowedUsers: [UserType.CLIENT], title: "Profile Picture" }
      },
      {
        name: "UpcomingVacations",
        path: ":userId/upcomingVacations",
        component: UserUpcomingVacations,
        props: true,
        meta: { disallowedUsers: [UserType.CLIENT], title: "Vacation Time" }
      },
      {
        name: "BankTransactions",
        path: ":userId/bankTransactions",
        component: UserBankTransactions,
        props: true,
        meta: { disallowedUsers: [UserType.CLIENT], title: "Bank Transactions" }
      },
      {
        name: "NotificationPrefs",
        path: ":userId/notificationPrefs",
        component: UserNotificationPreferencesForm,
        meta: { title: "Notification Pref." },
        props: true
      },
      {
        name: "Specialities",
        path: ":userId/specialities",
        component: UserSpecialities,
        props: true,
        meta: { disallowedUsers: [UserType.CLIENT], title: "Specialities" }
      },
      {
        name: "CommunicationLog",
        path: ":userId/commLog",
        component: UserCommunicationLog,
        props: true,
        meta: { disallowedUsers: [UserType.CLIENT], title: "Communication Log" }
      },
      {
        name: "NoeticSessions",
        path: ":userId/noeticSessions",
        component: UserNoeticSessions,
        props: true,
        meta: { disallowedUsers: [UserType.CLIENT], title: "Noetic Sessions" }
      },
      {
        name: "PlanMembership",
        path: ":userId/planMembership",
        component: UserPlanMembership,
        props: true,
        meta: { disallowedUsers: [UserType.CLIENT], title: "Plan Membership" }
      }
    ]
  },
  {
    path: "/admin/users",
    name: "UserIndex",
    component: UserIndex,
    meta: { allowedUsers: [UserType.CSR], title: "Users" },
    children: [
      {
        name: "UserProfileSummary",
        path: ":userId",
        component: UserProfileSummary,
        meta: { title: "Profile Summary" },
        props: true
      },
      {
        name: "UserAccountForm",
        path: ":userId/account",
        component: UserAccountForm,
        meta: { title: "Account Info" },
        props: true
      },
      {
        name: "UserAddressForm",
        path: ":userId/address",
        component: UserAddressForm,
        meta: { title: "Address Info" },
        props: true
      },
      {
        name: "UserContactForm",
        path: ":userId/contact",
        component: UserContactForm,
        meta: { title: "Contact Info" },
        props: true
      },
      {
        name: "UserBioForm",
        path: ":userId/bio",
        component: UserBioForm,
        meta: { title: "My Bio" },
        props: true
      },
      {
        name: "UserProfilePictureForm",
        path: ":userId/profilePicture",
        component: UserProfilePictureForm,
        meta: { title: "Profile Picture" },
        props: true
      },
      {
        name: "UserUpcomingVacations",
        path: ":userId/upcomingVacations",
        component: UserUpcomingVacations,
        meta: { title: "Upcoming Vacations" },
        props: true
      },
      {
        name: "UserBankTransactions",
        path: ":userId/bankTransactions",
        component: UserBankTransactions,
        meta: { title: "Bank Transactions" },
        props: true
      },
      {
        name: "UserNotificationPrefs",
        path: ":userId/notificationPrefs",
        component: UserNotificationPreferencesForm,
        meta: { title: "Notification Pref." },
        props: true
      },
      {
        name: "UserSpecialities",
        path: ":userId/specialities",
        component: UserSpecialities,
        meta: { title: "Specialities" },
        props: true
      },
      {
        name: "UserCommunicationLog",
        path: ":userId/commLog",
        component: UserCommunicationLog,
        meta: { title: "Communication Log" },
        props: true
      },
      {
        name: "UserNoeticSessions",
        path: ":userId/noeticSessions",
        component: UserNoeticSessions,
        meta: { title: "Noetic Sessions" },
        props: true
      },
      {
        name: "UserPlanMembership",
        path: ":userId/planMembership",
        component: UserPlanMembership,
        meta: { title: "Plan Membership" },
        props: true
      },
      {
        name: "UserPoolMembership",
        path: ":userId/poolMembership",
        component: UserPoolMembership,
        meta: { title: "Pool Membership" },
        props: true
      },
      {
        name: "UserFileManagement",
        path: ":userId/files",
        component: UserFileManagement,
        meta: { title: "File Management" },
        props: true
      }
    ]
  }
];
