<template>
    <div class="d-flex align-start">
      <o-sheet class="d-flex review-item-sheet">
        <v-btn v-if="canEdit" class="review-item-icon" color="primary" icon @click="$eventBus.$emit('actionClick', tag)">
          <v-icon>$edit</v-icon>
        </v-btn>
        <div style="width: 100%">
          <slot>Something</slot>
        </div>
      </o-sheet>
    </div>
</template>

<style lang="scss" scoped>
.review-item-sheet {
  flex: 1;
  margin-top: 12px;
  margin-bottom: 12px;
  padding-left: 24px;
  padding-right: 24px;
  max-width: 600px;
}
.review-item-icon {
  margin-right: 6px;
}
</style>

<script lang="ts">
import Vue from "vue";
import OSheet from "@/framework/OSheet.vue";

export default Vue.extend({
  components: {
    OSheet
  },
  props: {
    tag: {
      type: String,
      required: true
    },
    canEdit: {
      type: Boolean,
      default: true
    }
  }
});
</script>
