/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * User Invite Record Item
 */
export type UserInviteRecordItem = {
    /**
     * Record Unique ID
     */
    id?: number;
    /**
     * Plan ID
     */
    planId?: number;
    /**
     * User ID
     */
    userId?: number;
    /**
     * User Invite Status
     */
    status?: UserInviteRecordItem.status;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    created?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    updated?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    sent?: number;
    /**
     * User Invite Text
     */
    text?: string;
    /**
     * User Invite Display Name
     */
    displayName?: string;
    /**
     * User Invite Agreement Data
     */
    agreementData?: string;
    /**
     * User Invite Email
     */
    email?: string;
    /**
     * User Unique Id
     */
    userUniqueId?: string;
    /**
     * User Invite First
     */
    first?: string;
    /**
     * User Invite Last
     */
    last?: string;
    /**
     * User Invite Middle
     */
    middle?: string;
    /**
     * User Invite Degrees
     */
    degrees?: string;
    /**
     * User Invite Type
     */
    type?: number;
    /**
     * Flag for show if it is Agreement 1 Done
     */
    agreement1Done?: boolean;
    /**
     * Flag for show if it is Agreement 2 Done
     */
    agreement2Done?: boolean;
    /**
     * Flag for show if it is No Email
     */
    noEmail?: boolean;
    /**
     * Is this user a test user (Excluded from stats)
     */
    testUser?: boolean;
    /**
     * Is this user a Support user (Excluded from stats)
     */
    supportUser?: boolean;
    /**
     * Is this user a Noetic Expert
     */
    noeticExpert?: boolean;
    elegibleTo?: string;
    /**
     * Invitation Link
     */
    link?: string;
    /**
     * API Response Version
     */
    _ver?: number;
}

export namespace UserInviteRecordItem {

    /**
     * User Invite Status
     */
    export enum status {
        NEW = 'new',
        SENT = 'sent',
        ACCEPTED = 'accepted',
        DECLINED = 'declined',
        CANCELLED = 'cancelled',
        EXPIRED = 'expired',
    }


}
