/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Query Search Item
 */
export type QuerySearchItem = {
    /**
     * API Response Version
     */
    _ver?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    created?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    updated?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    meetingTime?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    inviteTime?: number;
    /**
     * Unix Timestamp - To Seconds Precision - For index ordering and display
     */
    sortTime?: number;
    /**
     * Formatted Sort Time
     */
    displayTime?: string;
    /**
     * Record Unique ID
     */
    id?: number;
    /**
     * User ID
     */
    userId?: number;
    /**
     * User Type ID
     */
    userType?: number;
    /**
     * Diagnosis Domain ID
     */
    diagnosisDomainId?: number;
    /**
     * Diagnosis Group ID
     */
    diagnosisGroupId?: number;
    /**
     * Diagnosis ID
     */
    diagnosisId?: number;
    /**
     * Plan Id
     */
    planId?: number;
    /**
     * Grant Id
     */
    grantId?: number;
    /**
     * Pool Id
     */
    poolId?: number;
    /**
     * type
     */
    type?: QuerySearchItem.type;
    /**
     * Query Status
     */
    status?: QuerySearchItem.status;
    /**
     * Query Status -- Display Only
     */
    displayStatus?: QuerySearchItem.displayStatus;
    /**
     * User Type Display
     */
    userTypeDisplay?: string;
    /**
     * User Type Display Abvreviated
     */
    userTypeDisplayAbbr?: string;
    /**
     * Media Type of Query
     */
    mediaType?: QuerySearchItem.mediaType;
    /**
     * Query Header Text
     */
    text?: string;
    /**
     * Diagnosis Domain Name
     */
    diagnosisDomainName?: number;
    /**
     * Diagnosis Group Name
     */
    diagnosisGroupName?: number;
    /**
     * Diagnosis Name
     */
    diagnosisName?: string;
    /**
     * plan Name
     */
    planName?: string;
    /**
     * Grant Name
     */
    grantName?: string;
    /**
     * Pool Name
     */
    poolName?: string;
    /**
     * Query Subject
     */
    subject?: string;
    /**
     * Plan / Pool Name
     */
    planPoolName?: string;
    /**
     * Display Name
     */
    ownerDisplayName?: string;
    /**
     * Meeting requested but unscheduled. Display Only
     */
    meetingRequested?: boolean;
    /**
     * Flag for Blinded Query
     */
    blinded?: boolean;
    /**
     * Flag for Test Query
     */
    ownerIsTest?: boolean;
    /**
     * Flag for CSR Quality Review
     */
    csrReview?: boolean;
}

export namespace QuerySearchItem {

    /**
     * type
     */
    export enum type {
        NORMAL = 'normal',
        BLINDED = 'blinded',
        COMMUNAL = 'communal',
        PRIVATE = 'private',
    }

    /**
     * Query Status
     */
    export enum status {
        OPEN = 'open',
        CLOSED = 'closed',
        ANSWERED = 'answered',
        HELD = 'held',
        PENDING_CHARGE = 'pending_charge',
        CHARGED = 'charged',
        CREATE = 'create',
        CSRHOLD = 'csrhold',
        CANCELLED = 'cancelled',
        PENDING_SURVEY = 'pending_survey',
    }

    /**
     * Query Status -- Display Only
     */
    export enum displayStatus {
        OPEN = 'open',
        CLOSED = 'closed',
        CANCELLED = 'cancelled',
        UNRELEASED = 'unreleased',
    }

    /**
     * Media Type of Query
     */
    export enum mediaType {
        MESSENGER = 'messenger',
        AUDIO = 'audio',
        VIDEO = 'video',
    }


}
