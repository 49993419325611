<template>
  <review-item :tag="tag" v-bind="$attrs">
    <div :class="!isMobile ? ['d-flex', 'justify-space-between', 'align-center'] : undefined">
      <div class="primary--text t-5">
        <strong>{{ $t("wizard.review." + tag) }}</strong>
      </div>
      <div :class="{'t-4 capitalize': true, 'review-item-val': isMobile}">{{ value }}</div>
    </div>
  </review-item>
</template>

<style lang="scss" scoped>
.capitalize {
  text-transform: capitalize;
}
.review-item-val {
  justify-content: flex-end;
  display: flex
}
</style>

<script lang="ts">
import Vue from "vue";
import ReviewItem from "./ReviewItem.vue";

export default Vue.extend({
  components: {
    ReviewItem
  },
  props: {
    tag: {
      type: String,
      required: true
    },

    value: {
      type: String,
      required: true
    }
  },
  computed: {
    isMobile(): boolean {
      // True when the viewport is under the breakpoint for mobile behavior
      return this.$vuetify.breakpoint.smAndDown;
    },
  }
});
</script>