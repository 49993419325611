import _Vue from "vue";
import store from "@/store";

// Plugin to declare global properties
export function GlobalsPlugin(Vue: typeof _Vue): void {
  // Declare readonly property
  Object.defineProperty(Vue.prototype, "$allowedCSRAdmin", {
    get(): boolean {
      return store.getters.isAllowedCSRAdmin;
    }
  });

}

_Vue.use(GlobalsPlugin);
