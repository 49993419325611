/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Membership Plan PartialItem
 */
export type MembershipPlanPartialItem = {
    /**
     * API Response Version
     */
    _ver?: number;
    /**
     * Record Unique ID
     */
    id?: number;
    /**
     * Membership plan name
     */
    name?: string;
    /**
     * Flag for record being active
     */
    active?: boolean;
    /**
     * Flag for plan Active now. Takes into account Active Flag and Date Ranges
     */
    isPlanActiveNow?: boolean;
    /**
     * Flag to use plan date range
     */
    useDate?: boolean;
    /**
     * Flag for record being selectable
     */
    selectable?: boolean;
    /**
     * Membership plan display title
     */
    displayTitle?: string;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    created?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    updated?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    dateStart?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    dateEnd?: number;
    /**
     * Type of user this plan applies to
     */
    userType?: number;
    /**
     * Type of plan
     */
    type?: MembershipPlanPartialItem.type;
}

export namespace MembershipPlanPartialItem {

    /**
     * Type of plan
     */
    export enum type {
        CENSUS = 'census',
        SSO = 'sso',
        REGION = 'region',
        ADVOCATE = 'advocate',
        BLINDED = 'blinded',
        EXPERT = 'expert',
        VTB = 'vtb',
    }


}
