<template>
  <v-menu v-if="showLanguageSelector">
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            elevation="0"
            small
            style="background-color: rgba(255,255,255,0.7);"
            id="selectAppLang"
            v-bind="attrs"
            v-on="{ ...tooltip, ...menu }"
            ><v-icon color="primary">$language</v-icon></v-btn
          >
        </template>
        <span>{{ $t("nav.selectLanguage") }}</span>
      </v-tooltip>
    </template>
    <v-list>
      <v-list-item-group :value="currentLanguage" color="primary">
        <v-list-item two-line v-for="(langDef, lang) in langs" :key="lang" @click="setLang(lang)" :value="lang">
          <v-list-item-content>
            <v-list-item-title>{{ langDef.nativeName }}</v-list-item-title>
            <v-list-item-subtitle>{{ langDef.name }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import Vue from "vue";
import { Languages } from "@/config";
// TODO Should the current language be cached locally so that a user does not need to change it on every visit?
// That should probably go in the main store and a mutator there changes the core language.

import { createHelpers } from "vuex-typescript-interface";
import { RootStoreInterface } from "@/store/types";
const { mapGetters, mapMutations } = createHelpers<RootStoreInterface>();

const getters = mapGetters(["currentLanguage", "showLanguageSelector"]);
const setters = mapMutations(["setLanguage"]);

export default Vue.extend({
  data: () => ({}),
  computed: {
    langs: () => Languages,
    ...getters
  },
  methods: {
    setLang(lang: string) {
      this.setLanguage(lang);
    },
    ...setters
  }
});
</script>
