/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Query State Transition Item
 */
export type QueryStateTransitionRecordItem = {
    /**
     * API Response Version
     */
    _ver?: number;
    /**
     * Record Unique ID
     */
    id?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    created?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    updated?: number;
    /**
     * Query State Transition Notes
     */
    notes?: string;
    /**
     * Record Unique ID
     */
    queryHeaderId?: number;
    /**
     * Old Filter State
     */
    oldFilterState?: QueryStateTransitionRecordItem.oldFilterState;
    /**
     * New Filter State
     */
    newFilterState?: QueryStateTransitionRecordItem.newFilterState;
    /**
     * old state
     */
    oldState?: string;
    /**
     * new state
     */
    newState?: string;
}

export namespace QueryStateTransitionRecordItem {

    /**
     * Old Filter State
     */
    export enum oldFilterState {
        NO_FILTERS = 'no_filters',
        APPLIED = 'applied',
        REMOVED = 'removed',
    }

    /**
     * New Filter State
     */
    export enum newFilterState {
        NO_FILTERS = 'no_filters',
        APPLIED = 'applied',
        REMOVED = 'removed',
    }


}
