<template>
  <v-img :alt="`${name} Logo`" class="shrink mr-2" contain :src="logo" transition="scale-transition" width="196" />
</template>

<script lang="ts">
import Vue from "vue";
import { createHelpers } from "vuex-typescript-interface";
import { ThemeStoreInterface } from "@/store/theme/types";
const { mapGetters } = createHelpers<ThemeStoreInterface>();
const getters = mapGetters(["logo", "name"]);

export default Vue.extend({
  name: "AppBarLogo",

  computed: {
    ...getters
  }
});
</script>
