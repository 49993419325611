<template>
  <div
    class="v-messages theme--light message-caption"
    :class="{ clickable: actionContext }"
    v-html="text"
    @click="click"
  />
</template>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  props: {
    text: {
      type: String,
      required: true
    },
    actionContext: {
      type: String,
      default: null
    }
  },
  methods: {
    click(): void {
      if (this.actionContext) {
        this.$emit("actionClick", this.actionContext);
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.message-caption {
  padding: 0 10px;
}

.clickable {
  text-decoration: underline;
  cursor: pointer;
}
</style>
