import {
  required,
  email,
  max,
  min,
  numeric,
  integer,
  regex,
  confirmed,
  between,
  digits,
} from "vee-validate/dist/rules";
import { extend, configure } from "vee-validate";
import { i18n } from "@/plugins/i18n";
import { ApiError, ValidatorsService } from "@/service";
import { CheckPassword } from "../util/password-strength";
import { DateTime } from "luxon";

// Set up default messages that are localized
configure({
  // this will be used to generate messages.
  defaultMessage: (field, values) => {
    values._field_ = ((i18n.te(`fields.${field}`) ? i18n.t(`fields.${field}`) : field) as string).toLowerCase();
    return i18n.t(`validations.messages.${values._rule_}`, values) as string;
  }
});

// Load rules we expect to need
extend("required", required);
extend("min", min);
extend("max", max);
extend("email", email);
extend("numeric", numeric);
extend("integer", integer);
extend("regex", regex);
extend("confirmed", confirmed);
extend("digits", digits);
extend("between", between);

function dateSortable(date: string): string {
  const [month, day, year] = date.split("/");
  return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
}

//This really should use locale-specific parsing
extend("beforeDate", {
  params: ["target"],
  validate(value, p: Record<string, any>): boolean {
    return !value || !p.target || dateSortable(value) <= dateSortable(p.target);
  },
  message: (v, values): string => {
    values._field_ = i18n.t(`fields.${values._field_}`);
    values._target_ = i18n.t(`fields.${values.target}`);
    return i18n.t("customValidations.messages.beforeDate", values) as string;
  }
});

extend("afterDate", {
  params: ["target"],
  validate(value, p: Record<string, any>): boolean {
    // Lexical after
    return !value || !p.target || dateSortable(value) >= dateSortable(p.target);
  },
  message: (v, values): string => {
    values._field_ = i18n.t(`fields.${values._field_}`);
    values._target_ = i18n.t(`fields.${values.target}`);
    return i18n.t("customValidations.messages.afterDate", values) as string;
  }
});

extend("date", {
  params: ["format"],
  validate(value, p: Record<string, any>): boolean {
    return !value || DateTime.fromFormat(value, p.format ?? "MM/dd/yyyy").toISODate() != null;
  },
  message: (v, values): string => {
    values._field_ = i18n.t(`fields.${values._field_}`);
    return i18n.t("customValidations.messages.date", values) as string;
  }
});

extend("time", {
  validate(value, p: Record<string, any>): boolean {
    return !value || /^(1[0-2]|0?[1-9]):([0-5]?\d)\s(am|pm)$/gi.test(value);
  },
  message: (v, values): string => {
    values._field_ = i18n.t(`fields.${values._field_}`);
    return i18n.t("customValidations.messages.time", values) as string;
  }
});

// Examination of vee-validate code indicates that validate() can be async as it is awaited
extend("telephone", {
  async validate(value): Promise<boolean> {
    try {
      if (!/^[0-9+\-()x ]{7,}$/.test(value)) {
        return false;
      }
      const r = await ValidatorsService.validateTelephone({ number: value });
      return r.valid || false;
    } catch (e) {
      // console.log(e);
      return false;
    }
  },
  message: (v, values): string => {
    values._field_ = i18n.t(`fields.${values._field_}`);
    return i18n.t("customValidations.messages.telephone", values) as string;
  }
});

extend("password", async value => {
  const r = CheckPassword(value);
  if (r.strong) {
    return true;
  } else {
    return i18n.t("passwordStrength.options") as string;
  }
  // const err = [...r.requiredTestErrors];
  // if (r.optionalTestErrors.length) {
  //   err.push(i18n.t("passwordStrength.options") as string);
  // }
  // return err.join("<br />");
});

extend("passwordMatch", {
  params: ["target"],
  validate(value, p: Record<string, any>): boolean {
    return value === p.target;
  },
  message: (): string => {
    return i18n.t("customValidations.messages.passwordMatch") as string;
  }
});

extend("passwordX", async value => {
  try {
    const r = await ValidatorsService.validatePassword({ password: value });
    // console.log(r.valid);
    if (r.valid) {
      return true;
    }
    return r.description || false;
  } catch (e) {
    if (e instanceof ApiError) {
      return e.body.message;
    }
    return (e as any).message ?? e;
  }
});

extend("amount", {
  validate(value): boolean {
    try {
      return /^-?\d+(\.\d{1,2})?$/.test(value);
    } catch (e) {
      // console.log(e);
      return false;
    }
  },
  message: (v, values): string => {
    return i18n.t("customValidations.messages.amount", values) as string;
  }
});

// Positive amount only
extend("posAmount", {
  validate(value): boolean {
    try {
      return /^\d+(\.\d{1,2})?$/.test(value);
    } catch (e) {
      // console.log(e);
      return false;
    }
  },
  message: (v, values): string => {
    return i18n.t("customValidations.messages.amount", values) as string;
  }
});

extend("name", {
  validate(value): boolean {
    try {
      // return !/[\d`~!@#\$%\^\&\*\)\(\+=\]\[\{\}\:\;\"\<\>\?\/\\|]+$/.test(value);
      return !/^[\d`~!@#$%^&*)(+=\][{}:;"<>?/\\|]+$/.test(value);
    } catch (e) {
      // console.log(e);
      return false;
    }
  },
  message: (v, values): string => {
    values._field_ = i18n.t(`fields.${values._field_}`);
    return i18n.t("customValidations.messages.name", values) as string;
  }
});

extend("dupContact", {
  validate(value, p: Record<string, any>): boolean {
    if (!value) return true;
    for (const t in p) {
      if (value == p[t]) {
        return false;
      }
    }
    return true;
  },
  message: (v, values): string => {
    values._field_ = i18n.t(`fields.${v}`);
    return i18n.t("customValidations.messages.dupContact", values) as string;
  }
});

extend("decimal", {
  params: ["decimals", "separator"],
  validate: (value, p: Record<string, any>): boolean => {
    if (value === null || value === undefined || value === "") {
      return false;
    }

    if (!p.decimals) p.decimals = "*";
    if (!p.separator) p.separator = ".";

    if (Number(p.decimals) === 0) {
      return /^-?\d*$/.test(value);
    }
    const regexPart = p.decimals === "*" ? "+" : `{1,${p.decimals}}`;
    return new RegExp(`^[-+]?\\d*(\\${p.separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`).test(value);
  },
  message: (v, values): string => {
    values._field_ = i18n.te(`fields.${v}`) ? i18n.t(`fields.${v}`) : v;
    return i18n.t("customValidations.messages.decimal", values) as string;
  }
});

extend("url", {
  validate(value): boolean {
    try {
      return /\b(?:(?:https?|ftp):\/\/|(([a-z0-9]{1,61}\.)))[-a-z0-9+&@#/%?=~_|!:,.;]*[-a-z0-9+&@#/%=~_|]{2,63}/i.test(
        value
      );
    } catch (e) {
      return false;
    }
  },
  message: (_v, values): string => {
    return i18n.t("customValidations.messages.url", values) as string;
  }
});

extend("zip", {
  validate(value): boolean {
    try {
      return /^\d{5}(-\d{4})?$/.test(value);
    } catch (e) {
      return false;
    }
  },
  message: (_v, values): string => {
    return i18n.t("customValidations.messages.zip", values) as string;
  }
});

extend("otp", {
  validate(value): boolean {
    try {
      return /^\d{6}$/.test(value);
    } catch (e) {
      return false;
    }
  },
  message: (_v, values): string => {
    return i18n.t("customValidations.messages.otp", values) as string;
  }
});

extend("filesize", {
  params: ["size"],
  validate(files: File | File[], { size }: Record<string, any>): boolean {
    if (isNaN(size)) {
      return false;
    }
    var nSize = size * 1024;
    if (!Array.isArray(files)) {
      return files.size <= nSize;
    }
    for (var i = 0; i < files.length; i++) {
      if (files[i].size > nSize) {
        return false;
      }
    }
    return true;
  },
  message: (_v, values): string => {
    return i18n.t("customValidations.messages.fileSize", values) as string;
  }
})
