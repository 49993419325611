<template>
  <password-reset-frame :loading="loading" :title="$t('captions.login')">
    <ValidationObserver ref="obs" v-slot="{ invalid, handleSubmit }">
      <v-form @submit.prevent="handleSubmit(doLogin)">
        <v-container class="px-6">
          <v-row>
            <v-col col="12">
              <VTextFieldWithValidation
                dense
                rules="required|email|max:64"
                type="email"
                prepend-icon="$email"
                v-model="username"
                label="email"
                autocomplete="username"
                id="login-name"
                color="primary"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col col="12">
              <v-password-with-validation
                v-model="password"
                name="password"
                id="password"
                label="password"
                placeholder="enterPassword"
                prepend-icon="$password"
                autocomplete="current-password"
                dense
                :validate="false"
                :data-openreplay-obscured="true"
              />
            </v-col>
          </v-row>
          <v-row v-if="errorText">
            <v-col col="12" class="text-center error--text">
              <span v-html="errorText"></span>
            </v-col>
          </v-row>
          <v-row :class="{ 'd-flex flex-column': isMobile  }">
            <v-col v-if="!isMobile"></v-col>
            <v-col :class="isMobile ? 'text-center' : 'text-left'">
              <o-btn :disabled="invalid" type="submit" id="login">{{ $t("captions.login") }}</o-btn>
            </v-col>
            <v-col :class="isMobile ? 'text-center' : 'text-left'">
              <v-btn text color="primary" id="forgotPassword" :to="{ name: 'ResetPassRequest' }">{{
                $t("loginScreen.forgotPassword")
              }}</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </ValidationObserver>
    <iframe ref="frame" id="cdLoginFrame" style="display:none;" />
  </password-reset-frame>
</template>

<script lang="ts">
// TODO Use prepended or appended icons in form fields
// TODO Colors
import Vue from "vue";
import { ValidationObserver } from "vee-validate";
import VTextFieldWithValidation from "@/components/form/VTextFieldWithValidation.vue";
import VPasswordWithValidation from "@/components/form/VPasswordWithValidation.vue";
import PasswordResetFrame from "@/components/auth/PasswordResetFrame.vue";
import OBtn from "@/framework/OBtn.vue";

import { createHelpers } from "vuex-typescript-interface";
import { RootStoreInterface } from "@/store/types";
const { mapActions, mapGetters } = createHelpers<RootStoreInterface>();

const actions = mapActions(["login"]);
const getters = mapGetters(["errorText"]);

import { ThemeStoreInterface } from "@/store/theme/types";
const { mapGetters: mapThemeGetters } = createHelpers<ThemeStoreInterface>();
const themeGetters = mapThemeGetters(["name", "domain", "infoDomain"]);

export default Vue.extend({
  components: {
    ValidationObserver,
    VTextFieldWithValidation,
    VPasswordWithValidation,
    PasswordResetFrame,
    OBtn
  },

  data: () => ({
    username: "",
    password: "",

    loading: false
  }),
  methods: {
    async doLogin(): Promise<void> {
      this.loading = true;
      try {
        await this.login({
          username: this.username,
          password: this.password
        });
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    // test() {
    //   this.$confirm("Something", { title: "Warning" });
    // },

    ...actions
  },
  computed: {
    ...getters,
    ...themeGetters,
    localeFields(): Record<string, string> {
      return {
        _link_: `<a href="${this.infoDomain}" target="_blank">${this.domain}</a>`,
        _company_: this.name
      };
    },
    isMobile(): boolean {
      // True when the viewport is under the breakpoint for mobile behavior
      return this.$vuetify.breakpoint.width <= 430;
    },
  }
});
</script>

<style lang="scss">
.v-card {
  &.login-card-x {
    background-image: url("~@/assets/footer.png") !important;
    background-repeat: no-repeat !important;
    background-size: 100% 48px !important;
    background-position: bottom center !important;
  }
  .footer-img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 48px;
    z-index: 4;

    .v-image__image {
      background-size: 100% 100% !important;
    }
  }
}

.login-elem {
  max-width: 960px;
}
</style>
