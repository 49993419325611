import _Vue from "vue";
import { NavigationGuardNext } from "vue-router";
import { ConfirmDialogOptions } from "@/plugins/confirm";

const DirtyCheckPlugin = {
  install(Vue: typeof _Vue) {
    Vue.prototype.$showDirty = function(next?: NavigationGuardNext, options?: ConfirmDialogOptions, message?: string): Promise<boolean> {
      const confirmOptions: ConfirmDialogOptions = options ?? {
        title: this.$t("headers.warning") as string,
        icon: "",
        buttonTrueText: this.$t("captions.stay") as string,
        buttonFalseText: this.$t("captions.leavePage") as string
      };
      return new Promise(resolve => {
        this.$confirm(this.$t(message ?? "messages.unsavedProgress") as string, confirmOptions).then((b: boolean) => {
          if (b) {
            next?.(false);
          } else {
            next?.();
          }
          resolve(b);
        });
      });
    }
  }
};

_Vue.use(DirtyCheckPlugin);
