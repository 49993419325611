<template>
  <action :action="action" showText v-on="$listeners">
    <template v-slot:default="{ show }">
      <div v-if="show && !event">
        <v-expand-transition>
          <div class="d-flex flex-row align-baseline justify-end">
            <v-text-field-integer
              ref="field"
              style="width:6rem;"
              v-model="magnitude"
              type="number"
              xlabel="Age Magnitude"
              v-bind:options="{
                  inputMask: '###',
                  outputMask: '###',
                  empty: null,
                  applyAfter: false
                }"
              :properties="{
                filled: true,
                xlabel: 'action.label',
                hint: 'Age',
                'persistent-hint': true,
                autofocus: true,
                placeholder: ' ',
                'persistent-placeholder': true
              }"
            />
            <div style="width: 1rem;" />
            <v-select
              v-model="units"
              style="width: 9rem;flex-grow:0"
              :hint="`Age Units`"
              :items="ageUnits"
              item-text="name"
              item-value="value"
              xlabel="Units"
              filled
              persistent-hint
            ></v-select>
            <v-btn text :disabled="disabled" @click="click">Select</v-btn>
          </div>
        </v-expand-transition>
      </div>
    </template>
  </action>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from "vue";
import Action from "./Action.vue";
import { VTextField } from "vuetify/lib";
import { ChatBotAction, ChatBotEvent, QuerySearchDisplayItem } from "@/service";
import VTextFieldInteger from "vuetify-mask/Integer.vue";

export default (Vue as VueConstructor<
  Vue & {
    $refs: {
      field: typeof VTextField;
    };
  }
>).extend({
  name: "AgeActionComponent",
  components: {
    Action,
    VTextFieldInteger
  },
  props: {
    action: {
      type: Object as PropType<ChatBotAction>,
      required: true
    }
  },
  data: () => ({
    event: undefined as ChatBotEvent | undefined,
    magnitude: "",
    units: QuerySearchDisplayItem.ageUnit.YEAR,
    ageUnits: [
      { value: QuerySearchDisplayItem.ageUnit.DAY, name: "Days" },
      { value: QuerySearchDisplayItem.ageUnit.WEEK, name: "Weeks" },
      { value: QuerySearchDisplayItem.ageUnit.MONTH, name: "Months" },
      { value: QuerySearchDisplayItem.ageUnit.YEAR, name: "Years" }
    ]
  }),
  computed: {
    disabled(): boolean {
      return Number(this.magnitude) < 1 || !this.units;
    }
  },
  methods: {
    click(): void {
      if (!this.disabled) {
        this.event = {
          type: "respond",
          ts: Date.now(),
          clientData: this.action.clientData,
          responses: [this.magnitude, this.units]
        };
        this.$emit("callback", this.event);
      }
    }

    // enter(): void {
    //   if (this.text.trim().length >= this.minCount) {
    //     this.event = {
    //       type: "respond",
    //       ts: Date.now(),
    //       // action: this.action,
    //       clientData: this.action.clientData,
    //       responses: [this.text]
    //     };
    //     this.$emit("callback", this.event);
    //   }
    // }
  },
  created() {
    if (this.action.responses?.length) {
      this.magnitude = this.action.responses[0];
      this.units = this.action.responses[1] as QuerySearchDisplayItem.ageUnit;
    }
  }
});
</script>
