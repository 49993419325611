<template>
  <v-dialog v-model="visible" max-width="800" eager scrollable v-bind="$attrs">
    <v-card :loading="loading" style="position: relative">
      <v-card-title class="px-3 pb-0">
        <v-toolbar flat dense>
          <v-toolbar-title class="primary--text">{{ title }}</v-toolbar-title>
          <v-spacer />
          <v-btn id="dismissCMEInfo" icon @click="visible=false">
            <v-icon color="primary">$close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-tabs v-model="tab">
        <v-tab key="frontmatter">{{ $t("discussion.cmeCard.title") }}</v-tab>
        <v-tab key="faculty">{{ $t("discussion.cmeCard.faculty") }}</v-tab>
      </v-tabs>
      <v-card-text style="height: 600px">
        <v-tabs-items v-model="tab">
          <v-tab-item key="frontmatter">
            <v-container class="cme-matter">
              <div v-if="usesCMS" v-html="html">
                <v-overlay absolute :value="loading">"Loading..."</v-overlay>
              </div>
              <div v-else-if="!!frontMatter" v-html="frontMatter" />
              <div v-else v-html="shortHtml" />
            </v-container>
          </v-tab-item>
          <v-tab-item key="faculty">
            <v-container class="cme-matter cme-faculty">
              <!-- This header data should be int eh CMS with a key in the finding source -->
              <div>
                <div class="cme-section-heading">Disclosure of Conflicts of Interest</div>
                <div class="cme-text">
                  Postgraduate Institute for Medicine (PIM) requires instructors, planners, managers, and other
                  individuals who are in a position to control the content of this activity to disclose any real or
                  apparent conflict of interest (COI) they may have as related to the content of this activity. All
                  identified COI are thoroughly vetted and resolved according to PIM policy. PIM is committed to
                  providing its learners with high quality activities and related materials that promote improvements
                  or quality in healthcare and not a specific proprietary business interest of a commercial interest.
                </div>
              </div>
              <div class="my-2">
                <div class="cme-section-heading">Faculty List and Disclosures</div>
              </div>
              <v-row>
                <template v-for="(f, i) of faculty">
                  <v-col :key="`f${i}`" class="cme-entry">
                    <div class="cme-faculty-name">{{ f.name }}</div>
                    <div class="cme-faculty-affil">{{ f.affiliations }}</div>
                    <div class="cme-faculty-disclosure">{{ f.disclosures }}</div>
                  </v-col>
                  <v-responsive v-if="i % 2 == 1" :key="`w${i}`" width="100%" style="border-top:1px solid #ccc;"/>
                </template>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions v-if="consent">
        <v-spacer />
        <o-btn id="agreeConsent" class="px-4" @click="$emit('agreed')">{{ consentTitle}}</o-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { Document } from "@prismicio/client/types/documents";
import { parseApiError } from "@/plugins/helper";
import { InteractionService, QuerySimulatorService } from "@/service";
import OBtn from "@/framework/OBtn.vue";

export default Vue.extend({
  components: {
    OBtn
  },
  data: () => ({
    loading: false,
    tab: "frontmatter",
    cmeDocument: undefined as Document | undefined,
    loaded: false,
    faculty: [] as Array<{
      name?: string | undefined;
      disclosures?: string | undefined;
      affiliations?: string | undefined;
    }>
  }),
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "CME Info"
    },
    document: {
      type: Object as PropType<Document>,
      required: false
    },
    docId: {
      type: String,
      required: false
    },
    queryKey: {
      type: String,
      required: false
    },
    frontMatter: {
      type: String,
      required: false
    },
    blinded: {
      type: Boolean,
      default: false
    },
    diagnosisId: {
      type: Number,
      required: false
    },
    grantId: {
      type: Number,
      required: false
    },
    planId: {
      type: Number,
      required: false
    },
    consent: {
      type: Boolean,
      default: false
    },
    consentTitle: {
      type: String,
      default: "Acknowledge"
    }
  },
  computed: {
    visible: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit("input", value);
      }
    },
    usesCMS(): boolean {
      return Boolean(this.docId);
    },
    html(): string {
      return this.cmeDocument ? this.cmeDocument.data.rawhtml : `<i>Could not find document:cme-front-matter:${this.docId}</i>`;
    },
    shortHtml(): string {
      return this.cmeDocument ? this.cmeDocument.data.short : "";
    }
  },
  methods: {
    async getCmeContent() {
      if (!this.docId || !this.docId.trim().length) return;
      this.loading = true;
      this.cmeDocument = await this.$prismic.client.getByUID("cme-front-matter", this.docId, {});
      this.$emit('cmsLoaded', this.cmeDocument);
      this.loading = false;
    },
    async getFaculty(key: string | undefined = undefined): Promise<void> {
      if (!key || !key.length) return;
      try {
        this.faculty = (await InteractionService.getFaculty({ key: key }))?.data ?? [];
        this.loaded = true;
      } catch (err) {
        this.$alert(parseApiError(err as any));
      }
    },
    async getSimulatedFaculty() {
      if (!this.diagnosisId || !this.grantId || !this.planId) return;
      try {
        const res =
          (
            await QuerySimulatorService.getFacultyList({
              planId: this.planId,
              grantId: this.grantId,
              dxId: this.diagnosisId
            })
          ).data || [];
        this.faculty = res.map(f => {
          let faculty = {} as {
            name?: string | undefined;
            disclosures?: string | undefined;
            affiliations?: string | undefined;
          };
          faculty.name = f.displayName;
          faculty.affiliations = f.userBio?.affiliations;
          faculty.disclosures = f.userBio?.disclosures;
          return faculty;
        });
        this.loaded = true;
      } catch (err) {
        this.$alert(parseApiError(err as any));
      }
    },
    reset() {
      this.loaded = false;
      this.faculty = [];
    }
  },
  watch: {
    visible(val: boolean) {
      if (val) {
        if (this.queryKey) {
          if (!this.blinded && !this.loaded) this.getFaculty(this.queryKey);
        } else {
          this.getSimulatedFaculty();
        }
      }
    },
    queryKey() {
      this.reset();
    },
    document(val) {
      this.cmeDocument = val;
    },
    docId() {
      this.getCmeContent();
    },
    diagnosisId(val) {
      if (val) {
        this.reset();
        this.getSimulatedFaculty();
      }
    }
  },
  created() {
    if (this.diagnosisId) this.getSimulatedFaculty();
    if (this.docId) this.getCmeContent();
  }
});
</script>

<style lang="scss" scoped>
.cme-matter {
  font-size: 13px;

  :deep(.accme-left-logo) {
    float: left;
    margin: 4px 9px 4px 0;
    img {
      height: 70px;
    }
  }
  :deep(.cme-section-heading) {
    font-weight: bold;
    font-style: italic;
    clear: both;
  }

  :deep(.cme-text) {
    margin-bottom: 9px;
  }

  :deep(.cme-short-description) {
    font-size: 11px;
    margin-left: 1.5em;
    margin-bottom: 9px;
  }
}
.cme-faculty {
  font-size: 13px;
  margin-bottom: 10px;

  :deep(hr) {
    color: var(--v-primary-base);
  }

  .cme-entry {
    white-space: pre-line;
  }

  :deep(.cme-faculty-name) {
    font-weight: bold;
  }

  :deep(.cme-faculty-affil) {
    font-size: 11px;
  }

  :deep(.cme-faculty-disclosure) {
    margin-left: 1.5em;
    margin-top: 4px;
  }
}
</style>
