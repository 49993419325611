import { Route } from "vue-router";

export default [
  {
    path: "/*ask/discussion*",
    name: "Legacy Discussion List",
    redirect: () => {
      return `/discussions`;
    }
  },
  {
    path: "/*ask/dash",
    name: "Legacy Dashboard",
    redirect: () => {
      return `/dashboard`;
    }
  },
  {
    path: "/*ask/wizard",
    name: "Legacy Query Wizard",
    redirect: () => {
      return `/newQuery`;
    }
  },
  {
    path: "/*ask*/:id",
    name: "Legacy Discussion Path",
    redirect: (to: Route) => {
      const { id } = to.params;
      return `/discussion/${id}`;
    }
  },
  {
    path: "/discussions/:id",
    name: "Discussion Typo",
    redirect: (to: Route) => {
      const { id } = to.params;
      return `/discussion/${id}`;
    }
  },
  {
    path: "/user*",
    name: "Legacy User Profile",
    redirect: () => {
      return `/account`;
    }
  },
  {
    path: "/dashbaord*",
    name: "New Portal Dashboard Typo",
    redirect: () => {
      return `/dashboard`;
    }
  }
];
