/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Minimal user info for reporting display
 */
export type UserReportingInfo = {
    /**
     * API Response Version
     */
    _ver?: number;
    /**
     * Record Unique ID
     */
    id?: number;
    /**
     * User first name
     */
    firstName?: string;
    /**
     * User middle name
     */
    middleName?: string;
    /**
     * User last name
     */
    lastName?: string;
    /**
     * User display name
     */
    displayName?: string;
    /**
     * Always pesent display name
     */
    resolvedDisplayName?: string;
    /**
     * User login
     */
    userName?: string;
    /**
     * User Profile Img
     */
    avatar?: string;
    /**
     * Email Address
     */
    email?: string;
    /**
     * User Type ID
     */
    userType?: number;
    /**
     * User Type Display
     */
    typeDisplay?: string;
    /**
     * User degrees
     */
    degrees?: string;
    /**
     * Is this a test user?
     */
    isTest?: boolean;
    /**
     * User Registration Status
     */
    regStatus?: UserReportingInfo.regStatus;
}

export namespace UserReportingInfo {

    /**
     * User Registration Status
     */
    export enum regStatus {
        PENDING = 'pending',
        NEW = 'new',
        VERIFY_SENT = 'verify_sent',
        VERIFIED = 'verified',
        WELCOME_SENT = 'welcome_sent',
        SKIPPED = 'skipped',
        NEW_CREG = 'new_creg',
        INVITED = 'invited',
        PRE_PLAN_INVITE = 'pre_plan_invite',
    }


}
