<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        style="width: 8rem;flex-grow:0"
        v-model="date"
        v-bind="attrs"
        v-on="on"
        label="Date of Birth"
        readonly
      ></v-text-field>
    </template>
    <v-date-picker
      ref="picker"
      v-model="date"
      :active-picker.sync="activePicker"
      :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)"
      min="1910-01-01"
      @change="save"
    ></v-date-picker>
  </v-menu>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import { VDatePicker, VMenu } from "vuetify/lib";

export default (Vue as VueConstructor<
  Vue & {
    $refs: {
      picker: InstanceType<typeof VDatePicker>;
      menu: InstanceType<typeof VMenu>;
    };
  }
>).extend({
  props: {
    value: {
      required: true,
      validator: prop => typeof prop === "string" || prop === null
    }
  },
  data: () => ({
    // date: null as string | null,
    menu: false,
    activePicker: "YEAR"
  }),
  computed: {
    date: {
      get() {
        return this.value;
      },
      set(v: string | null) {
        this.$emit("input", v);
      }
    }
  },
  watch: {
    menu: {
      handler(val): void {
        if (val) {
          console.log("Resetting");
          this.$nextTick(() => {
            // this.pickerDate = undefined;
            this.date = null;
            this.activePicker = "YEAR";
          });
        }
      },
      immediate: true

      //   val &&
      //     setTimeout(() => {
      //       this.activePicker = "YEAR";
      //       this.pickerDate = null;
      //     });
    }
  },
  methods: {
    save(date: string | undefined) {
      (this.$refs.menu as any).save(date);
      //   this.pickerDate = date;
    }
  }
});
</script>
