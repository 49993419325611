<template>
  <v-btn v-bind="$attrs" v-on="$listeners" color="primary" style="background-color:white" text elevation="2"
    ><slot
  /></v-btn>
</template>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({});
</script>
