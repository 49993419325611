import Tracker from "@openreplay/tracker";
import trackerVuex from "@openreplay/tracker-vuex";
// import trackerAssist from "@openreplay/tracker-assist";

const pKey = process.env.VUE_APP_OPENREPLAY_PROJECT_KEY as string;

// eslint-disable-next-line @typescript-eslint/ban-types
export let openreplayVuexPlugin: Function | undefined = undefined;
export let openreplayTracker: Tracker | undefined = undefined;

if (pKey.trim() !== "") {
  openreplayTracker = new Tracker({
    projectKey: process.env.VUE_APP_OPENREPLAY_PROJECT_KEY as string,
    ingestPoint: "https://openreplay.onviv.com/ingest",
    defaultInputMode: 0,
    obscureTextNumbers: false,
    obscureTextEmails: false,
    __DISABLE_SECURE_MODE: true
  });

  openreplayVuexPlugin = openreplayTracker.use(trackerVuex()); // check list of available options below
  // openreplayTracker.use(trackerAssist({
  // }));
  openreplayTracker.start().catch((e) => { console.error(e);});
}
