/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Pool Stats Record Item
 */
export type PoolStatsRecordItem = {
    /**
     * User Type Display
     */
    typeDisplay?: string;
    /**
     * Count of Users with thie Status and Type
     */
    count?: number;
    /**
     * User Registration Status
     */
    regStatus?: PoolStatsRecordItem.regStatus;
}

export namespace PoolStatsRecordItem {

    /**
     * User Registration Status
     */
    export enum regStatus {
        PENDING = 'pending',
        NEW = 'new',
        VERIFY_SENT = 'verify_sent',
        VERIFIED = 'verified',
        WELCOME_SENT = 'welcome_sent',
        SKIPPED = 'skipped',
        NEW_CREG = 'new_creg',
        INVITED = 'invited',
        PRE_PLAN_INVITE = 'pre_plan_invite',
    }


}
