/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { request as __request } from '../core/request';

export class ValidatorsService {

    /**
     * Validate a password string
     * Returns Valid boolean for password
     * @result any successful operation
     * @throws ApiError
     */
    public static async validatePassword({
        password,
    }: {
        /** Password string to validate (url encoded) **/
        password?: string,
    }): Promise<{
        valid: boolean,
        description?: string,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/validate/password`,
            query: {
                'password': password,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Invalid Parameters`,
            },
        });
        return result.body;
    }

    /**
     * Validate and E164 format a telephone number
     * Returns the E164 formatted string for valid numbers
     * @result any successful operation
     * @throws ApiError
     */
    public static async validateTelephone({
        number,
    }: {
        /** Telephone number to validate (url encoded) **/
        number?: string,
    }): Promise<{
        valid: boolean,
        formatted?: string,
        smsAllowed?: boolean,
        countryCode?: number,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/validate/telephone`,
            query: {
                'number': number,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Invalid Parameters`,
            },
        });
        return result.body;
    }

}