/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * User Bank
 */
export type UserBankRecordItem = {
    /**
     * Record Unique ID
     */
    id?: number;
    /**
     * User ID
     */
    userId?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    date: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    effectiveDate?: number;
    /**
     * User Bank Description
     */
    description: string;
    /**
     * Amount
     */
    amount: number;
    /**
     * Transaction Type
     */
    transactionType?: UserBankRecordItem.transactionType;
    /**
     * Source
     */
    source?: UserBankRecordItem.source;
    /**
     * Status
     */
    status?: UserBankRecordItem.status;
    /**
     * Record Unique ID
     */
    queryHeaderId?: number;
    /**
     * Record Unique ID
     */
    grantId?: number;
    /**
     * Record Unique ID
     */
    planId?: number;
    /**
     * Flag for adjustment
     */
    adjustment?: boolean;
    /**
     * Flag for allowing users to adjust Grant / Plan Id.  Input only
     */
    overrideQuerySettings?: boolean;
    /**
     * API Response Version
     */
    _ver?: number;
}

export namespace UserBankRecordItem {

    /**
     * Transaction Type
     */
    export enum transactionType {
        PAID = 'Paid',
        EARNED = 'Earned',
        ADJUSTMENT = 'Adjustment',
    }

    /**
     * Source
     */
    export enum source {
        SESSION = 'session',
        ASYNC = 'async',
        CHECK = 'check',
        DONATE = 'donate',
        QCCFA = 'qccfa',
        MANUAL = 'manual',
    }

    /**
     * Status
     */
    export enum status {
        CREDIT = 'credit',
        DEBIT_PENDING = 'debit-pending',
        DEBIT = 'debit',
    }


}
