<template>
  <v-dialog v-model="visible" :max-width="mobile ? '100%' : '791px'" persistent class="ma-0" :fullscreen="$vuetify.breakpoint.smAndDown">
    <v-card :loading="cardLoading">
      <v-card-title v-if="!mobile">
        <v-toolbar flat dense>
          <v-toolbar-title class="primary--text ls-0">{{ $t('discussion.index.title.discussion') }}</v-toolbar-title>
        </v-toolbar>
      </v-card-title>
      <v-card-subtitle class="ml-md-3 m-0 pt-2 pb-0">
        <p class="subtitle-para pb-1 black--text mb-0 ls-0" :class="isDraftLimitReached ? 'red--text' : 'mb-2'">{{
          isDraftLimitReached ? $t('discussion.error.maxDraft') : $t('discussion.index.caption.draftDiscussion') }}
        </p>
        <div class="discussion-btn-wrapper">
          <button type="button" color="primary" @click="visible = false; $emit('start-discussion')" :class="{ 'disabled-button': isDraftLimitReached }"
            class="font-weight-regular v-btn o-btn">{{ $t('discussion.index.caption.newDiscussion') }}</button>
        </div>
      </v-card-subtitle>
      <v-card-text class="pb-0 pt-0">
        <p class="text-right pe-6 text-md-h6 mt-3 mt-md-0 text-subtitle-1 d-flex d-md-block   draft-text ls-0"
          :class="isDraftLimitReached ? 'red--text' : 'black--text'"><strong> {{ draftCount }} / {{ maxDraft }}</strong>
         <strong> Drafts</strong>
        </p>
        <v-container class="pe-5">
          <v-row v-for="draft  of draftList" class="draft-wrapper">
            <v-col v-if="isOpenTrash(draft)" cols="12"
              class="d-flex flex-column justify-center align-md-center align-start trash-section-wrapper">
              <p v-html="$t('discussion.trash.caption')" class="black--text trash-section-title ls-0"></p>
              <div class="trash-actions">
                <o-btn color="primary" outlined class="font-weight-regular" @click="draft.openTrash = false">
                  {{ $t('captions.cancel') }}
                </o-btn>
                <o-btn color="primary" class="font-weight-regular ms-6"
                  @click="onDeleteDraft(draft.id); draft.openTrash = false">
                  {{ $t('captions.delete') }}
                </o-btn>
              </div>
            </v-col>
            <v-col cols="12" class="content-wrapper" v-else>
              <v-row> 
                <v-col cols="12" md="8"  class="draft-content  d-md-flex flex-md-column ">
                  <p class="black--text mb-0 ls-0"><v-icon color="primary" v-if="hasMediaType(draft)"  class="me-2">{{ mediaTypeIcon(draft.mediaType) }}</v-icon>
                   {{ draftQuestionCount(draft.id) }} {{ draftQuestionText(draft.id)  }} {{ draftQuestionCount(draft.id) ? '-' : '' }} {{ draft.diagnosis?.name ? draft.diagnosis?.name : 'Diagnosis Not Yet Selected' }}</p>
                  <p v-if="hasPlanSelectable(draft)" class="mb-0">{{ draft.plan?.displayTitle }} </p>
                  <p v-if="hasMatchingExpertSelectionMode(draft)"> <span v-if="hasPlanSelectable(draft)">-</span> {{ draft.pool?.displayName }}</p>
                  <p class="ls-0 question-subject mt-4 mt-md-auto mb-0">{{  draftQuestionHistoryText(draft) }}</p>
                </v-col>
                <v-col cols="12" md="4"  class="text-left text-md-right draft-content-right ps-0 pe-md-5 pe-0 d-md-flex flex-md-column">
                  <p class="mb-0 pe-3 ls-0 d-flex align-start"><v-btn icon class="trash-icon ms-auto me-md-2 me-0" @click="draft.openTrash = true"><v-icon
                        color="error" size="small">$trash</v-icon></v-btn><span
                      class="black--text ls-0 date-wrapper">{{ createDate(draft.id) }}</span></p>
                  <p class="pe-md-3 pe-0 mb-0 text-md-right text-left last-comment-owner ms-md-auto ms-0 ls-0">By {{ (draft.updatedBy == user?.id) ?
                    'You' : (draft.updatedBy != userCSR && user?.id != userCSR) ? 'Onviv Support' :
                      `${draft.userUpdated?.firstName} ${draft.userUpdated?.lastName}` }}</p>
                  <o-btn color="primary" class="px-6 me-md-1 font-weight-regular open-draft-btn  mt-4 mt-md-auto mb-0 align-self-md-end"
                    @click="openDraft(draft.id)">
                    {{ $t('discussion.index.caption.openDraft') }}
                  </o-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import Vue from "vue";
import OBtn from "@/framework/OBtn.vue";
import { QueryService, QueryHeaderInputItem, QuerySearchDisplayItem, PreviewStateItem} from "@/service";
import { DateTime } from "luxon";
import { UserType } from "@/config";



interface DraftItem extends QueryHeaderInputItem {
  openTrash: boolean;
}

import { createHelpers } from "vuex-typescript-interface";
import { RootStoreInterface } from "@/store/types";
const { mapGetters, mapMutations } = createHelpers<RootStoreInterface>();

const getters = mapGetters(["user", "isDraftModalOpen"])
const mutations = mapMutations(["setDraftModalOpen"])


interface DraftItem extends QueryHeaderInputItem {
  openTrash: boolean;
}

export default Vue.extend({
  components: {
    OBtn,
  },
  data: () => ({
    visible: false,
    isTrashModalVisbile: false,
    draftList: [] as Array<DraftItem> | undefined,
    loading: false,
    draftCount: 0 as number | undefined,
    maxDraft: 0 as number | undefined,
    cardLoading: false,
    darftUserId: '' as string | number,
    userCSR: 0,
    planSelect: ''


  }),
  props: {
    userId: {
      type: [String, Number],
      required: false
    }
  },
  computed: {
    ...getters,
    mobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isDraftLimitReached(): boolean {
      return (this.draftCount ?? 0) >= (this.maxDraft ?? 0)
    }

  },
  methods: {
    ...mutations,
    async getDraftList() {
      this.loading = true;
      let queryId = +(this.$route.query["draftId"]);
      this.$emit('show-loader');

      try {
        this.darftUserId = this.userId ? this.userId : (this.user?.id !== undefined ? this.user.id : 0);
        let draftData = await QueryService.getQueryHeaderInputList({ draftInclude: 'draft', userId: +(this.darftUserId) as number, pageSize: 5, activeInclude: "active" });

        if (draftData.data?.length == 0 || queryId) {
          if (queryId && draftData.data) {
            this.$emit('query-data', draftData.data.filter(query => query.id == queryId));
          }
          this.$emit('show-chatbot');
          this.$emit('start-discussion');
          return;
        }


        this.draftList = draftData?.data?.map((draft) => ({
          ...draft,
          openTrash: false,
        })).sort((a, b) => {
          if (a.created && b.created) {
            return b.created - a.created;
          } else {
            return 0;
          }
        });


        let draftStats = await QueryService.getQueryHeaderInputStatsByUserId({ userid: +(this.darftUserId) as number })
        this.draftCount = draftStats.draftCount;
        this.maxDraft = draftStats.maxDrafts;
        queryId ? this.visible = false : this.visible = true;
      } catch (e) {
        console.log(e);
      }
      this.$emit('show-chatbot');
    },
    createDate(draftId: number | undefined) {
      let activeDraft = this.draftList?.find(draft => draft.id == draftId);
      let activeDraftDate = activeDraft?.updated ? DateTime.fromSeconds(activeDraft?.updated).toFormat('MM/dd/yyyy h:mma') : "";
      return activeDraftDate;
    },
    draftQuestionCount(draftId: number | undefined) {
      let activeDraft = this.draftList?.find(draft => draft.id == draftId);
      return activeDraft?.clientData?.questionCount ? activeDraft?.clientData?.questionCount : '';
    },
    draftQuestionText(draftId: number | undefined){
      let activeDraft = this.draftList?.find(draft => draft.id == draftId);
      let questionCount = activeDraft?.clientData?.questionCount;
    return questionCount > 1 ? 'Questions' : questionCount == 1 ? 'Question' : ''; 

    },
    async onDeleteDraft(id: number | undefined) {
      this.cardLoading = true;
      try {
        await QueryService.deleteQueryHeaderInputById({ id: id as number });
        this.$nextTick(() => {
          this.draftList = this.draftList?.filter(i => i.id != id);
          if (this.draftCount) this.draftCount = --(this.draftCount);
          if (this.draftCount == 0){
          this.$emit('start-discussion');
          this.visible = false;
          } 
        });
      } catch (e) {
        console.log(e);
      }
      this.cardLoading = false;
    },
    openDraft(draftId: number | string | undefined) {
      const targetRoute = {
        name: 'NewQueryProxy',
        params: { userId: this.darftUserId as string },
        query: { draftId: draftId?.toString() },
      };

      const targetRouteString = this.$router.resolve(targetRoute).href;

      if (this.$route.fullPath !== targetRouteString) {
        window.location.assign(targetRouteString);
      }
    },
    mediaTypeIcon(mediaType: any): string {
      switch (mediaType) {
        case QuerySearchDisplayItem.mediaType.MESSENGER:
          return "$textAttachment";
        case QuerySearchDisplayItem.mediaType.VIDEO:
          return "$videoAttachment";
        case QuerySearchDisplayItem.mediaType.AUDIO:
          return "$audioAttachment";
        default:
          return "";
      }
    },
    draftQuestionHistoryText(draft: DraftItem): string {
      return  (draft.text && draft.history) ? draft.text : draft.history ?
        draft.history : 'Subject Not Yet Entered';
    },
    // conditional check methods
    hasMediaType(draft:DraftItem): boolean {
    return !!draft.mediaType;
    },
    hasMatchingExpertSelectionMode(draft:DraftItem): boolean {
      return !!draft.pool?.name && draft.expertSelectionMode === this.planSelect;
    },
    hasPlanSelectable(draft: DraftItem): boolean {
      return !!draft.plan?.name && !!draft.plan.selectable
    },
    isOpenTrash(draft: DraftItem):  boolean {
      return draft.openTrash
    }
  },
  watch: {
    visible(newVal) {
      if(newVal) {
        this.setDraftModalOpen(true);
      } else {
        this.setDraftModalOpen(false);
      }
    }
  },
  mounted() {
    // redirect to 404 - if end user try to access other drafts
    let userId = +(this.$route.params.userId);
    if(userId && this.user?.userType != UserType.CSR) {
      if(this.user?.id != userId) {
        this.$router.push({name: 'NotFound'})
      }
    }
    this.getDraftList();
    this.userCSR = UserType.CSR;
    this.planSelect = PreviewStateItem.expertSelectionMode.PLAN_SELECT;
  }
})
</script>
<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 6px;
  border-radius: 4px;
  display: absolute;
  right: 8px
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

:deep(.v-toolbar__content),
:deep(.v-toolbar__extension) {
  padding: 0;
}

.question-subject {
  line-height: 1.32;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 38px;
}


.container {
  max-height: 460px;
  display: relative;
  overflow-y: auto
}

.draft-wrapper {
  border: 1px solid var(--v-primary-base);
  border-radius: 10px;
  margin-bottom: 23px;
}

:deep(.v-dialog) {
  margin-top: 74px;
  max-height: calc(100vh - 100px);
}

.v-dialog>.v-card>.v-card__text {
  padding: 16px 32px 16px 40px;
}

.trash-icon {
  bottom: 10px;
}

.subtitle-para {
  margin-left: -12px;
}

.last-comment-owner {
  position: relative;
  bottom: 16px;
  font-size: 12px;
  max-width: 135px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.open-draft-link {
  text-decoration: none;
}

.trash-section-title {
  font-size: 18px;
}

.trash-section-wrapper {
  min-height: 118px;
}

p.draft-text {
  margin-bottom: 9px;
}

.ls-0 {
  letter-spacing: 0;
}

.discussion-btn-wrapper {
  padding-left: 5px;

  button {
    padding: 0 16px;
    height: 36px;
    background-color:  var(--v-primary-base) ;
    color: #ffffff;
    border-radius: 10px;

    &.disabled-button {
      background-color: rgba(0, 0, 0, 0.12);
      color: rgba(0, 0, 0, 0.26);
      cursor: not-allowed;
      pointer-events: none;
    }

    &:hover {
      opacity: 0.9;
    }
  }
}

.font-weight-regular.open-draft-btn {
  position: relative;
  bottom: 7px;
}

.date-wrapper {
  width: 135px;
  max-width: 100%
}

.draft-content {
  padding-left: 20px;
}

.draft-count {
      flex-flow: row;
    }

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  :deep(.v-dialog) {
    margin: 0;
  max-height: 100%;

  }

  .v-dialog>.v-card {
    padding: 60px 20px  20px 20px !important;

    .v-card__subtitle, .discussion-btn-wrapper, .v-card__text, .dark-text{
      padding: 0;
    }

    .discussion-btn-wrapper button {
      width: 100%;
    }

    .container {
      padding-right:2px !important;
      padding-left: 0;
      margin-right: -4px;
      max-height: unset;

      .row {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
      }
    }

    .subtitle-para {
      margin: 0;
    }

    .draft-text {
      flex-flow: row-reverse;
      justify-content: space-between;
      padding-right: 0 !important;
    }

    .draft-content, .draft-content-right {
      padding: 0;
    }

    .trash-icon {
      position: absolute;
      top: -8px;
      right: 0;
      z-index: 99;
    }

    .content-wrapper {
      .row {
        position: relative;
        padding: 16px;
        padding: 0;
        margin: 0;
      }
    }
    .last-comment-owner {
      bottom: 0;
      max-width: 100%;
    }

    .date-wrapper {
      width: 100%;
    }

  }

}
</style>
