/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { meta } from '../models/meta';
import type { QueryAggregationItem } from '../models/QueryAggregationItem';
import type { queryBuckets } from '../models/queryBuckets';
import type { QueryCSRStatsRecordItem } from '../models/QueryCSRStatsRecordItem';
import type { QueryExpertRecordItem } from '../models/QueryExpertRecordItem';
import type { QueryFilters } from '../models/QueryFilters';
import type { QueryGrantPlanItem } from '../models/QueryGrantPlanItem';
import type { QueryHeaderInputItem } from '../models/QueryHeaderInputItem';
import type { QueryHeaderInputStatsRecordItem } from '../models/QueryHeaderInputStatsRecordItem';
import type { QueryHeaderPartialList } from '../models/QueryHeaderPartialList';
import type { QueryHeaderRecordItem } from '../models/QueryHeaderRecordItem';
import type { QueryHeaderStatusCountItem } from '../models/QueryHeaderStatusCountItem';
import type { QueryHeaderTextRecordItem } from '../models/QueryHeaderTextRecordItem';
import type { QueryIndexFullDisplay } from '../models/QueryIndexFullDisplay';
import type { QueryInviteRecordItem } from '../models/QueryInviteRecordItem';
import type { QueryResponseRecordItem } from '../models/QueryResponseRecordItem';
import type { QueryResponseTextRecordItem } from '../models/QueryResponseTextRecordItem';
import type { QuerySearchItem } from '../models/QuerySearchItem';
import type { QueryStateTransitionRecordItem } from '../models/QueryStateTransitionRecordItem';
import { request as __request } from '../core/request';

export class QueryService {

    /**
     * Query Drafts.  Find query header input by ID.
     * Query Drafts.  Returns matching record
     * @result QueryHeaderInputItem successful operation
     * @throws ApiError
     */
    public static async getQueryHeaderInputById({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<QueryHeaderInputItem> {
        const result = await __request({
            method: 'GET',
            path: `/server/query/headerInput/${id}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Query Drafts.  Update an existing query header input
     * Query Drafts.  Update a record based on data given
     * @result QueryHeaderInputItem Successful operation
     * @throws ApiError
     */
    public static async updateQueryHeaderInput({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** QueryHeaderInput object that needs to be updated **/
        requestBody: QueryHeaderInputItem,
    }): Promise<QueryHeaderInputItem> {
        const result = await __request({
            method: 'PUT',
            path: `/server/query/headerInput/${id}`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Query Drafts.  Remove a record from database.  Internal only
     * Query Drafts.  Removes a record from the database
     * @result any Successful operation
     * @throws ApiError
     */
    public static async deleteQueryHeaderInputById({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/server/query/headerInput/${id}`,
            errors: {
                400: `Error: Update Failed`,
                401: `Authentication error response`,
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update an existing query invite
     * Update a record based on data given
     * @result QueryInviteRecordItem Successful operation
     * @throws ApiError
     */
    public static async updateQueryInvite({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** Query object that needs to be updated **/
        requestBody: QueryInviteRecordItem,
    }): Promise<QueryInviteRecordItem> {
        const result = await __request({
            method: 'PUT',
            path: `/server/query/invite/${id}`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Delete an Invite
     * Remove a record based on data given
     * @result any Successful operation
     * @throws ApiError
     */
    public static async deleteQueryInvite({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/server/query/invite/${id}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find query response by ID.
     * Returns matching record
     * @result QueryResponseRecordItem successful operation
     * @throws ApiError
     */
    public static async getQueryResponseById({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<QueryResponseRecordItem> {
        const result = await __request({
            method: 'GET',
            path: `/server/query/response/${id}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update an existing query response
     * Update a record based on data given
     * @result QueryResponseRecordItem Successful operation
     * @throws ApiError
     */
    public static async updateQueryResponse({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** Query object that needs to be updated **/
        requestBody: QueryResponseRecordItem,
    }): Promise<QueryResponseRecordItem> {
        const result = await __request({
            method: 'PUT',
            path: `/server/query/response/${id}`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Delete an existing query response.  Internal Only
     * Internal Only
     * @result any Successful operation
     * @throws ApiError
     */
    public static async deleteQueryResponse({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/server/query/response/${id}`,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find iCal download for Query.
     * Returns matching ICS File
     * @result any File Content
     * @throws ApiError
     */
    public static async getICalForQuery({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/server/query/${id}/iCal`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find query expert by ID.
     * Returns matching record
     * @result QueryExpertRecordItem successful operation
     * @throws ApiError
     */
    public static async getQueryExpertById({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<QueryExpertRecordItem> {
        const result = await __request({
            method: 'GET',
            path: `/server/query/expert/${id}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update an existing query expert
     * Update a record based on data given
     * @result QueryExpertRecordItem Successful operation
     * @throws ApiError
     */
    public static async updateQueryExpert({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** Query object that needs to be updated **/
        requestBody: QueryExpertRecordItem,
    }): Promise<QueryExpertRecordItem> {
        const result = await __request({
            method: 'PUT',
            path: `/server/query/expert/${id}`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find multiple query experts.  Filterable by queryHeaderId, userId, status
     * Returns one or more matching records
     * @result any successful operation
     * @throws ApiError
     */
    public static async getQueryExpertList({
        queryHeaderId,
        userId,
        expertStatus,
        page = 1,
        pageSize = 10,
    }: {
        /** Find records associated with a given Query Header **/
        queryHeaderId?: number,
        /** Find records associated with a given User **/
        userId?: number,
        /** Filter by Participant Status **/
        expertStatus?: 'invited' | 'declined' | 'claimed' | 'missedout' | 'latereview' | 'timedout' | 'cancelled' | 'answered' | 'followup' | 'user' | 'csr',
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
    }): Promise<{
        data?: Array<QueryExpertRecordItem>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/query/expert/list`,
            query: {
                'queryHeaderId': queryHeaderId,
                'userId': userId,
                'expertStatus': expertStatus,
                'page': page,
                'pageSize': pageSize,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Source: Elastic. Find featured query for dashboard
     * Returns one  matching records.
     * @result any successful operation
     * @throws ApiError
     */
    public static async getQueryFeatured({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<{
        data?: Array<QueryIndexFullDisplay>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/query/featured/${id}`,
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find Grant and Plan associated to a Query ID.
     * Returns matching record
     * @result QueryGrantPlanItem successful operation
     * @throws ApiError
     */
    public static async getQueryGrantPlan({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<QueryGrantPlanItem> {
        const result = await __request({
            method: 'GET',
            path: `/server/query/${id}/grantPlan`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find query header by ID.
     * Returns matching record
     * @result QueryHeaderRecordItem successful operation
     * @throws ApiError
     */
    public static async getQueryHeaderById({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<QueryHeaderRecordItem> {
        const result = await __request({
            method: 'GET',
            path: `/server/query/${id}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update an existing query header
     * Update a record based on data given
     * @result QueryHeaderRecordItem Successful operation
     * @throws ApiError
     */
    public static async updateQueryHeader({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** QueryHeader object that needs to be updated **/
        requestBody: QueryHeaderRecordItem,
    }): Promise<QueryHeaderRecordItem> {
        const result = await __request({
            method: 'PUT',
            path: `/server/query/${id}`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Query Drafts.  Filterable by diagnosisId, grantId, planId, poolId, userId.  Sort by ID, Date
     * Query Drafts.  Returns one or more matching records
     * @result any successful operation
     * @throws ApiError
     */
    public static async getQueryHeaderInputList({
        activeInclude = 'all',
        draftInclude = 'all',
        diagnosisId,
        grantId,
        planId,
        poolId,
        userId,
        userIdCreated,
        page = 1,
        pageSize = 10,
        sort,
    }: {
        /** Records to Include based on Active Flag.  Allows for inactive only if desired. **/
        activeInclude?: 'all' | 'active' | 'inactive',
        /** Records to Include based on Draft Status. **/
        draftInclude?: 'all' | 'draft' | 'completed',
        /** Find records associated with a given Diagnosis  **/
        diagnosisId?: number,
        /** Find records associated with a given Grant **/
        grantId?: number,
        /** Find records associated with a given Plan **/
        planId?: number,
        /** Find records associated with a given Pool **/
        poolId?: number,
        /** Find records associated with a given User **/
        userId?: number,
        /** Find records created by a given User **/
        userIdCreated?: number,
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
        /** Sort Options - Comma delimited list of key:order. order optional - default ASC  **/
        sort?: string,
    }): Promise<{
        data?: Array<QueryHeaderInputItem>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/query/headerInput/list`,
            query: {
                'activeInclude': activeInclude,
                'draftInclude': draftInclude,
                'diagnosisId': diagnosisId,
                'grantId': grantId,
                'planId': planId,
                'poolId': poolId,
                'userId': userId,
                'userIdCreated': userIdCreated,
                'page': page,
                'pageSize': pageSize,
                'sort': sort,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find query header input Stats by User ID.
     * Returns matching record
     * @result QueryHeaderInputStatsRecordItem successful operation
     * @throws ApiError
     */
    public static async getQueryHeaderInputStatsByUserId({
        userid,
    }: {
        /** User ID **/
        userid: number,
    }): Promise<QueryHeaderInputStatsRecordItem> {
        const result = await __request({
            method: 'GET',
            path: `/server/query/headerInput/stats/${userid}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * INTERNAL -- Source: DB -- not used by Front End
     * Returns one or more matching records
     * @result any successful operation
     * @throws ApiError
     */
    public static async getQueryHeaderList({
        diagnosisId,
        grantIds,
        planId,
        poolIds,
        queryStatus,
        type,
        mediaType,
        text,
        tags,
        userId,
        inclUserPartialRecord = false,
        meetingStart,
        meetingEnd,
        createdStart,
        createdEnd,
        inviteStart,
        inviteEnd,
        userType,
        page = 1,
        pageSize = 10,
        sort,
    }: {
        /** Find records associated with a given Diagnosis  **/
        diagnosisId?: number,
        /** Comma delimited list of grant ids.  Match to any  **/
        grantIds?: string,
        /** Find records associated with a given Plan **/
        planId?: number,
        /** Comma delimited list of pool ids.  Match to any  **/
        poolIds?: string,
        /** Return count for specific status **/
        queryStatus?: 'open' | 'closed' | 'answered' | 'held' | 'pending_charge' | 'charged' | 'create' | 'csrhold' | 'cancelled' | 'pending_survey',
        /** Query Type **/
        type?: 'normal' | 'blinded' | 'communal' | 'private' | 'vtb' | 'emo',
        /** Media Type **/
        mediaType?: 'messenger' | 'audio' | 'video',
        /** Find records searching by text. **/
        text?: string,
        /** Find records searching by Tags **/
        tags?: string,
        /** Find records associated with a given User **/
        userId?: number,
        /** Flag to include user partial record in result list **/
        inclUserPartialRecord?: boolean,
        /** Meeting Start time for range selection **/
        meetingStart?: number,
        /** Meeting End time for range selection **/
        meetingEnd?: number,
        /** Start time for range of record creation **/
        createdStart?: number,
        /** End time for range of record creation **/
        createdEnd?: number,
        /** Start time for range of invitation **/
        inviteStart?: number,
        /** End time for range of invitation **/
        inviteEnd?: number,
        /** Find records associated with a given User Type **/
        userType?: number,
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
        /** Sort Options - Comma delimited list of key:order. order optional - default ASC  **/
        sort?: string,
    }): Promise<{
        data?: Array<QueryHeaderPartialList>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/query/header/list`,
            query: {
                'diagnosisId': diagnosisId,
                'grantIds': grantIds,
                'planId': planId,
                'poolIds': poolIds,
                'queryStatus': queryStatus,
                'type': type,
                'mediaType': mediaType,
                'text': text,
                'tags': tags,
                'userId': userId,
                'inclUserPartialRecord': inclUserPartialRecord,
                'meetingStart': meetingStart,
                'meetingEnd': meetingEnd,
                'createdStart': createdStart,
                'createdEnd': createdEnd,
                'inviteStart': inviteStart,
                'inviteEnd': inviteEnd,
                'userType': userType,
                'page': page,
                'pageSize': pageSize,
                'sort': sort,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * INTERNAL -- Source: DB -- not used by Front End
     * Returns one or more matching records. Sort options: id, created, date, meetingTime, inviteTime, relevance
     * @result any successful operation
     * @throws ApiError
     */
    public static async getQueryHeaderListPost({
        page = 1,
        pageSize = 10,
        sort,
        requestBody,
    }: {
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
        /** Sort Options - Comma delimited list of key:order. order optional - default ASC  **/
        sort?: string,
        /** Filters **/
        requestBody?: QueryFilters,
    }): Promise<{
        data?: Array<QueryHeaderPartialList>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'POST',
            path: `/server/query/header/list`,
            query: {
                'page': page,
                'pageSize': pageSize,
                'sort': sort,
            },
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find record counts grouped by Status.
     * Returns one or more records with count
     * @result any successful operation
     * @throws ApiError
     */
    public static async getQueryHeaderStatusCount({
        queryStatus,
        userId,
        ownerId,
    }: {
        /** Return count for specific status **/
        queryStatus?: 'open' | 'closed' | 'answered' | 'held' | 'pending_charge' | 'charged' | 'create' | 'csrhold' | 'cancelled' | 'pending_survey',
        /** Find records associated with a given User **/
        userId?: number,
        /** Find records owned / created by a given User **/
        ownerId?: number,
    }): Promise<{
        data?: Array<QueryHeaderStatusCountItem>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/query/status`,
            query: {
                'queryStatus': queryStatus,
                'userId': userId,
                'ownerId': ownerId,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find query header text by ID.
     * Returns matching record
     * @result QueryHeaderTextRecordItem successful operation
     * @throws ApiError
     */
    public static async getQueryHeaderTextById({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<QueryHeaderTextRecordItem> {
        const result = await __request({
            method: 'GET',
            path: `/server/query/text/${id}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update an existing query header text
     * Update a record based on data given
     * @result QueryHeaderTextRecordItem Successful operation
     * @throws ApiError
     */
    public static async updateQueryHeaderText({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** QueryHeader object that needs to be updated **/
        requestBody: QueryHeaderTextRecordItem,
    }): Promise<QueryHeaderTextRecordItem> {
        const result = await __request({
            method: 'PUT',
            path: `/server/query/text/${id}`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find multiple query texts.  Filterable by text, queryHeaderId
     * Returns one or more matching records
     * @result any successful operation
     * @throws ApiError
     */
    public static async getQueryHeaderTextList({
        queryHeaderId,
        text,
        page = 1,
        pageSize = 10,
    }: {
        /** Find records associated with a given Query Header **/
        queryHeaderId?: number,
        /** Find records searching by Text **/
        text?: string,
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
    }): Promise<{
        data?: Array<QueryHeaderTextRecordItem>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/query/text/list`,
            query: {
                'queryHeaderId': queryHeaderId,
                'text': text,
                'page': page,
                'pageSize': pageSize,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Source: Elastic. Find Queries. Main Query Index
     * Returns one or more matching records. Sort options: id, created, date, meetingTime, inviteTime, relevance
     * @result any successful operation
     * @throws ApiError
     */
    public static async getQueryIndexBuckets({
        queryId,
        userTagIds,
        teamTagIds,
        globalTagIds,
        diagnosisIds,
        diagnosisGroupId,
        diagnosisDomainId,
        grantId,
        planIds,
        poolId,
        status,
        type,
        mediaType,
        text,
        userId,
        ownerId,
        dateSearchStart,
        dateSearchEnd,
        meetingStart,
        meetingEnd,
        createdStart,
        createdEnd,
        userType,
        teamQueriesOnly = false,
        page = 1,
        pageSize = 10,
        sort,
    }: {
        /** Find records associated with a given Query  **/
        queryId?: number,
        /** Comma delimited list of User Scoped Tag ids. **/
        userTagIds?: string,
        /** Comma delimited list of Team Scoped Tag ids. **/
        teamTagIds?: string,
        /** Comma delimited list of Global Scoped Tag ids. **/
        globalTagIds?: string,
        /** Comma delimited list of diagnosis ids.  Match to any  **/
        diagnosisIds?: string,
        /** Find records associated with a given Diagnosis Group **/
        diagnosisGroupId?: number,
        /** Find records associated with a given Diagnosis Domain **/
        diagnosisDomainId?: number,
        /** Find records associated with a given Grant **/
        grantId?: number,
        /** Comma delimited list of plan ids.  Match to any  **/
        planIds?: string,
        /** Find records associated with a given Pool **/
        poolId?: number,
        /** Query Status **/
        status?: 'open' | 'closed' | 'answered' | 'held' | 'pending_charge' | 'charged' | 'create' | 'csrhold' | 'cancelled' | 'pending_survey',
        /** Query Type **/
        type?: 'normal' | 'blinded' | 'communal' | 'private',
        /** Media Type **/
        mediaType?: 'messenger' | 'audio' | 'video',
        /** Find records searching by Text. Use ID to get specific Query. **/
        text?: string,
        /** Find records associated with a given User **/
        userId?: number,
        /** Find records owned / created by a given User **/
        ownerId?: number,
        /** Date Search Start time for range selection **/
        dateSearchStart?: number,
        /** Date Search End time for range selection **/
        dateSearchEnd?: number,
        /** Meeting Start time for range selection **/
        meetingStart?: number,
        /** Meeting End time for range selection **/
        meetingEnd?: number,
        /** Start time for range of record creation **/
        createdStart?: number,
        /** End time for range of record creation **/
        createdEnd?: number,
        /** Find records associated with a given User Type **/
        userType?: number,
        /** Show only Queries for Teams user is part of **/
        teamQueriesOnly?: boolean,
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
        /** Sort Options - Comma delimited list of key:order. order optional - default ASC  **/
        sort?: string,
    }): Promise<{
        data?: queryBuckets,
        queryStats?: QueryAggregationItem,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/query/indexBuckets`,
            query: {
                'queryId': queryId,
                'userTagIds': userTagIds,
                'teamTagIds': teamTagIds,
                'globalTagIds': globalTagIds,
                'diagnosisIds': diagnosisIds,
                'diagnosisGroupId': diagnosisGroupId,
                'diagnosisDomainId': diagnosisDomainId,
                'grantId': grantId,
                'planIds': planIds,
                'poolId': poolId,
                'status': status,
                'type': type,
                'mediaType': mediaType,
                'text': text,
                'userId': userId,
                'ownerId': ownerId,
                'dateSearchStart': dateSearchStart,
                'dateSearchEnd': dateSearchEnd,
                'meetingStart': meetingStart,
                'meetingEnd': meetingEnd,
                'createdStart': createdStart,
                'createdEnd': createdEnd,
                'userType': userType,
                'teamQueriesOnly': teamQueriesOnly,
                'page': page,
                'pageSize': pageSize,
                'sort': sort,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Source: Elastic. Find Queries. Main Query Index
     * Returns one or more matching records. Sort options: id, created, date, meetingTime, inviteTime, relevance
     * @result any successful operation
     * @throws ApiError
     */
    public static async getQueryIndexBucketsPost({
        page = 1,
        pageSize = 10,
        sort,
        requestBody,
    }: {
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
        /** Sort Options - Comma delimited list of key:order. order optional - default ASC  **/
        sort?: string,
        /** Filters **/
        requestBody?: QueryFilters,
    }): Promise<{
        data?: queryBuckets,
        queryStats?: QueryAggregationItem,
    }> {
        const result = await __request({
            method: 'POST',
            path: `/server/query/indexBuckets`,
            query: {
                'page': page,
                'pageSize': pageSize,
                'sort': sort,
            },
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Source: Elastic. Find Queries. Main Query Index
     * Returns one or more matching records. Sort options: id, created, date, meetingTime, inviteTime, relevance
     * @result any successful operation
     * @throws ApiError
     */
    public static async getQueryIndex({
        queryId,
        userTagIds,
        teamTagIds,
        globalTagIds,
        diagnosisIds,
        diagnosisGroupId,
        diagnosisDomainId,
        grantIds,
        planIds,
        poolIds,
        status,
        type,
        mediaType,
        text,
        userId,
        ownerId,
        dateSearchStart,
        dateSearchEnd,
        meetingStart,
        meetingEnd,
        inviteStart,
        inviteEnd,
        createdStart,
        createdEnd,
        userType,
        teamQueriesOnly = false,
        page = 1,
        pageSize = 10,
        sort,
    }: {
        /** Find records associated with a given Query  **/
        queryId?: number,
        /** Comma delimited list of User Scoped Tag ids. **/
        userTagIds?: string,
        /** Comma delimited list of Team Scoped Tag ids. **/
        teamTagIds?: string,
        /** Comma delimited list of Global Scoped Tag ids. **/
        globalTagIds?: string,
        /** Comma delimited list of diagnosis ids.  Match to any  **/
        diagnosisIds?: string,
        /** Find records associated with a given Diagnosis Group **/
        diagnosisGroupId?: number,
        /** Find records associated with a given Diagnosis Domain **/
        diagnosisDomainId?: number,
        /** Comma delimited list of grant ids.  Match to any  **/
        grantIds?: string,
        /** Comma delimited list of plan ids.  Match to any  **/
        planIds?: string,
        /** Comma delimited list of pool ids.  Match to any  **/
        poolIds?: string,
        /** Query Status **/
        status?: 'open' | 'closed' | 'answered' | 'held' | 'pending_charge' | 'charged' | 'create' | 'csrhold' | 'cancelled' | 'pending_survey',
        /** Query Type **/
        type?: 'normal' | 'blinded' | 'communal' | 'private',
        /** Media Type **/
        mediaType?: 'messenger' | 'audio' | 'video',
        /** Find records searching by Text. Use ID to get specific Query. **/
        text?: string,
        /** Find records associated with a given User **/
        userId?: number,
        /** Find records owned / created by a given User **/
        ownerId?: number,
        /** Date Search Start time for range selection **/
        dateSearchStart?: number,
        /** Date Search End time for range selection **/
        dateSearchEnd?: number,
        /** Meeting Start time for range selection **/
        meetingStart?: number,
        /** Meeting End time for range selection **/
        meetingEnd?: number,
        /** Start time for range of invitation **/
        inviteStart?: number,
        /** End time for range of invitation **/
        inviteEnd?: number,
        /** Start time for range of record creation **/
        createdStart?: number,
        /** End time for range of record creation **/
        createdEnd?: number,
        /** Find records associated with a given User Type **/
        userType?: number,
        /** Show only Queries for Teams user is part of **/
        teamQueriesOnly?: boolean,
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
        /** Sort Options - Comma delimited list of key:order. order optional - default ASC  **/
        sort?: string,
    }): Promise<{
        data?: Array<QueryIndexFullDisplay>,
        meta?: meta,
        queryStats?: QueryAggregationItem,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/query/index`,
            query: {
                'queryId': queryId,
                'userTagIds': userTagIds,
                'teamTagIds': teamTagIds,
                'globalTagIds': globalTagIds,
                'diagnosisIds': diagnosisIds,
                'diagnosisGroupId': diagnosisGroupId,
                'diagnosisDomainId': diagnosisDomainId,
                'grantIds': grantIds,
                'planIds': planIds,
                'poolIds': poolIds,
                'status': status,
                'type': type,
                'mediaType': mediaType,
                'text': text,
                'userId': userId,
                'ownerId': ownerId,
                'dateSearchStart': dateSearchStart,
                'dateSearchEnd': dateSearchEnd,
                'meetingStart': meetingStart,
                'meetingEnd': meetingEnd,
                'inviteStart': inviteStart,
                'inviteEnd': inviteEnd,
                'createdStart': createdStart,
                'createdEnd': createdEnd,
                'userType': userType,
                'teamQueriesOnly': teamQueriesOnly,
                'page': page,
                'pageSize': pageSize,
                'sort': sort,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Source: Elastic. Find Queries. Main Query Index
     * Returns one or more matching records. Sort options: id, created, date, meetingTime, inviteTime, relevance
     * @result any successful operation
     * @throws ApiError
     */
    public static async getQueryIndexPost({
        page = 1,
        pageSize = 10,
        sort,
        requestBody,
    }: {
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
        /** Sort Options - Comma delimited list of key:order. order optional - default ASC  **/
        sort?: string,
        /** Filters **/
        requestBody?: QueryFilters,
    }): Promise<{
        data?: Array<QueryIndexFullDisplay>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'POST',
            path: `/server/query/index`,
            query: {
                'page': page,
                'pageSize': pageSize,
                'sort': sort,
            },
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Query Invite List
     * Returns one or more matching records
     * @result any successful operation
     * @throws ApiError
     */
    public static async getQueryInviteList({
        status,
        type,
        userId,
        name,
        email,
        queryHeaderId,
        invitedByUserId,
        phone,
        notifyStage,
        firstJoinedStart,
        firstJoinedEnd,
        lastJoinedStart,
        lastJoinedEnd,
        createdStart,
        createdEnd,
        userType,
        mute,
        page = 1,
        pageSize = 10,
        sort,
    }: {
        /** Query Invite Status **/
        status?: 'active' | 'inactive' | 'hasjoined',
        /** Query Invite Type **/
        type?: 'lovedone' | 'subject' | 'provider' | 'poa' | 'blinded_voice_up' | 'blinded_voice_down' | 'blinded_voice_none' | 'blinded_voice_mute',
        /** Find records associated with a given User **/
        userId?: number,
        /** Find records searching by Name.  Enter ID for specific record **/
        name?: string,
        /** Find records searching by Email.   **/
        email?: string,
        /** Find records associated with a given Query Header **/
        queryHeaderId?: number,
        /** User ID who sent the Invite **/
        invitedByUserId?: number,
        /** Phone Number **/
        phone?: string,
        /** Stage of the Invite **/
        notifyStage?: number,
        /** Start time for range selection **/
        firstJoinedStart?: number,
        /** End time for range selection **/
        firstJoinedEnd?: number,
        /** Start time for range selection **/
        lastJoinedStart?: number,
        /** End time for range selection **/
        lastJoinedEnd?: number,
        /** Start time for range of record creation **/
        createdStart?: number,
        /** End time for range of record creation **/
        createdEnd?: number,
        /** Find records associated with a given User Type **/
        userType?: number,
        /** Search by Mute Flag **/
        mute?: boolean,
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
        /** Sort Options - Comma delimited list of key:order. order optional - default ASC  **/
        sort?: string,
    }): Promise<{
        data?: Array<QueryInviteRecordItem>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/query/invite/list`,
            query: {
                'status': status,
                'type': type,
                'userId': userId,
                'name': name,
                'email': email,
                'queryHeaderId': queryHeaderId,
                'invitedByUserId': invitedByUserId,
                'phone': phone,
                'notifyStage': notifyStage,
                'firstJoinedStart': firstJoinedStart,
                'firstJoinedEnd': firstJoinedEnd,
                'lastJoinedStart': lastJoinedStart,
                'lastJoinedEnd': lastJoinedEnd,
                'createdStart': createdStart,
                'createdEnd': createdEnd,
                'userType': userType,
                'mute': mute,
                'page': page,
                'pageSize': pageSize,
                'sort': sort,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find multiple query responses.  Filterable by queryExpertId, userId, status
     * Returns one or more matching records
     * @result any successful operation
     * @throws ApiError
     */
    public static async getQueryResponseList({
        queryExpertId,
        userId,
        startTime,
        endTime,
        type,
        page = 1,
        pageSize = 10,
    }: {
        /** Find records associated with a given Query Expert **/
        queryExpertId?: number,
        /** Find records associated with a given User **/
        userId?: number,
        /** Start time for range selection **/
        startTime?: number,
        /** End time for range selection **/
        endTime?: number,
        /** Find records searching by type **/
        type?: 'response' | 'info' | 'csrinfo' | 'csrhold' | 'csrupdate' | 'csrupdateaccept' | 'csrupdatedecline' | 'pendedexpert' | 'rejectedexpert' | 'rejectedexpertresponse' | 'retractedexpert' | 'attachment' | 'attachment_removed' | 'attachment_inherited',
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
    }): Promise<{
        data?: Array<QueryResponseRecordItem>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/query/response/list`,
            query: {
                'queryExpertId': queryExpertId,
                'userId': userId,
                'startTime': startTime,
                'endTime': endTime,
                'type': type,
                'page': page,
                'pageSize': pageSize,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find query response text by ID.
     * Returns matching record
     * @result QueryResponseTextRecordItem successful operation
     * @throws ApiError
     */
    public static async getQueryResponseTextById({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<QueryResponseTextRecordItem> {
        const result = await __request({
            method: 'GET',
            path: `/server/query/response/text/${id}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update an existing query response text
     * Update a record based on data given
     * @result QueryResponseTextRecordItem Successful operation
     * @throws ApiError
     */
    public static async updateQueryResponseText({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** QueryResponse object that needs to be updated **/
        requestBody: QueryResponseTextRecordItem,
    }): Promise<QueryResponseTextRecordItem> {
        const result = await __request({
            method: 'PUT',
            path: `/server/query/response/text/${id}`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find multiple query texts.  Filterable by text, queryResponseId
     * Returns one or more matching records
     * @result any successful operation
     * @throws ApiError
     */
    public static async getQueryResponseTextList({
        queryResponseId,
        text,
        page = 1,
        pageSize = 10,
    }: {
        /** Find records associated with a given Query Response **/
        queryResponseId?: number,
        /** Find records searching by Text **/
        text?: string,
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
    }): Promise<{
        data?: Array<QueryResponseTextRecordItem>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/query/response/text/list`,
            query: {
                'queryResponseId': queryResponseId,
                'text': text,
                'page': page,
                'pageSize': pageSize,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * CSR VIEW - Source: Elastic. Find Queries.
     * Returns one or more matching records. Sort options: id, created, date, meetingTime, inviteTime, relevance
     * @result any successful operation
     * @throws ApiError
     */
    public static async getQueryList({
        queryId,
        userId,
        ownerId,
        diagnosisIds,
        diagnosisGroupId,
        diagnosisDomainId,
        grantIds,
        planIds,
        poolIds,
        status,
        type,
        mediaType,
        isCsrReviewOnly = false,
        inclTestQueries = true,
        tags,
        text,
        dateSearchStart,
        dateSearchEnd,
        meetingStart,
        meetingEnd,
        inviteStart,
        inviteEnd,
        createdStart,
        createdEnd,
        userType,
        teamQueriesOnly = false,
        page = 1,
        pageSize = 10,
        sort,
    }: {
        /** Find records associated with a given Query  **/
        queryId?: number,
        /** Find records associated with a given User **/
        userId?: number,
        /** Find records owned / created by a given User **/
        ownerId?: number,
        /** Comma delimited list of diagnosis ids.  Match to any  **/
        diagnosisIds?: string,
        /** Find records associated with a given Diagnosis Group **/
        diagnosisGroupId?: number,
        /** Find records associated with a given Diagnosis Domain **/
        diagnosisDomainId?: number,
        /** Comma delimited list of grant ids.  Match to any  **/
        grantIds?: string,
        /** Comma delimited list of plan ids.  Match to any  **/
        planIds?: string,
        /** Comma delimited list of pool ids.  Match to any  **/
        poolIds?: string,
        /** Query Status **/
        status?: 'open' | 'closed' | 'answered' | 'held' | 'pending_charge' | 'charged' | 'create' | 'csrhold' | 'cancelled' | 'pending_survey',
        /** Query Type **/
        type?: 'normal' | 'blinded' | 'communal' | 'private',
        /** Media Type **/
        mediaType?: 'messenger' | 'audio' | 'video',
        /** Is pending CSR quality review only **/
        isCsrReviewOnly?: boolean,
        /** Include test queries in list **/
        inclTestQueries?: boolean,
        /** Find records searching by Tags **/
        tags?: string,
        /** Find records searching by Text. Use ID to get specific Query. **/
        text?: string,
        /** Date Search Start time for range selection **/
        dateSearchStart?: number,
        /** Date Search End time for range selection **/
        dateSearchEnd?: number,
        /** Meeting Start time for range selection **/
        meetingStart?: number,
        /** Meeting End time for range selection **/
        meetingEnd?: number,
        /** Start time for range of invitation **/
        inviteStart?: number,
        /** End time for range of invitation **/
        inviteEnd?: number,
        /** Start time for range of record creation **/
        createdStart?: number,
        /** End time for range of record creation **/
        createdEnd?: number,
        /** Find records associated with a given User Type **/
        userType?: number,
        /** Show only Queries for Teams user is part of **/
        teamQueriesOnly?: boolean,
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
        /** Sort Options - Comma delimited list of key:order. order optional - default ASC  **/
        sort?: string,
    }): Promise<{
        data?: Array<QuerySearchItem>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/query/list`,
            query: {
                'queryId': queryId,
                'userId': userId,
                'ownerId': ownerId,
                'diagnosisIds': diagnosisIds,
                'diagnosisGroupId': diagnosisGroupId,
                'diagnosisDomainId': diagnosisDomainId,
                'grantIds': grantIds,
                'planIds': planIds,
                'poolIds': poolIds,
                'status': status,
                'type': type,
                'mediaType': mediaType,
                'isCSRReviewOnly': isCsrReviewOnly,
                'inclTestQueries': inclTestQueries,
                'tags': tags,
                'text': text,
                'dateSearchStart': dateSearchStart,
                'dateSearchEnd': dateSearchEnd,
                'meetingStart': meetingStart,
                'meetingEnd': meetingEnd,
                'inviteStart': inviteStart,
                'inviteEnd': inviteEnd,
                'createdStart': createdStart,
                'createdEnd': createdEnd,
                'userType': userType,
                'teamQueriesOnly': teamQueriesOnly,
                'page': page,
                'pageSize': pageSize,
                'sort': sort,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find query state transitions by ID.
     * Returns matching record
     * @result QueryStateTransitionRecordItem successful operation
     * @throws ApiError
     */
    public static async getQueryStateTransitionById({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<QueryStateTransitionRecordItem> {
        const result = await __request({
            method: 'GET',
            path: `/server/query/log/state-transitions/${id}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find multiple query state transition logs.  Filterable by queryHeaderId, oldState, newState, oldFilterState, newFilterState, notes
     * Returns one or more matching records
     * @result any successful operation
     * @throws ApiError
     */
    public static async getQueryStateTransitionList({
        queryHeaderId,
        oldState,
        newState,
        notes,
        oldFilterState,
        newFilterState,
        page = 1,
        pageSize = 10,
    }: {
        /** Find records associated with a given Query Header **/
        queryHeaderId?: number,
        /** Find records searching by oldState **/
        oldState?: string,
        /** Find records searching by newState **/
        newState?: string,
        /** Find records searching by Notes **/
        notes?: string,
        /** Find records searching by oldFilterState **/
        oldFilterState?: 'no_filters' | 'applied' | 'removed',
        /** Find records searching by newFilterState **/
        newFilterState?: 'no_filters' | 'applied' | 'removed',
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
    }): Promise<{
        data?: Array<QueryStateTransitionRecordItem>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/query/log/state-transitions/list`,
            query: {
                'queryHeaderId': queryHeaderId,
                'oldState': oldState,
                'newState': newState,
                'notes': notes,
                'oldFilterState': oldFilterState,
                'newFilterState': newFilterState,
                'page': page,
                'pageSize': pageSize,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * CSR Stats for queries
     * Returns stats array
     * @result any successful operation
     * @throws ApiError
     */
    public static async getQueryStats({
        text,
        diagnosisId,
        grantId,
        planId,
        poolId,
        queryStatus,
        createdStart,
        createdEnd,
        inviteStart,
        inviteEnd,
        userId,
        page = 1,
        pageSize = 10,
        sort,
    }: {
        /** Find records searching by text. **/
        text?: string,
        /** Find records associated with a given Diagnosis  **/
        diagnosisId?: number,
        /** Find records associated with a given Grant **/
        grantId?: number,
        /** Find records associated with a given Plan **/
        planId?: number,
        /** Find records associated with a given Pool **/
        poolId?: number,
        /** Return count for specific status **/
        queryStatus?: 'open' | 'closed' | 'answered' | 'held' | 'pending_charge' | 'charged' | 'create' | 'csrhold' | 'cancelled' | 'pending_survey',
        /** Start time for range of record creation **/
        createdStart?: number,
        /** End time for range of record creation **/
        createdEnd?: number,
        /** Start time for range of invitation **/
        inviteStart?: number,
        /** End time for range of invitation **/
        inviteEnd?: number,
        /** Find records associated with a given User **/
        userId?: number,
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
        /** Sort Options - Comma delimited list of key:order. order optional - default ASC  **/
        sort?: string,
    }): Promise<{
        data?: Array<QueryCSRStatsRecordItem>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/query/stats`,
            query: {
                'text': text,
                'diagnosisId': diagnosisId,
                'grantId': grantId,
                'planId': planId,
                'poolId': poolId,
                'queryStatus': queryStatus,
                'createdStart': createdStart,
                'createdEnd': createdEnd,
                'inviteStart': inviteStart,
                'inviteEnd': inviteEnd,
                'userId': userId,
                'page': page,
                'pageSize': pageSize,
                'sort': sort,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Source: Elastic. Find query stats for the given user
     * Note this is different from the other Stats API.
     * @result any successful operation
     * @throws ApiError
     */
    public static async getQueryStatsUser({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<{
        queryStats?: QueryAggregationItem,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/query/stats/user/${id}`,
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Create a new query header expert
     * Creates a record based on data given
     * @result QueryExpertRecordItem Successful operation
     * @throws ApiError
     */
    public static async createQueryExpert({
        requestBody,
    }: {
        /** Query expert object to be created **/
        requestBody: QueryExpertRecordItem,
    }): Promise<QueryExpertRecordItem> {
        const result = await __request({
            method: 'POST',
            path: `/server/query/expert`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Create a new query header
     * Creates a record based on data given
     * @result QueryHeaderRecordItem Successful operation
     * @throws ApiError
     */
    public static async createQueryHeader({
        requestBody,
    }: {
        /** Query Header object to be created **/
        requestBody: QueryHeaderRecordItem,
    }): Promise<QueryHeaderRecordItem> {
        const result = await __request({
            method: 'POST',
            path: `/server/query`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Query Drafts.  Create a new query header input
     * Query Drafts.  Creates a record based on data given
     * @result QueryHeaderInputItem Successful operation
     * @throws ApiError
     */
    public static async createQueryHeaderInput({
        requestBody,
    }: {
        /** Query Header Input object to be created **/
        requestBody: QueryHeaderInputItem,
    }): Promise<QueryHeaderInputItem> {
        const result = await __request({
            method: 'POST',
            path: `/server/query/headerInput`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Create a new query header text
     * Creates a record based on data given
     * @result QueryHeaderTextRecordItem Successful operation
     * @throws ApiError
     */
    public static async createQueryHeaderText({
        requestBody,
    }: {
        /** Query Header text object to be created **/
        requestBody: QueryHeaderTextRecordItem,
    }): Promise<QueryHeaderTextRecordItem> {
        const result = await __request({
            method: 'POST',
            path: `/server/query/text`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Create a new query header invite
     * Creates a record based on data given
     * @result QueryInviteRecordItem Successful operation
     * @throws ApiError
     */
    public static async createQueryInvite({
        requestBody,
    }: {
        /** Query invite object to be created **/
        requestBody: QueryInviteRecordItem,
    }): Promise<QueryInviteRecordItem> {
        const result = await __request({
            method: 'POST',
            path: `/server/query/invite`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Create a new query  response
     * Creates a record based on data given
     * @result QueryResponseRecordItem Successful operation
     * @throws ApiError
     */
    public static async createQueryResponse({
        requestBody,
    }: {
        /** Query response object to be created **/
        requestBody: QueryResponseRecordItem,
    }): Promise<QueryResponseRecordItem> {
        const result = await __request({
            method: 'POST',
            path: `/server/query/response`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Create a new query response text
     * Creates a record based on data given
     * @result QueryResponseTextRecordItem Successful operation
     * @throws ApiError
     */
    public static async createQueryResponseText({
        requestBody,
    }: {
        /** Query Header text object to be created **/
        requestBody: QueryResponseTextRecordItem,
    }): Promise<QueryResponseTextRecordItem> {
        const result = await __request({
            method: 'POST',
            path: `/server/query/response/text`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

}