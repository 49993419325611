import { UserType } from "@/config";

export default [
  {
    path: "/discussions",
    name: "Discussions",
    component: () => import(/* webpackChunkName: "discussions" */ "@/views/Discussion/DiscussionIndex.vue"),
    meta: {
      disallowedUsers: [UserType.CLIENT, UserType.CSR],
      appClass: "appModeUser",
      title: "My Discussions"
    }
  },
  {
    path: "/discussion/:id",
    name: "Discussion",
    component: () => import(/* webpackChunkName: "discussion" */ "@/views/Discussion/Discussion.vue"),
    meta: { appBar: "user", appClass: "appModeUser", disallowedUsers: [UserType.CLIENT] },
    props: true
  },
  {
    path: "/invitation/:id",
    name: "Invitation",
    component: () => import(/* webpackChunkName: "discussion" */ "@/views/Discussion/Invitation.vue"),
    meta: { appBar: "user", appClass: "appModeUser", disallowedUsers: [UserType.CLIENT] },
    props: true
  }
];
