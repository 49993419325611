import { UserType } from "@/config";

export default [
  // Contigo members
  {
    path: "/member/files",
    name: "MemberFiles",
    component: () => import(/* webpackChunkName: "members" */ "@/views/Members/MemberFileUpload.vue"),
    meta: {
      appClass: "appModeUserRightNoFooter",
      appBar: "user",
      allowedUsers: [UserType.CLIENT]
    }
  },
  {
    path: "/member/lobby",
    name: "MemberLobby",
    component: () => import(/* webpackChunkName: "members" */ "@/views/Members/MemberLobby.vue"),
    meta: {
      appClass: "appModeUserRightNoFooter",
      appBar: "user",
      allowedUsers: [UserType.CLIENT]
    }
  }
];
