<template>
  <action :action="{ action: 'wait', clientData: {} }" :showText="false" v-on="$listeners" showTyping>
    <template v-slot:default>
      <span />
    </template>
  </action>
</template>

<script lang="ts">
import Vue from "vue";
import Action from "./Action.vue";

export default Vue.extend({
  name: "TypingIndicator",
  components: {
    Action
  }
});
</script>
