import { TermsStoreInterface, TermsParameterType } from "./types";
import { Module, StateFor } from "vuex-typescript-interface";
import { RootStoreInterface } from "../types";
import { Languages } from "@/config";
import Vue from "vue";

const userTypesMap = [
  "invited", // 0
  "client", // 1
  "network", // 2 (legacy expert)
  "network", // 3 CSR
  null, // 4 deprecated
  "network", // 5 network
  null, // 6 Deprecated
  null, // 7 Deprecated
  null, // 8 Deprecated
  null, // 9 Deprecated
  null, // 10 Deprecated
  "planmanager", // 11
  "blinded" // 12 Blinded
];

const terms: Module<TermsStoreInterface, RootStoreInterface> = {
  state: {
    document: null,
    loading: false
  },

  getters: {
    getCurrentDocument: state => state.document,
    getContent: state => state.document?.data?.content,
    getUpdated: state => state.document?.last_publication_date,
    getLanguage: state => state.document?.lang,
    getId: state => state.document?.id,
    isLoading: state => state.loading,
    termsParameter: (state: StateFor<TermsStoreInterface>): TermsParameterType => {
      if (state.document && state.document.id) {
        return {
          cmsId: state.document.id as string,
          language: state.document.lang as string,
          updated: state.document.last_publication_date as string
        };
      } else {
        return undefined;
      }
    }
  },

  mutations: {
    setDocument(state: StateFor<TermsStoreInterface>, doc: Record<string, any> | null): void {
      state.document = doc;
    },
    setLoading(state: StateFor<TermsStoreInterface>, b: boolean): void {
      state.loading = b;
    }
  },

  actions: {
    async loadTerms(
      { commit, rootState },
      payload: {
        blinded: boolean;
        expert: boolean;
        medium: "messenger" | "audio" | "video";
        respond: boolean;
      }
    ): Promise<Record<string, any> | null> {
      try {
        commit("setLoading", true);
        const currentUserType = rootState.userPartial?.userType || 0;
        const currentLocale = Languages[rootState.lang]?.locale ?? "en-us";
        if (!(userTypesMap[currentUserType] ?? null)) {
          commit("setDocument", null);
          commit("setLoading", false);
          return null;
        }
        const prismic = Vue.prototype.$prismic;
        const query = [
          prismic.Predicates.at("document.type", "query-terms"),
          prismic.Predicates.at("my.query-terms.user-types.user-type", userTypesMap[currentUserType]),
          prismic.Predicates.at(`my.query-terms.${payload.medium}`, true),
          prismic.Predicates.at("my.query-terms.response", payload.respond),
          prismic.Predicates.at("my.query-terms.expert", payload.expert),
          prismic.Predicates.at("my.query-terms.blinded", payload.blinded)
        ];
        let resp = await prismic.client.query(query, {
          lang: currentLocale,
          orderings: "[document.last_publication_date desc]"
        });
        if ((!resp || !resp.results.length) && currentLocale != "en-us") {
          resp = await prismic.client.query(query, {
            lang: "en-us",
            orderings: "[document.last_publication_date desc]"
          });
        }
        const doc = resp?.results?.length ? resp.results[0] : null;
        // console.log(doc);
        commit("setDocument", doc);
        commit("setLoading", false);
        return doc;
      } catch (e) {
        console.log({ e });
        commit("setDocument", null);
        commit("setLoading", false);
        return null;
      }
    }
  }
};

export default terms;
