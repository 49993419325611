/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Tags Target Count
 */
export type TagsTargetCountRecordItem = {
    /**
     * tag Id
     */
    tagId?: number;
    /**
     * Target Type
     */
    targetType?: TagsTargetCountRecordItem.targetType;
    /**
     * Count of tags for given target type
     */
    count?: number;
    /**
     * API Response Version
     */
    _ver?: number;
}

export namespace TagsTargetCountRecordItem {

    /**
     * Target Type
     */
    export enum targetType {
        USER = 'user',
        PLAN = 'plan',
        POOL = 'pool',
        GRANT = 'grant',
        QUERY = 'query',
        OTHER = 'other',
    }


}
