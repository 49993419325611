/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DiagnosisDomainItem } from '../models/DiagnosisDomainItem';
import type { DiagnosisDomainRecordItem } from '../models/DiagnosisDomainRecordItem';
import type { DiagnosisGroupItem } from '../models/DiagnosisGroupItem';
import type { DiagnosisGroupRecordItem } from '../models/DiagnosisGroupRecordItem';
import type { DiagnosisRecordItem } from '../models/DiagnosisRecordItem';
import type { DiagnosisStatsItem } from '../models/DiagnosisStatsItem';
import type { meta } from '../models/meta';
import type { QueryFilters } from '../models/QueryFilters';
import { request as __request } from '../core/request';

export class DiagnosisService {

    /**
     * Search Diagnosis Tree by name, limited to user. Honors CSR status
     * Returns a list if matching diagnosis domains and children, with document counts
     * @result any successful operation
     * @throws ApiError
     */
    public static async searchDiagnosisTreeForQueries({
        name,
        exclude = 'none',
        excludeId,
    }: {
        /** Find records searching by Name.  Enter ID for specific record **/
        name?: string,
        /** Choose to exclude diagnosis already linked to a 'plan', 'pool', or 'grant' **/
        exclude?: 'none' | 'plan' | 'pool' | 'grant',
        /** ID of item to exclude **/
        excludeId?: number,
    }): Promise<{
        /**
         * A different iteration of diagnosis tree search -
         * This one limits the diagnoses to those used by queries accessible to the user.
         * This does not take into account future 'public' queries, and a context
         * option could be added here to control that.
         *
         * Though this shares underlying repository calls with the server version, a separate endpoint is used to control
         * user experience and which options are accessible. So this applies fromSearch always
         * TODO Should this be in a separate tag?
         */
        data?: Array<DiagnosisDomainItem>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/user/diagnosis/search/fromQueries`,
            query: {
                'name': name,
                'exclude': exclude,
                'excludeId': excludeId,
            },
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Search Diagnosis Tree by name, limited to user. Honors CSR status
     * Returns a list if matching diagnosis domains and children, with document counts
     * @result any successful operation
     * @throws ApiError
     */
    public static async searchDxTreeForQueriesPost({
        name,
        exclude = 'none',
        excludeId,
        requestBody,
    }: {
        /** Find records searching by Name.  Enter ID for specific record **/
        name?: string,
        /** Choose to exclude diagnosis already linked to a 'plan', 'pool', or 'grant' **/
        exclude?: 'none' | 'plan' | 'pool' | 'grant',
        /** ID of item to exclude **/
        excludeId?: number,
        /** Filters **/
        requestBody?: QueryFilters,
    }): Promise<{
        data?: Array<DiagnosisDomainItem>,
    }> {
        const result = await __request({
            method: 'POST',
            path: `/user/diagnosis/search/fromQueries`,
            query: {
                'name': name,
                'exclude': exclude,
                'excludeId': excludeId,
            },
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find diagnosis by ID
     * Returns a single diagnosis
     * @result DiagnosisRecordItem successful operation
     * @throws ApiError
     */
    public static async getDiagnosisById({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<DiagnosisRecordItem> {
        const result = await __request({
            method: 'GET',
            path: `/server/diagnosis/${id}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update an existing Diagnosis
     * Update a record based on data given
     * @result DiagnosisRecordItem successful operation
     * @throws ApiError
     */
    public static async updateDiagnosis({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** Diagnosis object to be updated **/
        requestBody: DiagnosisRecordItem,
    }): Promise<DiagnosisRecordItem> {
        const result = await __request({
            method: 'PUT',
            path: `/server/diagnosis/${id}`,
            body: requestBody,
            errors: {
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find diagnosis domain by ID
     * Returns a single diagnosis domain
     * @result DiagnosisDomainItem successful operation
     * @throws ApiError
     */
    public static async getDiagnosisDomainById({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<DiagnosisDomainItem> {
        const result = await __request({
            method: 'GET',
            path: `/server/diagnosis/domain/${id}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update an existing Diagnosis Domain
     * Update a record based on data given
     * @result DiagnosisDomainRecordItem successful operation
     * @throws ApiError
     */
    public static async updateDiagnosisDomain({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** Diagnosis object to be updated **/
        requestBody: DiagnosisDomainRecordItem,
    }): Promise<DiagnosisDomainRecordItem> {
        const result = await __request({
            method: 'PUT',
            path: `/server/diagnosis/domain/${id}`,
            body: requestBody,
            errors: {
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find multiple diagnosis domains.  Filterable by name, grantId, planId.
     * Returns one or more matching diagnosis domains
     * @result any successful operation
     * @throws ApiError
     */
    public static async getDiagnosisDomainList({
        name,
        grantId,
        planId,
        inclHidden = false,
        excludeGrantId,
        excludePlanId,
        page = 1,
        pageSize = 10,
        sort,
    }: {
        /** Find records searching by Name.  Enter ID for specific record **/
        name?: string,
        /** Find records associated with a given Grant **/
        grantId?: number,
        /** Find records associated with a given Plan **/
        planId?: number,
        /** Flag to include hidden domains in result list **/
        inclHidden?: boolean,
        /** Exclude records associated with a given Grant **/
        excludeGrantId?: number,
        /** Exclude records associated with a given Plan **/
        excludePlanId?: number,
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
        /** Sort Options - Comma delimited list of key:order. order optional - default ASC  **/
        sort?: string,
    }): Promise<{
        data?: Array<DiagnosisDomainRecordItem>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/diagnosis/domain/list`,
            query: {
                'name': name,
                'grantId': grantId,
                'planId': planId,
                'inclHidden': inclHidden,
                'excludeGrantId': excludeGrantId,
                'excludePlanId': excludePlanId,
                'page': page,
                'pageSize': pageSize,
                'sort': sort,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * CSR Stats for Diagnosis
     * Returns stats array
     * @result any successful operation
     * @throws ApiError
     */
    public static async getDiagnosisStats({
        diagnosisDomainId,
        diagnosisGroupId,
        diagnosisId,
        page = 1,
        pageSize = 10,
        sort,
    }: {
        /** Find records associated with a given Diagnosis Domain **/
        diagnosisDomainId?: number,
        /** Find records associated with a given Diagnosis Group **/
        diagnosisGroupId?: number,
        /** Find records associated with a given Diagnosis  **/
        diagnosisId?: number,
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
        /** Sort Options - Comma delimited list of key:order. order optional - default ASC  **/
        sort?: string,
    }): Promise<{
        data?: Array<DiagnosisStatsItem>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/diagnosis/stats`,
            query: {
                'diagnosisDomainId': diagnosisDomainId,
                'diagnosisGroupId': diagnosisGroupId,
                'diagnosisId': diagnosisId,
                'page': page,
                'pageSize': pageSize,
                'sort': sort,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find diagnosis group by ID
     * Returns a single diagnosis group
     * @result DiagnosisGroupItem successful operation
     * @throws ApiError
     */
    public static async getDiagnosisGroupById({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<DiagnosisGroupItem> {
        const result = await __request({
            method: 'GET',
            path: `/server/diagnosis/group/${id}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update an existing Diagnosis Group
     * Update a record based on data given
     * @result DiagnosisGroupRecordItem successful operation
     * @throws ApiError
     */
    public static async updateDiagnosisGroup({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** Diagnosis Group object to be updated **/
        requestBody: DiagnosisGroupRecordItem,
    }): Promise<DiagnosisGroupRecordItem> {
        const result = await __request({
            method: 'PUT',
            path: `/server/diagnosis/group/${id}`,
            body: requestBody,
            errors: {
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find multiple diagnosis groups.  Filterable by name, Diagnosis Domain Id.  Only one allowed.
     * Returns one or more matching diagnosis groups
     * @result any successful operation
     * @throws ApiError
     */
    public static async getDiagnosisGroupList({
        name,
        diagnosisDomainId,
        excludeDiagnosisDomainId,
        page = 1,
        pageSize = 10,
        sort,
    }: {
        /** Find records searching by Name.  Enter ID for specific record **/
        name?: string,
        /** Find records associated with a given Diagnosis Domain **/
        diagnosisDomainId?: number,
        /** Exclude records associated with a given Diagnosis Domain **/
        excludeDiagnosisDomainId?: number,
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
        /** Sort Options - Comma delimited list of key:order. order optional - default ASC  **/
        sort?: string,
    }): Promise<{
        data?: Array<DiagnosisGroupItem>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/diagnosis/group/list`,
            query: {
                'name': name,
                'diagnosisDomainId': diagnosisDomainId,
                'excludeDiagnosisDomainId': excludeDiagnosisDomainId,
                'page': page,
                'pageSize': pageSize,
                'sort': sort,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Find multiple diagnosis.  Filterable by name, DomainId, GrantId, PlanId, PoolId, or UserId.  Only one allowed.
     * Returns one or more matching records
     * @result any successful operation
     * @throws ApiError
     */
    public static async getDiagnosisList({
        name,
        diagnosisGroupId,
        grantId,
        planId,
        poolId,
        userId,
        excludeGrantId,
        excludePlanId,
        excludePoolId,
        excludeDiagnosisGroupId,
        excludeUserId,
        page = 1,
        pageSize = 10,
        sort,
    }: {
        /** Find records searching by Name.  Enter ID for specific record **/
        name?: string,
        /** Find records associated with a given Diagnosis Group **/
        diagnosisGroupId?: number,
        /** Find records associated with a given Grant **/
        grantId?: number,
        /** Find records associated with a given Plan **/
        planId?: number,
        /** Find records associated with a given Pool **/
        poolId?: number,
        /** Find records associated with a given User **/
        userId?: number,
        /** Exclude records associated with a given Grant **/
        excludeGrantId?: number,
        /** Exclude records associated with a given Plan **/
        excludePlanId?: number,
        /** Exclude records associated with a given Pool **/
        excludePoolId?: number,
        /** Exclude records associated with a given Diagnosis Group **/
        excludeDiagnosisGroupId?: number,
        /** Exclude records associated with a given User **/
        excludeUserId?: number,
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
        /** Sort Options - Comma delimited list of key:order. order optional - default ASC  **/
        sort?: string,
    }): Promise<{
        data?: Array<DiagnosisRecordItem>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/diagnosis/list`,
            query: {
                'name': name,
                'diagnosisGroupId': diagnosisGroupId,
                'grantId': grantId,
                'planId': planId,
                'poolId': poolId,
                'userId': userId,
                'excludeGrantId': excludeGrantId,
                'excludePlanId': excludePlanId,
                'excludePoolId': excludePoolId,
                'excludeDiagnosisGroupId': excludeDiagnosisGroupId,
                'excludeUserId': excludeUserId,
                'page': page,
                'pageSize': pageSize,
                'sort': sort,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Search Diagnosis Tree by name
     * Returns a list if matching diagnosis domains and children
     * @result any successful operation
     * @throws ApiError
     */
    public static async searchDiagnnosisTree({
        name,
        exclude,
        excludeId,
    }: {
        /** Find records searching by Name.  Enter ID for specific record **/
        name?: string,
        /** Choose to exclude diagnosis already linked to a 'plan', 'pool', or 'grant' **/
        exclude?: string,
        /** ID of item to exclude **/
        excludeId?: number,
    }): Promise<{
        data?: Array<DiagnosisDomainItem>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/diagnosis/domain/search`,
            query: {
                'name': name,
                'exclude': exclude,
                'excludeId': excludeId,
            },
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Create a Diagnosis
     * Creates a record based on data given
     * @result DiagnosisRecordItem successful operation
     * @throws ApiError
     */
    public static async createDiagnosis({
        requestBody,
    }: {
        /** Diagnosis object to be created **/
        requestBody: DiagnosisRecordItem,
    }): Promise<DiagnosisRecordItem> {
        const result = await __request({
            method: 'POST',
            path: `/server/diagnosis`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Create a new  Diagnosis Domain
     * Creates a record based on data given
     * @result DiagnosisDomainRecordItem successful operation
     * @throws ApiError
     */
    public static async createDiagnosisDomain({
        requestBody,
    }: {
        /** Diagnosis Domain object to be created **/
        requestBody: DiagnosisDomainRecordItem,
    }): Promise<DiagnosisDomainRecordItem> {
        const result = await __request({
            method: 'POST',
            path: `/server/diagnosis/domain`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Create a Diagnosis Group
     * Creates a record based on data given
     * @result DiagnosisGroupRecordItem successful operation
     * @throws ApiError
     */
    public static async createDiagnosisGroup({
        requestBody,
    }: {
        /** Diagnosis Group object to be created **/
        requestBody: DiagnosisGroupRecordItem,
    }): Promise<DiagnosisGroupRecordItem> {
        const result = await __request({
            method: 'POST',
            path: `/server/diagnosis/group`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

}