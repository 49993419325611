import Vue from "vue";
import InfiniteLoading from "vue-infinite-loading";
import NoMoreData from "@/components/util/infinite-loading/NoMoreData.vue";
import NoData from "@/components/util/infinite-loading/NoData.vue";

Vue.use(InfiniteLoading, {
  slots: {
    noMore: NoMoreData,
    noResults: NoData
  }
});
