<template>
  <action :action="action" showText v-on="$listeners">
    <template v-slot:default="{ show }">
      <div v-if="show">
        <v-expand-transition>
          <v-text-field
            ref="field"
            v-model="text"
            filled
            :label="action.label"
            :hint="action.hint"
            persistent-hint
            autofocus
            placeholder=" "
            persistent-placeholder
            xsingle-line
            v-if="!event"
            @keydown.enter="enter"
          />
        </v-expand-transition>
      </div>
    </template>
  </action>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from "vue";
import Action from "./Action.vue";
import { VTextField } from "vuetify/lib";
import { ChatBotAction, ChatBotEvent } from "@/service";

export default (Vue as VueConstructor<
  Vue & {
    $refs: {
      field: typeof VTextField;
    };
  }
>).extend({
  name: "TextActionComponent",
  components: {
    Action
  },
  props: {
    action: {
      type: Object as PropType<ChatBotAction>,
      required: true
    }
  },
  data: () => ({
    event: undefined as ChatBotEvent | undefined,
    text: ""
  }),
  computed: {
    minCount(): number {
      return this.action?.minimum ?? 1;
    }
  },
  methods: {
    enter(): void {
      if (this.text.trim().length >= this.minCount) {
        this.event = {
          type: "respond",
          ts: Date.now(),
          // action: this.action,
          clientData: this.action.clientData,
          responses: [this.text]
        };
        this.$emit("callback", this.event);
      }
    }
  }
});
</script>
