/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Query Notify Item
 */
export type QueryNotifyRecordItem = {
    /**
     * API Response Version
     */
    _ver?: number;
    /**
     * Record Unique ID
     */
    id?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    created?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    updated?: number;
    /**
     * Timestamp - if sent is blank returns created
     */
    sent?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    deferUntil?: number;
    /**
     * Record Unique ID
     */
    queryExpertId?: number;
    /**
     * Record Unique ID
     */
    queryHeaderId?: number;
    /**
     * Error Message
     */
    error?: string;
    /**
     * Tyoe
     */
    type?: QueryNotifyRecordItem.type;
    /**
     * Status
     */
    status?: QueryNotifyRecordItem.status;
}

export namespace QueryNotifyRecordItem {

    /**
     * Tyoe
     */
    export enum type {
        EMAIL = 'email',
        SMS = 'sms',
        APP = 'app',
    }

    /**
     * Status
     */
    export enum status {
        NEW = 'new',
        SENT = 'sent',
        VERIFIED = 'verified',
        ERROR = 'error',
    }


}
