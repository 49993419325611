import { render, staticRenderFns } from "./OAuthLoginCard.vue?vue&type=template&id=b4f1a7b2"
import script from "./OAuthLoginCard.vue?vue&type=script&lang=ts"
export * from "./OAuthLoginCard.vue?vue&type=script&lang=ts"
import style0 from "./OAuthLoginCard.vue?vue&type=style&index=0&id=b4f1a7b2&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports