/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Tags
 */
export type TagsRecordItem = {
    /**
     * Record Unique ID
     */
    id?: number;
    /**
     * Created by UserId
     */
    userId?: number;
    /**
     * Tag Definition Id
     */
    definitionId?: number;
    /**
     * Flag for record being active
     */
    active?: boolean;
    /**
     * Current user allowed to delete?  Helper field.  Confirmation logic in backend.
     */
    userCanDelete?: boolean;
    /**
     * Tags name
     */
    name?: string;
    /**
     * Tags Description
     */
    desc?: string;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    created?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    updated?: number;
    /**
     * Scope of the tag
     */
    scope?: TagsRecordItem.scope;
    /**
     * API Response Version
     */
    _ver?: number;
}

export namespace TagsRecordItem {

    /**
     * Scope of the tag
     */
    export enum scope {
        USER = 'user',
        TEAM = 'team',
        GLOBAL = 'global',
        CEN = 'cen',
    }


}
