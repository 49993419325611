import _Vue from "vue";

export interface HelpDialogOptions {
  docType?: string;
  docId?: string;
  dismissText?: string;
  dismissColor?: string;
  dismissTextFlat?: string;
  color?: string;
  icon?: string;
  persistent?: boolean;
  title?: string;
  width?: number;
}

// Pluigin to make a help method available globally

export function EventBusPlugin(Vue: typeof _Vue): void {
  function help(helpKey: string): void {
    Vue.prototype.$eventBus.$emit("help", helpKey);
  }

  // Install an event bus object on the prototype for global use
  Vue.prototype.$eventBus = new _Vue();

  // do stuff with options
  Vue.prototype.$help = help;

  // For debugging only...
  const old_on = Vue.prototype.$eventBus.$on;
  Vue.prototype.$eventBus.$on = (...args: any): void => {
    // console.log("EventBus:", args);
    // custom logic here like pushing to a callback array or something
    old_on.apply(Vue.prototype.$eventBus, args);
  };
}

_Vue.use(EventBusPlugin);
