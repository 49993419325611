<template>
  <v-dialog eager v-model="visible" scrollable :max-width="width" :persistent="persistent" @keydown.esc="dismiss()">
    <v-card tile class="help-window">
      <v-toolbar v-if="Boolean(title)" dark :color="color" dense flat>
        <v-icon v-if="Boolean(icon)" left>{{ icon }}</v-icon>
        <v-toolbar-title class="white--text" v-text="displayTitle" />
        <v-spacer />
        <v-btn id="btnCloseHelp" class="mr-n2" size="36" icon @click="dismiss()">
          <v-icon>$close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="body-1 text-body-1 py-3" style="position:relative;">
        <cms-rich-text docType="help-page" :docId="helpKey" @loaded="onLoad" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn v-if="Boolean(dismissText)" :color="dismissColor" :text="dismissTextFlat" @click="dismiss()">
          {{ dismissText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
/* 
  This is a dynamic help dialog which will load copy from the server based on a help key.
*/
import Vue from "vue";
import { Route } from "vue-router";
import CmsRichText from "./CmsRichText.vue";

export default Vue.extend({
  components: {
    CmsRichText
  },
  props: {
    dismissText: {
      type: String,
      default: "OK"
    },
    dismissColor: {
      type: String,
      default: "primary"
    },
    dismissTextFlat: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: "primary"
    },
    icon: {
      type: String,
      default() {
        return "$help";
      }
    },
    helpKey: {
      type: String,
      required: true
    },
    persistent: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "Help"
    },
    width: {
      type: Number,
      default: 600
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      document: null as any,
      docTitle: ""
    };
  },
  computed: {
    visible: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit("input", value);
      }
    },
    displayTitle(): string {
      return this.docTitle.trim() == "" ? this.title : this.docTitle;
      // if (this.document) {
      //   const t = this.$prismic.richTextAsPlain(this.document.data.title);
      //   return !t || t.trim() == "" ? this.title : t;
      // } else {
      //   return this.title;
      // }
    }
  },
  methods: {
    onLoad(found: boolean, document: any) {
        this.docTitle = found ? this.$prismic.richTextAsPlain(document.data.title) : "";
    },
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    onEnterPressed(e: any) {
      if (e.keyCode === 13) {
        e.stopPropagation();
        this.dismiss();
      }
    },
    dismiss() {
      // this.$emit("dismiss");
      this.visible = false;
    }
  },
  watch: {
    $route(val: Route) {
      if (val && val.name == 'Login') this.dismiss();
    }
  },
  mounted() {
    document.addEventListener("keyup", this.onEnterPressed);
  },
  destroyed() {
    document.removeEventListener("keyup", this.onEnterPressed);
  }
});
</script>

<style lang="scss">
.help-window {
  p.block-img {
    img {
      max-width: 100%;
      margin: auto 0;
    }
  }
}
</style>
