/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UserSpecialtyRecordItem } from './UserSpecialtyRecordItem';

/**
 * Query Participant
 */
export type QueryParticipantListItem = {
    /**
     * User Unique ID
     */
    userId?: number;
    /**
     * Active Flag
     */
    active?: boolean;
    /**
     * Always present display name
     */
    resolvedDisplayName?: string;
    /**
     * User initials for Avatar
     */
    initials?: string;
    /**
     * User Profile Img Thumbnail
     */
    avatar?: string;
    /**
     * User Type ID
     */
    userType?: number;
    /**
     * User Type Display
     */
    typeDisplay?: string;
    /**
     * User Type Display
     */
    email?: string;
    /**
     * API Response Version
     */
    _ver?: number;
    /**
     * Expert Unique ID
     */
    id?: number;
    /**
     * Pseudonum if applicable
     */
    pseudonym?: string;
    /**
     * Flag for is Expert
     */
    isExpert?: boolean;
    /**
     * Status
     */
    status?: QueryParticipantListItem.status;
    /**
     * csrHandler
     */
    csrHandler?: QueryParticipantListItem.csrHandler;
    /**
     * Recorded live minutes
     */
    liveMinutes?: number;
    /**
     * Meeting Pin
     */
    meetingPin?: string;
    /**
     * Invitation Link
     */
    inviteLink?: string;
    /**
     * Meeting URL
     */
    mtgUrl?: string;
    userSpecialty?: UserSpecialtyRecordItem;
}

export namespace QueryParticipantListItem {

    /**
     * Status
     */
    export enum status {
        INVITED = 'invited',
        DECLINED = 'declined',
        CLAIMED = 'claimed',
        MISSEDOUT = 'missedout',
        LATEREVIEW = 'latereview',
        TIMEDOUT = 'timedout',
        CANCELLED = 'cancelled',
        ANSWERED = 'answered',
        FOLLOWUP = 'followup',
        USER = 'user',
        CSR = 'csr',
    }

    /**
     * csrHandler
     */
    export enum csrHandler {
        NONE = 'none',
        ASSIGNED = 'assigned',
        UNASSIGNED = 'unassigned',
        DEACTIVATED = 'deactivated',
    }


}
