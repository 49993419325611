import { ReportStoreInterface } from "./types";
import { ActionContext, Module, StateFor } from "vuex-typescript-interface";
import { RootStoreInterface } from "../types";
import { ReportDefinition, ReportService } from "@/service";

const reports: Module<ReportStoreInterface, RootStoreInterface> = {
  state: {
    reportDefs: []
  },

  getters: {
    reports: state => state.reportDefs
  },

  mutations: {
    setReports(state: StateFor<ReportStoreInterface>, r: Array<ReportDefinition>): void {
      state.reportDefs = r;
    }
  },

  actions: {
    async loadReports({
      commit
    }: ActionContext<ReportStoreInterface, RootStoreInterface>): Promise<Array<ReportDefinition>> {
      const r = await ReportService.listCommonExports();
      commit("setReports", r);
      return r;
    },

    async requestReport(
      { commit: _commit }: ActionContext<ReportStoreInterface, RootStoreInterface>,
      { id, options }: { id: string; options: Record<string, any> }
    ): Promise<void> {
      const opts = Object.assign({}, { report: id }, options);
      await ReportService.prepareCommonReport(opts);
    }
  }
};

export default reports;
