/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Link
 */
export type LinkItem = {
    /**
     * Record Unique ID
     */
    id?: number;
    /**
     * Link type
     */
    type?: LinkItem.type;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    created?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    updated?: number;
    /**
     * Link linkType
     */
    linkType?: LinkItem.linkType;
    /**
     * Link title
     */
    title?: string;
    /**
     * Link
     */
    link?: string;
    /**
     * Link s3bucket
     */
    s3bucket?: string;
    /**
     * Link s3key
     */
    s3key?: string;
    /**
     * Link s3timeout
     */
    s3timeout?: number;
    /**
     * API Response Version
     */
    _ver?: number;
}

export namespace LinkItem {

    /**
     * Link type
     */
    export enum type {
        URL = 'url',
        S3 = 's3',
    }

    /**
     * Link linkType
     */
    export enum linkType {
        FORGET = 'forget',
        REGISTER = 'register',
        TUTORIAL = 'tutorial',
        TRAINING = 'training',
    }


}
