<template>
  <div class="new-query-layout" :class="{
      'd-mobile': isMobile
    }" :style="layoutStyle">
    <div class="d-md-flex d-block review-toolbar align-center ps-md-8 ps-4 py-4 pe-md-0 pe-4">
      <div class="draft-details d-flex justify-space-between align-center py-2 mt-0 pe-4"
        v-if="isDraftQueryDetailsVisible" :class="{'draft-details-mobile' : isMobile}">
        <span class="trash-toolbar-content">Draft Saved: {{ updatedDate() }} by {{ (activeDraft.updatedBy == user?.id) ?
          'You' : `${activeDraft.userUpdated?.firstName} ${activeDraft.userUpdated?.lastName}` }}</span> <v-btn icon
          @click="openDeleteModal = true"><v-icon color="error" size="small">$trash</v-icon></v-btn>
      </div>
    </div>
    <div v-if="isMobile" class="accordion-container">
      <!-- accordion-start: to wrap two  coloumn -->
      <v-list v-if="isMobile" class="pa-0">
        <v-list-group v-model="opensummaryAccordion" class="two-column-container" no-action>
          <template v-slot:activator>
            <v-list-item-title class="font-weight-bold item-header-content">Summary</v-list-item-title>
          </template>
          <v-list-item class="pa-0 validation-list">
            <div class="review-wrapper mt-4">
              <review-pane :isDraftIdPresent="isDraftQueryOpen" :draftId="draftId"
                :showBuilderSummary="showQuestionSummary" :queryBuilderData="latestResponse" class="review-pane review-pane-mb" :class="{'reduce-wrapper-height' : showMbSubmissionBtn}"
                @submit="submit()" />
            </div>
          </v-list-item>
        </v-list-group>
        <v-list-group class="two-column-container submission-wrapper" v-model="openSubmissionAccordion" no-action>
          <template v-slot:activator>
            <v-list-item-title class="font-weight-bold item-header-content">Submission</v-list-item-title>
          </template>
          <v-list-item class="ps-4 validation-list">
            <chat-bot ref="bot" :callback="callback"
              :showAllActions="isDraftQueryOpen ? isDraftQueryOpen : showActionsList" :init="{ x: 1 }"
              :class="{'reduce-wrapper-height' : showMbSubmissionBtn}"
              styles="max-width:600px;flex:1;width:100%;margin:0 auto;" :showAvatar="false" @submitError="onSubmitError"
              @fetch-draft-id="fetchDraftId" :userId="userId" @show-question-summary="showQuestionSummary = true" />
          </v-list-item>
        </v-list-group>
      </v-list>
      <!-- accordion-end -->
    </div>
    <v-btn v-if="showMbSubmissionBtn" class="submit-discussion ma-4" color="primary" @click="submit()">{{ $t("captions.submitQuery") }}</v-btn>
    <div v-if="!isMobile" class="new-query-scrollable review-container"
      :class="{ 'pr-0': isMobile, 'pr-8': !isMobile, 'pl-0': isMobile, 'pl-4': !isMobile }" :style="{
        top: isMobile ? '60px' : isDraftQueryOpen ? '78px' : canSubmit ? '60px' : '60px',
        bottom: 0
      }">
      <v-slide-y-transition>
        <div class="review-wrapper mt-4">
          <review-pane :isDraftIdPresent="isDraftQueryOpen" :draftId="draftId" :showBuilderSummary="showQuestionSummary"
            :queryBuilderData="latestResponse" class="review-pane" @submit="submit()" />
        </div>
      </v-slide-y-transition>
    </div>
    <div  class="left-pane new-query-scrollable scrollable-fader d-flex flex-column align-stretch new-query-view"
      :class="{ 'pl-2': isMobile, 'pl-8': !isMobile, 'pr-2': isMobile, 'pr-4': !isMobile }">
      <!-- <div class="scroll-fader"></div> -->
      <chat-bot  x-wizardMode="!isMobile" ref="bot" :callback="callback"
        :showAllActions=" isDraftQueryOpen ? isDraftQueryOpen : showActionsList" :init="{ x: 1 }"
        styles="max-width:600px;flex:1;width:100%;margin:0 auto;" :showAvatar="false" @submitError="onSubmitError"
        @fetch-draft-id="fetchDraftId" :userId="userId" @show-question-summary="showQuestionSummary = true"
        v-if="showChatBot && !isMobile" />
      <!-- <div v-if="isMobile" class="mobile-bar text-center">
        <v-btn color="primary" outlined @click="showOverlay = !showOverlay"><strong>Case Details</strong></v-btn>
      </div> -->
      <DraftDiscussion :userId="userId" :isDraftWizard="isDraftQueryOpen" @start-discussion="showActionsList = true"
        @show-loader="mask=true" @show-chatbot="showChatBot = true; mask = false" @query-data="fetchQueryData" />
      <DeleteDraftDiscussion :userId="userId" :isVisible="openDeleteModal" @dismiss-modal="openDeleteModal = false" />
    </div>
    <v-overlay absolute :value="mask">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import { ChatBotEvent, ChatBotResponse, NewQueryService, QueryHeaderInputItem } from "@/service";
import ChatBot from "@/components/ChatBot/ChatBot.vue";
import ReviewPane from "./components/ReviewPane.vue";
import OBtn from "@/framework/OBtn.vue";
import { NavigationGuardNext } from "vue-router";
import DraftDiscussion from "../../components/Discussion/DraftDiscussion.vue";
import DeleteDraftDiscussion from "@/components/Discussion/DeleteDraftDiscussion.vue";
import { ThemeStoreInterface } from "@/store/theme/types";
import { RootStoreInterface } from "@/store/types";
import { createHelpers } from "vuex-typescript-interface";

import { DateTime } from "luxon";


const { mapGetters: mapThemeGetters } = createHelpers<ThemeStoreInterface>();
const { mapMutations, mapGetters } = createHelpers<RootStoreInterface>();
const themeGetters = mapThemeGetters(["accentImage", "accentFactor"]);
const mutations = mapMutations(["setProgressCheckCallback"]);
const getters = mapGetters(["user"])


export default (Vue as VueConstructor<
  Vue & {
    $refs: {
      bot: InstanceType<typeof ChatBot>;
    };
  }
>).extend({
  components: {
    ChatBot,
    ReviewPane,
    OBtn,
    DraftDiscussion,
    DeleteDraftDiscussion
  },
  props: {
    userId: {
      type: [String, Number],
      default: null
    }
  },
  data: () => ({
    // preview: null as QueryInteractionDisplayItem | null,
    latestResponse: null as ChatBotResponse | null,
    showOverlay: false,
    mask: false,
    isSubmitted: false,
    isEditing: false,
    canLogoutAs: false,
    isDraftQueryOpen: false,
    openDeleteModal: false,
    showChatBot:false,
    showActionsList: false,
    showQueryDetails: false,
    activeDraft: {} as QueryHeaderInputItem,
    showDeleteBar: false,
    draftId: 0,
    showQuestionSummary: false,
    openSubmissionAccordion: true,
    opensummaryAccordion: false
  }),
  computed: {
    ...themeGetters,
    ...getters,
    canSubmit(): boolean {
      const cd = this.latestResponse?.action.clientData ?? {};
      // Has reached the end and if there are terms they have been accepted
      return cd["completed"] && !this.isEditing && (!cd["termsRequired"] || cd["termsAccepted"]);
    },
    isDraftQueryDetailsVisible(): boolean {
        return this.isDraftQueryOpen && this.showQueryDetails && this.showDeleteBar;
      }, 
    isMobile(): boolean {
      // True when the viewport is under the breakpoint for mobile behavior
      return this.$vuetify.breakpoint.smAndDown;
    },
    layoutStyle(): Record<string, string | number> {
      const style: Record<string, any> = {
        left: `${this.$vuetify.application.left}px`,
        top: `${this.$vuetify.application.top}px`,
        bottom: `${this.$vuetify.application.bottom}px`,
        right: `${this.$vuetify.application.right}px`
      };
      if (!this.isMobile) {
        style["background-image"] = `url("${this.accentImage}") !important`;
        style["background-size"] = `${(this.accentFactor * 50).toFixed(0)}% auto  !important`;
      }
      return style;
    },
    paneStyle(): Record<string, string> {
      return {
        position: "fixed",
        left: `${this.$vuetify.application.left}px`,
        top: `${this.$vuetify.application.top}px`,
        bottom: `${this.$vuetify.application.bottom}px`,
        right: `${this.$vuetify.application.right}px`
      };
    },
    isDirty(): boolean {
      return !this.isSubmitted && !!this.latestResponse?.action.clientData?.diagnosisId;
    },
    showMbSubmissionBtn(): boolean {
      return this.canSubmit && this.isMobile;
    }
  },
  methods: {
    ...mutations,
    submit(): void {
      if (this.canSubmit) {
        this.mask = true;
        this.$eventBus.$emit("newQuerySubmit");
      }
    },
    onSubmitError(err: string): void {
      console.log("Submit error");
      this.mask = false;
    },
    async callback(event: ChatBotEvent): Promise<ChatBotResponse | undefined> {
      // console.log("Callback here", event);
      this.showOverlay = false;

      try {
        this.latestResponse = this.userId
          ? await NewQueryService.newQueryChatProxy({ userId: Number(this.userId), requestBody: event })
          : await NewQueryService.newQueryChat({ requestBody: event });
          
        if (this.canSubmit) {
          this.showOverlay = true;
        }
        this.isEditing = false;

        // Submit action?
        // Handle the end of the chat - usually on success
        if (this.latestResponse?.action.action === "submit") {
          // Navigate to the query?
          const id = this.latestResponse.action.clientData["queryId"] ?? undefined;
          this.isSubmitted = true;
          if (id) {
            // Navigate to query
            this.$router
              .replace({
                name: "Discussion",
                params: { id: String(id), alert: this.$t("alerts.querySuccess") as string }
              })
              .catch(() => {
                /* */
              });
          } else {
            // Navigate to main
            this.$router.replace("/").catch(() => {
              /* */
            });
          }
        }
        if(this.isDraftQueryOpen) this.showDeleteBar = true;
        return this.latestResponse;
      } catch (e) {
        if ((e as any).body?.errorKey) {
          await this.$alert(this.$t((e as any).body.errorKey) as string);
        } else if ((e as any).message) {
          await this.$alert(this.$t((e as any).message) as string);
        } else {
          await this.$alert(this.$t("errors.unspecified") as string);
        }
        if ((e as any).status === 401) {
          this.$router.replace("/").catch(() => {
            /* */
          });
        }
        this.mask = false;
      }
      return undefined;
    },
    async checkDirty(next?: NavigationGuardNext) {
      if (this.isDirty && !this.canLogoutAs) {
        return !await this.$showDirty(next);
      } else {
        next?.();
      }
      return true;
    },
    fetchQueryData(queryData: Array<QueryHeaderInputItem>) {
      this.activeDraft = queryData[0];
      this.showQueryDetails = true;
    },
    updatedDate() {
      if (!this.activeDraft?.updated) {
        return "";
    }
    const dateTime = DateTime.fromSeconds(this.activeDraft.updated);
    const formattedDate = dateTime.toFormat('h:mma MM/dd/yyyy ZZZZ ');
    
    return `${formattedDate}`;
    },
    fetchDraftId(id: number | string) {
      this.draftId = +id
    }
  },
  watch: {
    userId: {
      handler(val, old): void {
        if (val !== old) {
          this.$refs.bot.reset();
        }
      }
    },
    canSubmit(newVal) {
      if(newVal) this.opensummaryAccordion = true;
    }
  },
  created() {
    // Handle LogoutAs event
    this.setProgressCheckCallback(async(): Promise<boolean> => {
      this.canLogoutAs = await this.checkDirty(undefined);
      return this.canLogoutAs;
    });

    // capture summary edit action
    this.$eventBus.$on("actionClick", (action: string) => {
        this.isEditing  = true;
    });
  },
  mounted() {
    this.isDraftQueryOpen =  (this.$route.query["draftId"]) ? true : false;
  },
 
  beforeDestroy() {
    this.setProgressCheckCallback(null);
  }
});
</script>
<style lang="scss" scoped>
@import "@/scss/local.scss";
.new-query-view {
  //   background-color: rgb(236, 236, 211);

  .avatar-pic {
    position: absolute;
    width: 15rem;
    height: 15rem;
    bottom: 0;
    left: 0;
    margin-left: -14rem;
    // background-color: rgba(80, 80, 80, 0.2);
  }
  .new-query-content {
    background-color: rgb(243, 209, 209);
  }
}

.new-query-layout {
  overflow: hidden;
  position: fixed;
  padding: 0 16px;
  // display: flex;
  transform: scale(1); // Set this element as anchor for "fixed" children

  background-repeat: no-repeat !important;
  background-origin: border-box;
  background-position: bottom right !important;

  &.d-mobile {
    background-color: #ffffff;
  }

  .new-query-scrollable {
    overflow-y: auto;
    position: relative;
    &.scrollable-fader {
      // Uncomment to check if gradient may clobber backgrounds.
      // background-color: white;
      // Top of scrolling area fades gradually
      .scroll-fader {
        height: 40px;
        width: 100%;
        position: absolute;
        left: 0;
        z-index: 10;
        top: 0;
        // Must match the background color!
        background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); /* W3C */
      }
    }
  }

  .review-toolbar {
    position: absolute;
    top: 0;
    left: 50%;
    right: 0;
  }

  .review-container {
    position: fixed;
    width: 50%;
    right: 0;
    overflow: auto;
    .review-wrapper {
      position: relative;
    }
  }

  .left-pane {
    position: fixed;
    width: 50%;
    left: 0;
    top: 0;
    bottom: 0;
  }

  .mobile-wrapper {
    position: relative;
    top: 0;
    bottom: 0;
  }

  &.d-mobile {
    overflow: inherit;
    // position: relative;
    display: flex;
    flex-direction: column;
    align-content: stretch;
    padding: 0 8px;

    padding: 0 0;
    transform: inherit; // Set this element as anchor for "fixed" children

    .mobile-wrapper {
      position: fixed;
      //top: 0;
      right: 0;
      width: 100%;
      overflow-y: visible;
    }

    .left-pane {
      position: static;
      width: 100%;
      //  top: 60px;
      //padding-top: 60px;
      flex: 1;
    }
    .new-query-scrollable {
      position: relative;
    }

    .review-toolbar {
      left: 0;
    }

    .review-container {
      position: static;
      width: 100%;
      margin-top: 60px;
      .review-wrapper {
        position: relative;
        left: 0;
        right: 0;
        top: 0;
        background-color: white;
        z-index: 100;
        padding: 24px 24px;

        .review-pane {
          margin: 0 auto;
          max-width: 600px;
        }
      }
    }
  }
  .summary-btn {
    font-weight: bold;
    border-radius: 10px;
  }
}

.draft-details {
  max-width: 600px;
  box-shadow: $o-primary-shadow;
  border-radius: $o-border-radius;
  margin-top: 12px;
  margin-bottom: 12px;
  padding-left: 24px;
  color: var(--v-primary-base);
  width: 100%;
  margin-left: -16px;
  background-color: rgb(255, 255, 255);

}

.trash-toolbar-content {
  font-size: 14px
}

.draft-details-mobile {
  position: fixed;
  bottom: 0;
  margin-bottom: 0;
  left: 0;
  right: 0;
  max-width: 100%;
  border-radius: 0;
  z-index: 2;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .trash-toolbar-content {
  font-size: 12px;
}
  .draft-details {
    margin-left: 0;
    padding-left: 16px;
    padding-right: 6px;

    .v-btn {
    bottom: 3px
  }
  
  }

  .new-query-layout.d-mobile {
    .review-container {
    margin-top: 111px;
  }

  } 
}

.submit-discussion {
  border-radius: 10px;
}
</style>
<style lang="scss">
// globally handling mobile styling- .review-item is not accessible in scope
.submission-wrapper {
  border-top: 2px solid var(--v-primary-base);
  border-bottom: 2px solid var(--v-primary-base);
}
.two-column-container {
  position: relative;
  z-index: 9999;

  .v-list-item--active .item-header-content {
    font-size: 24px
  }


  .review-pane, .chat-bot-component {
    max-height: calc(100vh - 234px);
    overflow-y: auto;

    &.reduce-wrapper-height {
     max-height: calc(100vh - 293px);
    }
  }

  .review-wrapper {
    width: 100%;
  }

  .review-pane-mb .review-item-sheet , .review-pane-mb .question-builder-summary {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }

  .review-item-sheet, .question-builder-summary {
      margin: 16px auto !important;
    }

   .chat-bot-component .chat-bot-scroller {
      position: relative;
    }
}

@media (min-width: 675px) and (max-width: 960px) {
  .two-column-container{
    .review-pane-mb .review-item-sheet , .review-pane-mb .question-builder-summary {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  } 
}
</style>
