import _Vue from "vue";
import vuetify from "./vuetify";

// install $cofirm to vue instances
// Usage
//     $confirm(message: string, options?: ConfirmDialogOptions): Promise<boolean>;

import Confirm from "@/components/util/Confirm.vue";

export interface ConfirmDialogOptions {
  buttonTrueText?: string;
  buttonFalseText?: string;
  buttonTrueIcon?: string;
  buttonFalseIcon?: string;
  buttonTrueColor?: string;
  buttonFalseColor?: string;
  buttonFalseFlat?: boolean;
  buttonTrueFlat?: boolean;
  color?: string;
  icon?: string;
  message?: string;
  persistent?: boolean;
  title?: string;
  width?: number;
  copyContent?: string;
  copySuccessText?: string;
  copyFailureText?: string;
}

export function ConfirmPlugin(Vue: typeof _Vue, options?: ConfirmDialogOptions): void {
  const ctor = Confirm.extend({ vuetify });
  const defaultOpts: ConfirmDialogOptions = options || {};

  function createDialogCmp(options: ConfirmDialogOptions): Promise<boolean | null> {
    const container = document.querySelector("[data-app=true]") || document.body;
    return new Promise(resolve => {
      const cmp = new ctor(
        Object.assign(
          {},
          {
            propsData: Object.assign({}, defaultOpts, options),
            destroyed: () => {
              container.removeChild(cmp.$el);
              resolve(cmp.$data.value);
            }
          }
        )
      );
      container.appendChild(cmp.$mount().$el);
    });
  }
  function show(message: string, options: ConfirmDialogOptions = {}): Promise<boolean | null> {
    options.message = message;
    return createDialogCmp(options);
  }

  // do stuff with options
  Vue.prototype.$confirm = show;
}

_Vue.use(ConfirmPlugin);
