var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('action',_vm._g({attrs:{"action":_vm.action,"showText":""},scopedSlots:_vm._u([{key:"default",fn:function({ show }){return [(show && !_vm.event)?_c('div',[_c('v-expand-transition',[_c('div',{staticClass:"d-flex flex-row align-baseline justify-end"},[_c('v-text-field-integer',{ref:"field",staticStyle:{"width":"6rem"},attrs:{"type":"number","xlabel":"Age Magnitude","options":{
                  inputMask: '###',
                  outputMask: '###',
                  empty: null,
                  applyAfter: false
                },"properties":{
                filled: true,
                xlabel: 'action.label',
                hint: 'Age',
                'persistent-hint': true,
                autofocus: true,
                placeholder: ' ',
                'persistent-placeholder': true
              }},model:{value:(_vm.magnitude),callback:function ($$v) {_vm.magnitude=$$v},expression:"magnitude"}}),_c('div',{staticStyle:{"width":"1rem"}}),_c('v-select',{staticStyle:{"width":"9rem","flex-grow":"0"},attrs:{"hint":`Age Units`,"items":_vm.ageUnits,"item-text":"name","item-value":"value","xlabel":"Units","filled":"","persistent-hint":""},model:{value:(_vm.units),callback:function ($$v) {_vm.units=$$v},expression:"units"}}),_c('v-btn',{attrs:{"text":"","disabled":_vm.disabled},on:{"click":_vm.click}},[_vm._v("Select")])],1)])],1):_vm._e()]}}])},_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }