export * from "./types";
import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
import { UserType } from "@/config";
import Store from "@/store";
import Dashboard from "@/views/dashboard/Dashboard.vue";
import Support from "@/views/support/Support.vue";

import { UserPartialItem } from "@/service";

import authRoutes from "./modules/auth";
import devRoutes from "./modules/dev";
import invitations from "./modules/invitations";
import members from "./modules/members";
import account from "./modules/account";
import querySetup from "./modules/querySetup";
import newQuery from "./modules/newQuery";
import discussion from "./modules/discussion";
import tags from "./modules/tags";
import reports from "./modules/reports";
import queryIndex from "./modules/queryIndex";
import invitees from "./modules/invitees";
import redirects from "./modules/redirects";

Vue.use(VueRouter);

const dev = process.env.VUE_APP_PROD ? [] : devRoutes;

// I found it difficult to accurately type meta here without breaking something else
// so it remains 'any'

const routes: Array<RouteConfig> = [
  ...devRoutes,
  ...authRoutes,
  ...newQuery,
  ...discussion,
  ...queryIndex,
  ...tags,
  ...reports,
  ...invitations,
  ...members,
  ...account,
  ...querySetup,
  ...invitees,
  ...redirects,
  {
    // This the the main page, only accessible once logged in
    path: "/",
    name: "Home",
    component: Dashboard,
    meta: {
      appClass: "appModeUser",
      appBar: "user",
      navDrawer: true,
      title: ""
    }
  },
  {
    path: "/support",
    name: "Support",
    component: Support,
    meta: { allowedUsers: [] }
  },
  {
    name: "AuthDisallowed",
    path: "/noAuth",
    component: () => import("@/views/AuthDisallowed.vue"),
    meta: {
      appClass: "appModeUserRightNoFooter",
      appBar: "user"
    }
  },
  {
    path: "/:catchAll(.*)",
    component: () => import("@/views/404.vue"),
    name: "NotFound",
    props: true,
    meta: {
      appClass: "appModeUserRightNoFooter",
      appBar: "user"
    }
  }
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(_to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

// Login helper
function restoreLogin(): boolean {
  const loginData = localStorage.getItem("cen-login");
  if (loginData) {
    try {
      const r = JSON.parse(loginData);
      if (r.exp > Date.now() / 1000) {
        Store.commit("setLogin", {
          user: r.user as UserPartialItem,
          exp: r.exp,
          token: r.token as string,
          authUser: r.authUser ?? null,
          canPost: Boolean(r.canPost),
        });
        Store.dispatch("applyTheme", r.theme ?? "default");
        return true;
      }
    } catch (e) {
      // Do nothing
    }
    localStorage.removeItem("cen-login");
  }
  return false;
}

// Guards
const alternateHomePages = Object.fromEntries([[UserType.CLIENT, "MemberLobby"], [UserType.CSR, "QueryIndex"]]);

function nearestMeta<T>(prop: string, to: Route): T {
  return to.matched
    .slice()
    .reverse()
    .find(r => r.meta[prop] !== undefined)?.meta[prop];
}

router.beforeEach((to, from, next) => {
  // Redirect if user is disallowed to view the page
  let isLogged = !!Store.getters.isLoggedIn;
  // See if we need to restore login- Updated logic - always try to restore the login
  // Otherwise it looks like were logged out when we arent
  if (!isLogged) {
    isLogged = restoreLogin();
  }
  // This ensures that parent routes value for this propagate (allowedUsers does not)
  const isAuthDisallowed = to.matched.some(route => route.meta.disallowAuthed);
  if (isLogged && isAuthDisallowed) {
    return from.path === "/noAuth" ? next(false) : next("/noAuth");
  } else if (!isLogged && !isAuthDisallowed) {
    if (to.path !== "/") Store.commit("setRedirectRoute", { 'guest': Object.assign({}, to)});
    return from.path === "/login" ? next(false) : next("/login");
  } else if (isLogged) {
    // Special case - home page - some users cant use it, so redirect accordingly.
    // Where a single user type can have multiple home pages (? Members) then we need additional
    // logic driven by the server (perhaps the server can send the name of the users home page in the user object or auth object?)

    // If the route only allows certain user types...
    const userType = Store.getters.user?.userType || 0;

    if (to.path === "/") {
      const name = alternateHomePages[userType];
      if (name) {
        return from.name == name ? next(false) : next({ name });
      }
    }
    // matched routes are in parent to child order, so we need the last instance of meta.allowedUsers
    const allowedUsers = nearestMeta<Array<number>>("allowedUsers", to);

    if (allowedUsers && !allowedUsers.includes(userType)) {
      return from.name == 'NotFound"' ? next(false) : next({ name: "NotFound", params: { catchAll: "UnknownPage" } });
    }

    const disallowedUsers = nearestMeta<Array<number>>("disallowedUsers", to);

    if (disallowedUsers && disallowedUsers.includes(userType)) {
      return from.name == 'NotFound"' ? next(false) : next({ name: "NotFound", params: { catchAll: to.name ?? "UnknownPage" } });
    }

    // Some routes are only allowed for the current user
    if (userType !== UserType.CSR) {
      const sameUserOnly = nearestMeta<boolean>("sameUser", to);

      if (sameUserOnly) {
        const userId = (Store.getters.user?.id ?? 0).toString();
        return !to.params.userId || to.params.userId == userId
          ? next()
          : next({ name: "NotFound", params: { catchAll: "UnknownPage" } });
      }
    }
  }

  return next();
});

export default router;
