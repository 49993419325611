<template>
    <v-dialog v-model="isVisible" max-width="630" scrollable persistent>
        <v-card :loading="cardLoading">
            <v-card-title>
                <v-toolbar flat dense class="ps-0">
                    <v-toolbar-title class="primary--text ps-0">{{ $t('discussion.index.title.deleteDraft')
                        }}</v-toolbar-title>
                </v-toolbar>
            </v-card-title>
            <v-card-text class="pb-0">
                <v-container class="pb-0 ps-0">
                    <p class="mb-0 black--text" v-html="$t('discussion.trash.message')">
                    </p>
                </v-container>
            </v-card-text>
            <v-card-actions class="d-flex justify-md-end justify-start pb-6">
                <o-btn outlined class="me-4 font-weight-regular" @click="dismiss()">
                    {{ $t('captions.cancel') }}
                </o-btn>
                <o-btn color="primary" class="px-4 font-weight-regular" @click="onDeleteDraft()">
                    {{ $t('discussion.index.caption.deleteDraft') }}
                </o-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script lang="ts">
import Vue from "vue";
import OBtn from "@/framework/OBtn.vue";
import { QueryService } from "@/service";


export default Vue.extend({
    components: {
        OBtn
    },
    data: () => ({
        visible: true,
        cardLoading: false
    }),
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
        userId: {
            type: [String, Number],
            required: false
        }
    },
    methods: {
        dismiss() {
            this.$emit('dismiss-modal')
        },
        async onDeleteDraft() {
            this.cardLoading = true;
            let draftId = this.$route.query["draftId"];
            try {
                await QueryService.deleteQueryHeaderInputById({ id: +(draftId) });
            } catch (e) {
                console.log(e);
            }

            this.dismiss();
            this.redirectToDraftScreen()
            this.cardLoading = false;

        },
        redirectToDraftScreen() {
            const targetRoute = {
                name: 'NewQueryProxy',
                params: { userId: this.userId as string }
            };

            const targetRouteString = this.$router.resolve(targetRoute).href;

            if (this.$route.fullPath !== targetRouteString) {
                window.location.assign(targetRouteString);
            }
        },
    }
})
</script>
<style lang="scss" scoped>
:deep(.v-toolbar__content) {
    padding-left: 0;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
    .v-dialog > .v-card > .v-card__actions  {
        padding: 24px;
    }

    .container {
        padding-top: 0;
    }
}
</style>
