<template>
  <o-sheet v-show="!loading" class="pa-6 overflow-hidden">
    <div class="d-flex">
      <div class="primary--text t-5 font-weight-bold">{{ !mobileOnly ? $t(`discussion.index.title.featured`) : "Featured" }}</div>
      <v-spacer />
      <o-btn class="text-subtitle-1" text @click="onViewAll">{{ $t(`captions.viewAll`)}}</o-btn>
    </div>
    <div class="my-auto" style="height: 80% !important">
      <div v-if="query.queryId" class="my-4" @click="mobileOnly ? show() : undefined">
        <upcoming-discussion-item  :query="query" :mobile="isMobile" active @respond="show" />
      </div>
      <div v-else v-show="!loading" class="fill-height">
        <div :class="{'grey--text text--darken-0': true, 'd-flex flex-wrap align-center justify-center': !isMobile }" style="height: 100% !important">No discussion found</div>
      </div>
    </div>
  </o-sheet>
</template>

<script lang="ts">
import Vue from "vue";
import { QueryIndexFullDisplay, QueryService } from "@/service";
import OSheet from "@/framework/OSheet.vue";
import OBtn from "@/framework/OBtn.vue";
import UpcomingDiscussionItem from "./UpcomingDiscussionItem.vue";

export default Vue.extend({
  components: {
    OSheet,
    OBtn,
    UpcomingDiscussionItem
  },
  data: () => ({
    query: {} as QueryIndexFullDisplay,
    loading: false
  }),
  props: {
    userId: {
      type: [String, Number],
      required: true
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    mobileOnly: {
      type: Boolean,
      default: false
    },
    isExpert: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    async load() {
      this.loading = true;
      try {
        const r = await QueryService.getQueryFeatured({id: Number(this.userId)});
        if (r.data && r.data.length) {
          this.query = r.data[0];
        }
      } catch(e) {
        // TODO: Handle Error
        console.log(e);
      }
      this.loading = false;
    },
    onViewAll() {
      this.$router.push({ name: "Discussions", hash: this.isExpert ? "#requiresAction" : "#newOpportunities" }).catch(() => { /* Handle Error */});
    },
    show() {
      this.$router.push({ name: this.query.isInvited ? "Invitation" : "Discussion", params: { id: String(this.query.queryId) } }).catch(() => { /* Handle Error */});
    }
  },
  mounted() {
    this.load();
  }
});
</script>
