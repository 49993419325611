<template>
  <ValidationProvider :name="label" :rules="rules" v-slot="{ errors, valid }">
    <v-text-field
      v-model="innerValue"
      :id="fieldId"
      :error-messages="errors"
      :success="Boolean(rules) && valid"
      :label="multiLang ? $t(`fields.${label}`) : label"
      :placeholder="placeholder ? (multiLang ? $t(`placeholders.${placeholder}`) : placeholder) : undefined"
      :hint="hint ? (multiLang ? $t(`hints.${hint}`) : hint) : undefined"
      v-bind="$attrs"
      v-on="$listeners"
    ></v-text-field>
  </ValidationProvider>
</template>

<script lang="ts">
import Vue from "vue";
import { ValidationProvider } from "vee-validate";

export default Vue.extend({
  inheritAttrs: false,
  components: {
    ValidationProvider
  },
  props: {
    // must be included in props
    value: {
      type: [String, Number],
      required: false
    },
    rules: {
      type: [Object, String],
      default: ""
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false
    },
    hint: {
      type: String,
      required: false
    },
    id: {
      type: String,
      required: false
    },
    withoutId: {
      type: Boolean,
      default: false
    },
    multiLang: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    innerValue: "" as string | number
  }),
  computed: {
    fieldId(): string | undefined {
      if (this.withoutId) return undefined;
      return this.id ? this.id : this.label;
    }
  },
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
});
</script>
