<template>
  <v-navigation-drawer :class="specificity ? 'prioritize-specificity' : ''" right clipped app v-model="drawer" disable-route-watcher disable-resize-watcher>
    <template v-slot:prepend>
      <v-list-item id="adminMenu">
        <v-list-item-content>
          <v-list-item-title>Admin Menu</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-divider></v-divider>
    <v-list>
      <v-list-group no-action id="queries" group="queries">
        <template v-slot:activator>
          <v-list-item-title>Queries</v-list-item-title>
        </template>
        <v-list-item link :to="{ name: 'QueryIndex' }" id="queryIndex" @click="closeDrawer">
          <v-list-item-title>Query List</v-list-item-title>
        </v-list-item>
      </v-list-group>
      <v-list-group no-action id="users" group="users|invitations">
        <template v-slot:activator>
          <v-list-item-title>Users</v-list-item-title>
        </template>
        <v-list-item link :to="{ name: 'UserIndex' }" id="userIndex" @click="closeDrawer">
          <v-list-item-title>User Admin</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: 'InvitationIndex' }" id="invitationIndex" @click="closeDrawer">
          <v-list-item-title>User Invitations</v-list-item-title>
        </v-list-item>
      </v-list-group>
      <v-list-group no-action id="querySetup" group="grants|plans|diagnosis|pools">
        <template v-slot:activator>
          <v-list-item-title>Query Setup</v-list-item-title>
        </template>
        <v-list-item link :to="{ name: 'GrantIndex' }" id="grantIndex" @click="closeDrawer">
          <v-list-item-title>Funding Sources</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: 'PlanIndex' }" id="planIndex" @click="closeDrawer">
          <v-list-item-title>Plans</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: 'DiagnosisDomain' }" id="diagnosisDomain" @click="closeDrawer">
          <v-list-item-title>Diagnoses</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: 'PoolIndex' }" id="poolIndex" @click="closeDrawer">
          <v-list-item-title>Expert Pools</v-list-item-title>
        </v-list-item>
      </v-list-group>
      <v-list-group no-action id="tags" group="tags">
        <template v-slot:activator>
          <v-list-item-title>Tags (BETA)</v-list-item-title>
        </template>
        <v-list-item link :to="{ name: 'TagIndex' }" id="tagIndex" @click="closeDrawer">
          <v-list-item-title>Tag List (BETA)</v-list-item-title>
        </v-list-item>
      </v-list-group>
      <v-list-group v-if="$allowedCSRAdmin" no-action id="customFields" group="customFields">
        <template v-slot:activator>
          <v-list-item-title>Custom Fields</v-list-item-title>
        </template>
        <v-list-item link :to="{ name: 'CustomField' }" id="CustomField" @click="closeDrawer">
          <v-list-item-title>Template List</v-list-item-title>
        </v-list-item>
      </v-list-group>
      <v-list-group no-action id="reports" group="reports">
        <template v-slot:activator>
          <v-list-item-title>Reports</v-list-item-title>
        </template>
        <v-list-item link :to="{ name: 'CommonReports' }" id="commonReports" @click="closeDrawer">
          <v-list-item-title>Common Reports</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="$allowedCSRAdmin" link :to="{ name: 'ExpertAggStats' }" id="expertAggStats" @click="closeDrawer">
          <v-list-item-title>Expert Stats</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="$allowedCSRAdmin" link :to="{ name: 'QueryAggStats' }" id="queryAggStats" @click="closeDrawer">
          <v-list-item-title>Query Stats</v-list-item-title>
        </v-list-item>
      </v-list-group>
      <v-list-item v-show="false" link :to="{ name: 'NewQuery' }" id="newQuery" @click="closeDrawer">
        <v-list-item-title>New Query</v-list-item-title>
      </v-list-item>
      <v-list-group no-action id="dev" v-if="dev">
        <template v-slot:activator>
          <v-list-item-title>Dev</v-list-item-title>
        </template>
        <v-list-item link :to="{ name: 'Playground' }" id="playground" @click="closeDrawer">
          <v-list-item-title>Design Sandbox</v-list-item-title>
        </v-list-item>
      </v-list-group>
    </v-list>
    <template v-slot:append>
      <v-list-item dense>
        <v-list-item-title>{{ commit }}</v-list-item-title>
      </v-list-item>
      <v-list-item dense>
        <v-list-item-title>Srv: {{ serverVersion }}</v-list-item-title>
      </v-list-item>
    </template>
  </v-navigation-drawer>
</template>

<script lang="ts">
import Vue from "vue";

import { createHelpers } from "vuex-typescript-interface";
import { RootStoreInterface } from "@/store/types";

const { mapGetters, mapMutations } = createHelpers<RootStoreInterface>();

const getters = mapGetters(["isAdminMenuOpen", "serverVersion"]);
const setters = mapMutations(["setAdminMenu"]);

export default Vue.extend({
  props: {
    // State of the drawer/menu button
    open: {
      type: Boolean,
      default: false
    },
    specificity: {
      type: Boolean, 
      default: false
    }
  },
  computed: {
    ...getters,
    commit(): string {
      return process.env.VUE_APP_SOURCE_COMMIT as string;
    },
    dev(): boolean {
      return !process.env.VUE_APP_PROD;
    },
    drawer: {
      get() {
        return this.isAdminMenuOpen;
      },
      set(val: boolean) {
        this.setAdminMenu(val);
      }
    }
  },
  methods: {
    ...setters,
    closeDrawer() {
      if (window.innerWidth < 600) {
        this.drawer = false;
      }
    }
  }
});
</script>
