<template>
  <div class="cb-message" v-if="message">
    <div class="cb-message--content">
      <div class="cb-message--text">
        <div class="cb-message--text-content" v-html="displayText" />
      </div>
      <caption-text v-if="message.actionContext" actionContext="message.actionContext" text="Help" />
    </div>
  </div>
</template>

<script lang="ts">
import { ChatBotMessage } from "@/service";
import { ThemeStoreInterface } from "@/store/theme/types";
import Vue, { PropType } from "vue";
import { createHelpers } from "vuex-typescript-interface";
import CaptionText from "./CaptionText.vue";
const { mapGetters } = createHelpers<ThemeStoreInterface>();
const getters = mapGetters(["getReplacementText"]);

export default Vue.extend({
  name: "BannerMessage",
  components: {
    CaptionText
  },
  props: {
    message: {
      type: Object as PropType<ChatBotMessage>,
      required: true
    }
  },
  computed: {
    ...getters,
    displayText(): string {
      return this.patternMatcher(this.message.content ?? "");
    }
  },
  methods: {
    patternMatcher(content: string): string {
      return content.replace(/::([^:]+)::/g, (m, s) => {
        return this.getReplacementText(s);
      });
    }
  }
});
</script>

<style lang="scss" scoped>
.cb-message--text {
  padding: 5px 20px;
  border-radius: 6px;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.6;
  position: relative;
  -webkit-font-smoothing: subpixel-antialiased;
  .cb-message--text-content {
    margin: 4px 0;
    :deep(p) {
      margin-bottom: 0px;
    }
  }
}
</style>
