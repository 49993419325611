<template>
  <action :action="action" showText xshowText="!event" v-on="$listeners">
    <template v-slot:default="{ show }">
      <div v-if="show && action.choices">
        <v-expand-transition>
          <div class="chat-bot-action-buttons vertical" v-if="!event">
            <v-btn
              class="chat-bot-button"
              text
              v-for="(c, i) in action.choices"
              :key="i"
              @click="click(i)"
              v-text="c.name"
              color="primary"
            />
          </div>
        </v-expand-transition>
      </div>
    </template>
  </action>
</template>

<script lang="ts">
import { ChatBotAction, ChatBotEvent } from "@/service";
import Vue, { PropType } from "vue";
import Action from "./Action.vue";

export default Vue.extend({
  name: "ChooseOneActionComponent",
  components: {
    Action
  },
  props: {
    action: {
      type: Object as PropType<ChatBotAction>,
      required: true
    }
  },
  data: () => ({
    event: undefined as ChatBotEvent | undefined
  }),
  methods: {
    click(index: number): void {
      this.event = {
        type: "respond",
        ts: Date.now(),
        clientData: this.action.clientData,
        // action: this.action,
        responses: this.action.choices ? [this.action.choices[index].value] : []
      };
      this.$emit("callback", this.event);
    }
  }
});
</script>

<style lang="scss" scoped>
// There is some issue with scoped styles in slotes with v-if
.chat-bot-action-buttons {
  display: flex;
  &.horizontal {
    flex-direction: row;
    justify-content: space-evenly;
  }
  &.vertical {
    flex-direction: column;
    align-items: center;
    .chat-bot-button {
      margin-bottom: 4px;
    }
  }
}
</style>
