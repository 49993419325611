/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DiagnosisRecordItem } from '../models/DiagnosisRecordItem';
import type { PreviewExpertItem } from '../models/PreviewExpertItem';
import type { PreviewStateItem } from '../models/PreviewStateItem';
import { request as __request } from '../core/request';

export class QuerySimulatorService {

    /**
     * Find Effective Diagnoses for a Query
     * Returns one or more matching records. Lookup by PlanId and GrantId.
     * @result any successful operation
     * @throws ApiError
     */
    public static async getEffectiveDiagnoses({
        planId,
        grantId,
    }: {
        /** Plan Record ID **/
        planId: number,
        /** Grant Record ID **/
        grantId: number,
    }): Promise<{
        data?: Array<DiagnosisRecordItem>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/querySimulator/effectiveDiagnoses/${planId}/${grantId}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Return a structure indicating all the states, associated pools and experts for the query simulator
     * Returns one or more matching records. Lookup by PlanId, GrantId, and Diagnosis.
     * @result any successful operation
     * @throws ApiError
     */
    public static async getPoolsPreview({
        planId,
        grantId,
        dxId,
    }: {
        /** Plan Record ID **/
        planId: number,
        /** Grant Record ID **/
        grantId: number,
        /** Diagnosis Record ID **/
        dxId: number,
    }): Promise<{
        step?: {
            data?: Array<PreviewStateItem>,
        },
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/querySimulator/poolsPreview/${planId}/${grantId}/${dxId}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Return a list of experts
     * CSR or Plan Membership Required.  Returns one or more matching records.
     * @result any successful operation
     * @throws ApiError
     */
    public static async getFacultyList({
        planId,
        grantId,
        dxId,
    }: {
        /** Plan Record ID **/
        planId: number,
        /** Grant Record ID **/
        grantId: number,
        /** Diagnosis Record ID **/
        dxId: number,
    }): Promise<{
        data?: Array<PreviewExpertItem>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/querySimulator/faculty/${planId}/${grantId}/${dxId}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

}