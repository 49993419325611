/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Log Field Change
 */
export type LogFieldChangeItem = {
    /**
     * Record Unique ID
     */
    id?: number;
    /**
     * Reference Record Unique ID
     */
    refId?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    created?: number;
    /**
     * Reference Table
     */
    refTable?: LogFieldChangeItem.refTable;
    /**
     * Reference event
     */
    event?: LogFieldChangeItem.event;
    /**
     * Reference Field
     */
    refField?: string;
    /**
     * Text
     */
    text?: string;
    /**
     * Old Value
     */
    oldVal?: string;
    /**
     * New Value
     */
    newVal?: string;
    /**
     * User Id
     */
    userId?: number;
    /**
     * API Response Version
     */
    _ver?: number;
}

export namespace LogFieldChangeItem {

    /**
     * Reference Table
     */
    export enum refTable {
        UNKNOWN = 'unknown',
        QUERY_HEADER = 'query_header',
        USER = 'user',
        GRANT_PROGRAM = 'grant_program',
        GRANT_PROGRAM_DIAGNOSIS = 'grant_program_diagnosis',
        GRANT_PROGRAM_DIAG_DOMAINS = 'grant_program_diag_domains',
        GRANT_PROGRAM_POOLS = 'grant_program_pools',
        POOLS = 'pools',
        POOLS_PLANS_HASH = 'pools_plans_hash',
        POOLS_EXPERTS_HASH = 'pools_experts_hash',
        POOLS_DIAGNOSIS_HASH = 'pools_diagnosis_hash',
        MEMBERSHIP_PLAN = 'membership_plan',
        PLAN_GRANT_HASH = 'plan_grant_hash',
        MEMBERSHIP_PLAN_LINKS = 'membership_plan_links',
        MEMBERSHIP_PLAN_SURGE = 'membership_plan_surge',
        MEMBERSHIP_CENSUS = 'membership_census',
        USER_INVITE = 'user_invite',
        ADDRESS = 'address',
        PHONE = 'phone',
        NOTIFICATION_PREFERENCE = 'notification_preference',
        USER_BIO = 'user_bio',
        USER_AWAY = 'user_away',
        CANCER_SPECIALIZATIONS = 'cancer_specializations',
        MASTER_CANCER_TYPE = 'master_cancer_type',
        MASTER_CANCER_GROUP = 'master_cancer_group',
        DIAGNOSIS_DOMAIN = 'diagnosis_domain',
    }

    /**
     * Reference event
     */
    export enum event {
        FIELD_CHANGE = 'field_change',
        QUERY_CREATE = 'query_create',
        LOGIN = 'login',
        LOGIN_AS = 'login_as',
        ENTITY_ENABLE_DISABLE = 'entity_enable_disable',
        UPDATE = 'update',
        CREATE = 'create',
        DELETE = 'delete',
    }


}
