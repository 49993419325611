<template>
  <action :action="action" showText v-on="$listeners">
    <template v-slot:default="{ show }">
      <div v-if="show && !event">
        <v-expand-transition>
          <div class="d-flex flex-row align-baseline justify-end">
            <v-date-selector-with-validation v-model="date" rules="date" />
            <v-btn text :disabled="disabled" @click="click">Select</v-btn>
          </div>
        </v-expand-transition>
      </div>
    </template>
  </action>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from "vue";
import Action from "./Action.vue";
import { VTextField } from "vuetify/lib";
import { ChatBotAction, ChatBotEvent } from "@/service";
import VDateSelectorWithValidation from "@/components/form/VDateSelectorWithValidation.vue";

export default (Vue as VueConstructor<
  Vue & {
    $refs: {
      field: typeof VTextField;
    };
  }
>).extend({
  name: "DateActionComponent",
  components: {
    Action,
    VDateSelectorWithValidation
  },
  props: {
    action: {
      type: Object as PropType<ChatBotAction>,
      required: true
    }
  },
  data: () => ({
    event: undefined as ChatBotEvent | undefined,
    date: 0
  }),
  computed: {
    disabled(): boolean {
      return Number(this.date) < 1;
    }
  },
  methods: {
    click(): void {
      if (!this.disabled) {
        this.event = {
          type: "respond",
          ts: Date.now(),
          clientData: this.action.clientData,
          responses: [String(this.date)]
        };
        this.$emit("callback", this.event);
      }
    }

    // enter(): void {
    //   if (this.text.trim().length >= this.minCount) {
    //     this.event = {
    //       type: "respond",
    //       ts: Date.now(),
    //       // action: this.action,
    //       clientData: this.action.clientData,
    //       responses: [this.text]
    //     };
    //     this.$emit("callback", this.event);
    //   }
    // }
  }
});
</script>
