<template>
  <review-item :tag="tag" v-bind="$attrs">
    <div :class="{'justify-space-around align-center' : true, 'd-flex': !isMobile}">
      <div v-if="!isMobile" class="primary--text t-5">
        <strong>{{ $t("wizard.review." + tag) }}</strong>
      </div>
      <div :class="{'d-flex flex-column' : isMobile}">
        <o-btn @click="$emit('submit')">{{ $t("captions.submitQuery") }}</o-btn>
      </div>
    </div>
  </review-item>
</template>

<script lang="ts">
import Vue from "vue";
import ReviewItem from "./ReviewItem.vue";
import OBtn from "@/framework/OBtn.vue";

export default Vue.extend({
  components: {
    ReviewItem,
    OBtn
  },
  props: {
    tag: {
      type: String,
      required: true
    }
  },
  computed: {
    isMobile(): boolean {
      // True when the viewport is under the breakpoint for mobile behavior
      return this.$vuetify.breakpoint.smAndDown;
    },
  }
});
</script>