<template>
  <o-btn
    role="img"
    aria-hidden="false"
    v-clipboard:copy="content"
    v-clipboard:success="onCopy"
    v-clipboard:error="onError"
    :title="title"
    :aria-label="title"
    :disabled="false"
    :icon="!caption"
    :small="small"
  >
    <v-icon small :class="{'mr-2': !!caption}">$copy</v-icon>
    {{ caption }}
  </o-btn>
</template>

<script lang="ts">
import Vue from "vue";
import OBtn from "@/framework/OBtn.vue";

export default Vue.extend({
  name: "CopyContentBtn",

  components: { OBtn },
  props: {
    content: {
      type: String,
      required: true
    },
    caption: {
      type: String,
      required: false
    },
    title: {
      type: String,
      default: "Copy" // TODO Localize
    },
    small: {
      type: Boolean,
      default: true
    },
    successTxt: {
      type: String,
      default: "Content copied to Clipboard."
    },
    failureTxt: {
      type: String,
      default: "Could not copy content to clipboard."
    }
  },
  methods: {
    async copy() {
      try {
        await navigator.clipboard.writeText(this.content);
        this.onCopy({});
      } catch(_) {
        this.onError({});
      }
    },
    onCopy(_: object) {
      this.$emit("click");
      this.$eventBus.$emit("snackbar", { text: this.successTxt, color: "success" });
    },
    onError(_: object) {
      this.$emit("click");
      this.$eventBus.$emit("snackbar", { text: this.failureTxt, color: "error" });
    }
  }
});
</script>
