import { RouteMeta } from "../types";

// All of these routes have this meta setup in common
const meta: RouteMeta = {
  disallowAuthed: true,
  appBar: "none",
  appClass: ""
};

export default [
  // Invitees
  {
    path: "/invitee/:uuid",
    name: "InviteeLandingPage",
    component: () => import(/* webpackChunkName: "members" */ "@/views/Invitees/InviteeLandingPage.vue"),
    props: true,
    meta
  },
  {
    path: "/invitee/session/:uuid/:token",
    name: "InviteeSession",
    component: () => import(/* webpackChunkName: "members" */ "@/views/Invitees/InviteeSessionPage.vue"),
    props: true,
    meta
  }
];
