import _Vue from "vue";
import vuetify from "./vuetify";

// install $cofirm to vue instances
// Usage
//     $alert(message: string, options?: AlertDialogOptions): Promise<void>;

import Alert from "@/components/util/Alert.vue";

export interface AlertDialogOptions {
  dismissText?: string;
  dismissColor?: string;
  dismissTextFlat?: boolean;
  color?: string;
  icon?: string;
  message?: string;
  persistent?: boolean;
  title?: string;
  width?: number;
}

export function AlertPlugin(Vue: typeof _Vue, options?: AlertDialogOptions): void {
  const ctor = Alert.extend({ vuetify });
  const defaultOpts: AlertDialogOptions = options || {};

  function createDialogCmp(options: AlertDialogOptions): Promise<boolean> {
    const container = document.querySelector("[data-app=true]") || document.body;
    return new Promise(resolve => {
      const cmp = new ctor(
        Object.assign(
          {},
          {
            propsData: Object.assign({}, defaultOpts, options),
            destroyed: () => {
              container.removeChild(cmp.$el);
              resolve(cmp.$data.value);
            }
          }
        )
      );
      container.appendChild(cmp.$mount().$el);
    });
  }
  function show(message: string, options: AlertDialogOptions = {}): Promise<boolean> {
    options.message = message;
    return createDialogCmp(options);
  }

  function showWarning(message: string, options: AlertDialogOptions = {}): Promise<boolean> {
    let warningOptions: AlertDialogOptions = {
      title: "Warning",
      dismissText: "Close",
      icon: "",
      dismissTextFlat: false
    }
    return show(message, Object.assign(warningOptions, options))
  }

  // do stuff with options
  Vue.prototype.$alert = show;
  Vue.prototype.$warning= showWarning;
}

_Vue.use(AlertPlugin);
