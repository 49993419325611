import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "@/locales/en.json";
// Get english default validation messages
import validations from "vee-validate/dist/locale/en.json";

Vue.use(VueI18n);

/*
function loadLocaleMessages(): LocaleMessages {
  const locales = require.context("../locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: LocaleMessages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}
*/
/*
export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "zh",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages()
});
*/

// This method allows lazy loading og the languages

export const i18n = new VueI18n({
  locale: "en", // set locale
  fallbackLocale: "en",
  messages: { en: { ...en, validations: validations } } // set locale messages
});

// export default i18n;

const loadedLanguages = ["en"]; // our default language that is preloaded

function setI18nLanguage(lang: string) {
  i18n.locale = lang;
  // axios.defaults.headers.common["Accept-Language"] = lang;
  // document.querySelector("html").setAttribute("lang", lang);
  return lang;
}

export async function loadLanguageAsync(lang: string): Promise<string> {
  // If the same language
  if (i18n.locale === lang) {
    //return Promise.resolve(setI18nLanguage(lang));
    return setI18nLanguage(lang);
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    // return Promise.resolve(setI18nLanguage(lang));
    return setI18nLanguage(lang);
  }

  // If the language hasn't been loaded yet
  const messages = await import(/* webpackChunkName: "lang-[request]" */ `@/locales/${lang}.json`);
  const validationMessages = await import(
    /* webpackChunkName: "validation-lang-[request]" */ `vee-validate/dist/locale/${lang}.json`
  );
  i18n.setLocaleMessage(lang, { ...messages.default, validations: validationMessages.default });
  loadedLanguages.push(lang);
  return setI18nLanguage(lang);
}
