import { UserType } from "@/config";

export default [
  {
    path: "/admin/queries",
    name: "QueryIndex",
    component: () => import(/* webpackChunkName: "queries" */ "@/views/admin/queries/QueryIndex.vue"),
    children: [
      {
        name: "QueryDetail",
        path: ":id",
        component: () => import(/* webpackChunkName: "discussion" */ "@/views/Discussion/Discussion.vue"),
        props: true
      },
      {
        name: "QuerySummary",
        path: ":queryId",
        component: () => import(/* webpackChunkName: "queries" */ "@/views/admin/queries/QuerySummary.vue"),
        props: true
      }
    ],
    props: true,
    meta: { allowedUsers: [UserType.CSR] }
  }
];
