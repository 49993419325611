import { render, staticRenderFns } from "./ReviewItemSimple.vue?vue&type=template&id=60e94b45&scoped=true"
import script from "./ReviewItemSimple.vue?vue&type=script&lang=ts"
export * from "./ReviewItemSimple.vue?vue&type=script&lang=ts"
import style0 from "./ReviewItemSimple.vue?vue&type=style&index=0&id=60e94b45&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60e94b45",
  null
  
)

export default component.exports