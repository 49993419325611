import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { OpenAPI } from "@/service";
import { i18n } from "./plugins/i18n";
import "./plugins/vee-validate";
import "./plugins/confirm";
import "./plugins/alert";
import "./plugins/clipboard";
import "./plugins/showdown";
import "./plugins/infinite-loading";
import "./plugins/eventbus";
import "./plugins/prismic";
import "./plugins/openreplay";
import "./plugins/globals";
import "./plugins/dirtycheck";
import "./plugins/matomo";
import "./plugins/lightBox";

import { RequestHookParams } from "./service/core/OpenAPI";

Vue.config.productionTip = false;

OpenAPI.BASE = process.env.VUE_APP_API_BASE_URI as string;

if (process.env.NODE_ENV === "development") {
  OpenAPI.REQUEST_HOOK = async ({ url, options }: RequestHookParams) => {
    return {
      url: /\?/.test(url) ? `${url}&XDEBUG_TRIGGER=PHPSTORM` : `${url}?XDEBUG_TRIGGER=PHPSTORM`,
      options
    };
  };
}

OpenAPI.RESPONSE_HOOK = async r => {
  // Could use headers.get('X-Cen-Status') for logged out status or headers.get('X-Cen-Token-Update') to replace the token
  const result = r.result;
  // const status = (r.response as Response).headers.get("X-Cen-Status");
  const status = r.response?.headers.get("X-Cen-Status");
  if (status == "session-terminated") {
    console.log("Session terminated!");
    await store.dispatch("cacheUserId");
    await store.dispatch("resetCredentials");
    await store.dispatch("initialize");
    store.commit("setSnackbar", i18n.t("auth.loggedOut") as string);
    // Massage result as this error is handled
    return {
      url: result.url,
      ok: true,
      status: 200,
      statusText: "OK",
      body: null
    };
  }
  const update = (r.response as Response).headers.get("X-Cen-Token-Update");
  if (update) {
    store.dispatch("updateToken", { token: update });
  }
  const version = (r.response as Response).headers.get("X-Cen-Server-Version");

  if (version) {
    store.commit("setServerVersion", version);
  }
  return result;
};

store.dispatch("initialize").then(() => {
  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App),
    // Initailize store on creation
    beforeCreate(): void {
      // Not sure why the this fails type inspection
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      //(this.$store as any).commit("initStore");
      //store.dispatch("initialize");
      // store.commit("initStore");
    }
  }).$mount("#app");
});
