/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Query Invite Item for Query INteraction
 */
export type QueryInviteItem = {
    /**
     * API Response Version
     */
    _ver?: number;
    /**
     * Record Unique ID
     */
    id?: number;
    /**
     * type
     */
    type?: QueryInviteItem.type;
    /**
     * Query Status
     */
    status?: QueryInviteItem.status;
    /**
     * Invite Name
     */
    name?: string;
    /**
     * Email
     */
    email?: string;
    /**
     * phone
     */
    phone?: string;
    /**
     * PIN
     */
    pin?: string;
    /**
     * Unique ID
     */
    uuid?: string;
    /**
     * Invitation Link
     */
    link?: string;
}

export namespace QueryInviteItem {

    /**
     * type
     */
    export enum type {
        LOVEDONE = 'lovedone',
        SUBJECT = 'subject',
        PROVIDER = 'provider',
        POA = 'poa',
        BLINDED_VOICE_UP = 'blinded_voice_up',
        BLINDED_VOICE_DOWN = 'blinded_voice_down',
        BLINDED_VOICE_NONE = 'blinded_voice_none',
        BLINDED_VOICE_MUTE = 'blinded_voice_mute',
    }

    /**
     * Query Status
     */
    export enum status {
        ACTIVE = 'active',
        INACTIVE = 'inactive',
        HASJOINED = 'hasjoined',
    }


}
