/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InviteeSessionStatusInfo } from '../models/InviteeSessionStatusInfo';
import { request as __request } from '../core/request';

export class InviteeService {

    /**
     * Get Invnitee Info
     * Get Invnitee Info
     * @result any successful operation
     * @throws ApiError
     */
    public static async getInviteeInfo({
        key,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
    }): Promise<{
        /**
         * Status of Invitee
         */
        status: string,
        /**
         * Type of query
         */
        queryType: string,
        /**
         * messenger, audio, video
         */
        medium: string,
        /**
         * Type of invitee
         */
        inviteeType: string,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/invitee/${key}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Start Invitee Session
     * Start Invitee Session
     * @result any successful operation
     * @throws ApiError
     */
    public static async startInviteeSession({
        key,
        requestBody,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
        /** Start Session **/
        requestBody: {
            /**
             * User Name
             */
            name: string,
            /**
             * User Email
             */
            email: string,
            terms?: {
                /**
                 * CMD Document Id
                 */
                cmsId?: string,
                /**
                 * CMS Document Language
                 */
                language: string,
                /**
                 * CMS Document Last Published
                 */
                updated: string,
            },
        },
    }): Promise<{
        /**
         * Session Token
         */
        token: string,
    }> {
        const result = await __request({
            method: 'POST',
            path: `/invitee/${key}`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Get Invitee Status
     * Get Invitee Status
     * @result InviteeSessionStatusInfo successful operation
     * @throws ApiError
     */
    public static async inviteeSessionStatus({
        key,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
    }): Promise<InviteeSessionStatusInfo> {
        const result = await __request({
            method: 'GET',
            path: `/invitee/${key}/status`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

}