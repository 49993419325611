import Vue from "vue";
import router from "@/router";
// import VueMatomo from "vue-matomo"; # Throws type error

const VueMatomo = require("vue-matomo").default;

const siteId = Number(process.env.VUE_APP_MATOMO_PIWIK_SITE_ID);
const siteUrl = process.env.VUE_APP_MATOMO_PIWIK_SITE_URL as string;

Vue.use(VueMatomo, {
  // Configure your matomo server and site by providing
  host: siteUrl,
  siteId: siteId,

  // Changes the default .js and .php endpoint's filename
  // Default: 'matomo'
  trackerFileName: 'matomo',

  // Enables automatically registering pageviews on the router
  router: router,

  // Enables link tracking on regular links. Note that this won't
  // work for routing links (ie. internal Vue router links)
  // Default: true
  enableLinkTracking: true,

  // Whether to track the initial page view
  // Default: true
  trackInitialView: true,

  // Tell Matomo the website domain so that clicks on these domains are not tracked as 'Outlinks'
  // Default: undefined, example: '*.example.com'
  domains: ['*.my.cancerexpertnow.com','*.cancerexpertnow.com', '*.mdopinions.com', '*.noeticinsight.com'],

  // Set this to include crossorigin attribute on the matomo script import
  // Default: undefined, possible values : 'anonymous', 'use-credentials'
  crossOrigin: undefined,
});