/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum userPermissions {
    CSR_SUPER = 'csr_super',
    CSR_ADMIN = 'csr_admin',
    LOGINAS = 'loginas',
    LOGINAS_SUPPORT = 'loginas_support',
    LOGINAS_TEST = 'loginas_test',
    CSR = 'csr',
}