<comment>
    Two column view. On wide screens the coluns are independently scrollable (or have a srollable component).
       In this case both columns are fixed to the viewport. Inner views determine scrolling behavior
    On mobile the right columns falls below the left (natural order0 and all scroll together
</comment>

<template>
  <div
    class="tcf align-stretch"
    :style="layoutStyle"
    :class="{
      'tcf-mobile': isMobile
    }"
  >
    <div class="mobile-helper">
      <div class="tcf-left">
        <slot name="left" :isMobile="isMobile" />
      </div>
      <div class="tcf-right" :class="{ 'mt-4': isMobile }">
        <slot name="right" :isMobile="isMobile" :on="$emit" />
      </div>
      <slot name="absolute" />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  props: {
    mobileWidth: {
      type: [String, Number],
      required: false
    },
  },
  computed: {
    isMobile(): boolean {
      // True when the viewport is under the breakpoint for mobile behavior
      if (this.mobileWidth) {
        return this.$vuetify.breakpoint.width < Number(this.mobileWidth);
      }
      return this.$vuetify.breakpoint.smAndDown;
    },
    layoutStyle(): Record<string, string | number> {
      return {
        left: `${this.$vuetify.application.left}px`,
        top: `${this.$vuetify.application.top}px`,
        bottom: `${this.$vuetify.application.bottom}px`,
        right: `${this.$vuetify.application.right}px`
      };
    }
  }
});
</script>
<style lang="scss" scoped>
.tcf {
  overflow: hidden;
  position: fixed;
  flex-direction: row;
  transform: scale(1); // Set this element as anchor for "fixed" children

  .tcf-left {
    position: fixed;
    width: 50%;
    left: 0;
    top: 0;
    bottom: 0;

    padding-top: 16px;
    padding-left: 16px;
    padding-right: 0;
    padding-bottom: 24px;
  }

  .tcf-right {
    position: fixed;
    width: 50%;
    right: 0;
    top: 0;
    bottom: 0;

    padding-top: 16px;
    padding-left: 0;
    padding-right: 16px;
    padding-bottom: 24px;
  }

  &.tcf-mobile {
    //overflow: inherit;
    // position: relative;
    display: flex;
    flex-direction: column;
    align-content: stretch;
    padding: 16px 0 0 0;

    // transform: inherit; // Set this element as anchor for "fixed" children

    // Bolie helper allows contents to scroll inside the application frame
    .mobile-helper {
      overflow-y: auto;
      flex: 1;
    }

    .tcf-left,
    .tcf-right {
      position: static;
      width: 100%;
      padding-top: 0;
      padding-right: 8px;
      padding-left: 8px;
      padding-bottom: 6px;
      min-width: 360px;
    }
  }
}
</style>
