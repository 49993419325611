/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * TagsTarget
 */
export type TagsTargetRecordItem = {
    /**
     * Record Unique ID
     */
    id?: number;
    /**
     * Created by UserId
     */
    userId?: number;
    /**
     * tag Id
     */
    tagId?: number;
    /**
     * tag target Id
     */
    targetId?: number;
    /**
     * Target Type
     */
    targetType?: TagsTargetRecordItem.targetType;
    /**
     * Flag for record being active
     */
    active?: boolean;
    /**
     * Current user allowed to delete?  Helper field.  Confirmation logic in backend.
     */
    userCanDelete?: boolean;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    created?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    updated?: number;
    /**
     * Scope of the tag
     */
    scope?: TagsTargetRecordItem.scope;
    /**
     * API Response Version
     */
    _ver?: number;
}

export namespace TagsTargetRecordItem {

    /**
     * Target Type
     */
    export enum targetType {
        USER = 'user',
        PLAN = 'plan',
        POOL = 'pool',
        GRANT = 'grant',
        QUERY = 'query',
        OTHER = 'other',
    }

    /**
     * Scope of the tag
     */
    export enum scope {
        USER = 'user',
        TEAM = 'team',
        GLOBAL = 'global',
        SCOPE = 'scope',
    }


}
