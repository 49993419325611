<template>
  <password-reset-frame :loading="loading" :title="$t('captions.login')">
    <v-card-subtitle>
      Select your Identity Provider
    </v-card-subtitle>
    <v-container class="pt-0">
      <v-row>
        <v-col>
          <div v-for="app in apps" :key="app.key" class="mb-4">
            <common-button @click="doLogin(app)">{{ app.name }}</common-button>
          </div>
          <div v-if="errorText">
            <div col="12" class="text-center error--text">
              {{ errorText }}
            </div>
          </div>
          <div>
            <p>You will be logged in through your Microsoft 365 Account.</p>
            <p>You may be asked to authenticate.</p>
            <p><v-btn text :to="{ name: 'Login' }">Back to Login</v-btn></p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </password-reset-frame>
</template>

<script lang="ts">
// TODO Use prepended or appended icons in form fields
// TODO Colors
import Vue from "vue";
import PasswordResetFrame from "@/components/auth/PasswordResetFrame.vue";
import CommonButton from "@/components/form/CommonButton.vue";

import { createHelpers } from "vuex-typescript-interface";
import { RootStoreInterface } from "@/store/types";
import { AuthService, OAuthAppsItem } from "@/service";
const { mapActions, mapGetters } = createHelpers<RootStoreInterface>();

const actions = mapActions(["msalSignIn"]);
const getters = mapGetters(["errorText"]);

export default Vue.extend({
  components: {
    PasswordResetFrame,
    CommonButton
  },
  data: () => ({
    apps: [] as OAuthAppsItem[],
    loading: false,

    localeFields: { _link_: '<a href="https://www.cancerexpertnow.com" target="_blank">www.cancerexpertnow.com</a>' }
  }),
  methods: {
    async doLogin(app: OAuthAppsItem): Promise<void> {
      this.loading = true;
      try {
        await this.msalSignIn({ app });
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    // test() {
    //   this.$confirm("Something", { title: "Warning" });
    // },

    ...actions
  },
  computed: {
    ...getters
  },
  async created() {
    const d = await AuthService.getOauthApps();
    this.apps = d.data;
  }
});
</script>

<style lang="scss">
.v-card {
  &.login-card-x {
    background-image: url("~@/assets/footer.png") !important;
    background-repeat: no-repeat !important;
    background-size: 100% 48px !important;
    background-position: bottom center !important;
  }
  .footer-img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 48px;
    z-index: 4;

    .v-image__image {
      background-size: 100% 100% !important;
    }
  }
}

.login-elem {
  max-width: 960px;
}
</style>
