<template>
  <action :action="action" showText v-on="$listeners">
    <template v-slot:default="{ show }">
      <v-expand-transition>
        <div v-if="show && !event" class="d-flex flex-row align-baseline justify-end">
          <birth-date-picker v-model="date" />
          <v-btn text :disabled="disabled" @click="click">Select</v-btn>
        </div>
      </v-expand-transition>
    </template>
  </action>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from "vue";
import Action from "./Action.vue";
import { VTextField } from "vuetify/lib";
import { ChatBotAction, ChatBotEvent } from "@/service";
import BirthDatePicker from "@/components/form/BirthDatePicker.vue";
export default (Vue as VueConstructor<
  Vue & {
    $refs: {
      field: typeof VTextField;
    };
  }
>).extend({
  name: "DobActionComponent",
  components: {
    Action,
    BirthDatePicker
  },
  props: {
    action: {
      type: Object as PropType<ChatBotAction>,
      required: true
    }
  },
  data: () => ({
    event: undefined as ChatBotEvent | undefined,
    date: null as string | null
  }),
  computed: {
    disabled(): boolean {
      return Number(this.date) < 1;
    }
  },
  methods: {
    click(): void {
      if (!this.disabled && this.date !== null) {
        this.event = {
          type: "respond",
          ts: Date.now(),
          clientData: this.action.clientData,
          responses: [this.date ?? ""]
        };
        this.$emit("callback", this.event);
      }
    }

    // enter(): void {
    //   if (this.text.trim().length >= this.minCount) {
    //     this.event = {
    //       type: "respond",
    //       ts: Date.now(),
    //       // action: this.action,
    //       clientData: this.action.clientData,
    //       responses: [this.text]
    //     };
    //     this.$emit("callback", this.event);
    //   }
    // }
  },
  created() {
    if (this.action.responses?.length && this.action.responses[0]) {
      // TODO CHeck this format
      this.date = this.action.responses[0];
    }
  }
});
</script>
