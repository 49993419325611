import { i18n } from "../plugins/i18n";

// Configs
export type CheckPasswordConfig = {
  allowPassphrases?: boolean;
  maxLength?: number;
  minLength?: number;
  minPhraseLength?: number;
  minOptionalTestsToPass?: number;
};

type CPConfig = {
  allowPassphrases: boolean;
  maxLength: number;
  minLength: number;
  minPhraseLength: number;
  minOptionalTestsToPass: number;
};
// Default settings
const defaults = {
  allowPassphrases: false,
  maxLength: 128,
  minLength: 12,
  minPhraseLength: 20,
  minOptionalTestsToPass: 4
};

export type CheckPasswordResult = {
  errors: Array<string>;
  failedTests: Array<number>;
  passedTests: Array<number>;
  requiredTestErrors: Array<string>;
  optionalTestErrors: Array<string>;
  isPassphrase: boolean;
  strong: boolean;
  optionalTestsPassed: number;
};

// An array of required tests. A password *must* pass these tests in order
// to be considered strong.
const required = [
  // enforce a minimum length
  function(password: string, cfg: CPConfig): string | undefined {
    if (password.length < cfg.minLength) {
      return i18n.t("passwordStrength.minLength", { _value_: cfg.minLength }) as string;
    }
  },

  // enforce a maximum length
  function(password: string, cfg: CPConfig): string | undefined {
    if (password.length > cfg.maxLength) {
      return i18n.t("passwordStrength.maxLength", { _value_: cfg.maxLength }) as string;
    }
  }

  // forbid repeating characters
  // function(password: string, _cfg: CPConfig): string | undefined {
  //   if (/(.)\1{2,}/.test(password)) {
  //     return i18n.t("passwordStrength.repeats") as string;
  //   }
  // }
];

// An array of optional tests. These tests are "optional" in two senses:
//
// 1. Passphrases (passwords whose length exceeds
//    this.configs.minPhraseLength) are not obligated to pass these tests
//    provided that this.configs.allowPassphrases is set to Boolean true
//    (which it is by default).
//
// 2. A password need only to pass this.configs.minOptionalTestsToPass
//    number of these optional tests in order to be considered strong.
const optional = [
  // require at least one lowercase letter
  function(password: string) {
    if (!/[a-z]/.test(password)) {
      return i18n.t("passwordStrength.lowercase") as string;
    }
  },

  // require at least one uppercase letter
  function(password: string) {
    if (!/[A-Z]/.test(password)) {
      return i18n.t("passwordStrength.uppercase") as string;
    }
  },

  // require at least one number
  function(password: string) {
    if (!/[\d]/.test(password)) {
      return i18n.t("passwordStrength.number") as string;
    }
  },

  // require at least one special character
  function(password: string) {
    if (!/[^A-Za-z0-9]/.test(password)) {
      return i18n.t("passwordStrength.special") as string;
    }
  }
];

export function CheckPassword(password: string, config: CheckPasswordConfig = {}): CheckPasswordResult {
  const cfg = Object.assign({}, defaults, config);

  // create an object to store the test results
  const result: CheckPasswordResult = {
    errors: [],
    failedTests: [],
    passedTests: [],
    requiredTestErrors: [],
    optionalTestErrors: [],
    isPassphrase: false,
    strong: true,
    optionalTestsPassed: 0
  };

  // Always submit the password/passphrase to the required tests
  let i = 0;
  required.forEach(function(test) {
    const err = test(password, cfg);
    if (typeof err === "string") {
      result.strong = false;
      result.errors.push(err);
      result.requiredTestErrors.push(err);
      result.failedTests.push(i);
    } else {
      result.passedTests.push(i);
    }
    i++;
  });

  // If configured to allow passphrases, and if the password is of a
  // sufficient length to consider it a passphrase, exempt it from the
  // optional tests.
  if (cfg.allowPassphrases === true && password.length >= cfg.minPhraseLength) {
    result.isPassphrase = true;
  }

  if (!result.isPassphrase) {
    let j = required.length;
    optional.forEach(function(test) {
      const err = test(password);
      if (typeof err === "string") {
        result.errors.push(err);
        result.optionalTestErrors.push(err);
        result.failedTests.push(j);
      } else {
        result.optionalTestsPassed++;
        result.passedTests.push(j);
      }
      j++;
    });
  }

  // If the password is not a passphrase, assert that it has passed a
  // sufficient number of the optional tests, per the configuration
  if (!result.isPassphrase && result.optionalTestsPassed < cfg.minOptionalTestsToPass) {
    result.strong = false;
  }

  // return the result
  return result;
}
