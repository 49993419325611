<template>
  <ValidationProvider
    :name="label"
    :bails="true"
    :rules="{ required: true, password: validate }"
    :debounce="300"
    v-slot="{ errors, valid }"
  >
    <v-text-field
      v-model="innerValue"
      persistent-hint
      :type="showPassword ? 'text' : 'password'"
      :error-messages="errors"
      :success="innerValue && String(innerValue).length && valid"
      :label="$t(`fields.${label}`)"
      :placeholder="$t(`placeholders.${placeholder}`)"
      :append-icon="showPassword ? '$eye' : '$eyeSlash'"
      @click:append="showPassword=!showPassword"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template v-slot:message="{ message }"> <span v-html="message"></span> </template
    ></v-text-field>
  </ValidationProvider>
</template>

<script lang="ts">
import Vue from "vue";
import { ValidationProvider } from "vee-validate";

export default Vue.extend({
  inheritAttrs: false,
  components: {
    ValidationProvider
  },
  data: () => ({
    innerValue: "" as string | number,
    showPassword: false
  }),
  props: {
    // must be included in props
    value: {
      type: [String, Number],
      required: false
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: "password",
      required: false
    },
    validate: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
});
</script>

<style lang="scss" scoped>
:deep(.v-input__icon--append) {
  margin-left: 8px !important;
}
</style>
