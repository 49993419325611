/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Query Expert Record Item
 */
export type QueryExpertRecordItem = {
    /**
     * API Response Version
     */
    _ver?: number;
    /**
     * Record Unique ID
     */
    id?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    created?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    updated?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    lastView?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    lastNotify?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    firstAnswer?: number;
    /**
     * Query Expert Draft Response
     */
    draftResponse?: string;
    /**
     * Flag for show History
     */
    showHistory?: boolean;
    /**
     * Flag for is Expert
     */
    isExpert?: boolean;
    /**
     * Status
     */
    status?: QueryExpertRecordItem.status;
    /**
     * Experts Action for the Invite
     */
    inviteAction?: QueryExpertRecordItem.inviteAction;
    /**
     * reminderStatus
     */
    reminderStatus?: QueryExpertRecordItem.reminderStatus;
    /**
     * csrHandler
     */
    csrHandler?: QueryExpertRecordItem.csrHandler;
    /**
     * acctStatus
     */
    acctStatus?: QueryExpertRecordItem.acctStatus;
    /**
     * Query Header Id
     */
    queryHeaderId?: number;
    /**
     * User Id
     */
    userId?: number;
    /**
     * query State Trans Id
     */
    queryStateTransId?: number;
    /**
     * meeting Host Id
     */
    userBankTransId?: number;
    /**
     * userUniqueId
     */
    userUniqueId?: string;
    /**
     * pseudonym
     */
    pseudonym?: string;
    /**
     * PIN
     */
    pin?: string;
    /**
     * Account Error
     */
    acctError?: string;
    /**
     * Meeting Url
     */
    mtgUrl?: string;
    /**
     * weight Used
     */
    weightUsed?: number;
}

export namespace QueryExpertRecordItem {

    /**
     * Status
     */
    export enum status {
        INVITED = 'invited',
        DECLINED = 'declined',
        CLAIMED = 'claimed',
        MISSEDOUT = 'missedout',
        LATEREVIEW = 'latereview',
        TIMEDOUT = 'timedout',
        CANCELLED = 'cancelled',
        ANSWERED = 'answered',
        FOLLOWUP = 'followup',
        USER = 'user',
        CSR = 'csr',
    }

    /**
     * Experts Action for the Invite
     */
    export enum inviteAction {
        ACCEPT = 'accept',
        DECLINE = 'decline',
        MISSEDOUT = 'missedout',
        CANCELLED = 'cancelled',
        CLOSED = 'closed',
        INVITED = 'invited',
    }

    /**
     * reminderStatus
     */
    export enum reminderStatus {
        NONE = 'none',
        REMINDED = 'reminded',
    }

    /**
     * csrHandler
     */
    export enum csrHandler {
        NONE = 'none',
        ASSIGNED = 'assigned',
        UNASSIGNED = 'unassigned',
        DEACTIVATED = 'deactivated',
    }

    /**
     * acctStatus
     */
    export enum acctStatus {
        NEW = 'new',
        PROCESSED = 'processed',
        ERROR = 'error',
        SKIP = 'skip',
    }


}
