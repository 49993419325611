/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Query Response Record Item
 */
export type QueryResponseRecordItem = {
    /**
     * API Response Version
     */
    _ver?: number;
    /**
     * Record Unique ID
     */
    id?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    created?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    updated?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    dateScored?: number;
    /**
     * Response Text
     */
    text?: string;
    /**
     * Flag for response translated
     */
    translated?: boolean;
    /**
     * Status
     */
    type?: QueryResponseRecordItem.type;
    /**
     * Response Assistant ID
     */
    assistantId?: number;
    /**
     * User Id
     */
    userIdCreated?: number;
    /**
     * User Id
     */
    userId?: number;
    /**
     * query State Trans Id
     */
    queryExpertId?: number;
    /**
     * patient Document Id
     */
    dosId?: number;
    /**
     * response Type
     */
    responseType?: string;
    /**
     * score Model
     */
    scoreModel?: string;
    /**
     * Decimal - Score
     */
    score?: number;
    /**
     * Decimal - score User
     */
    scoreUser?: number;
}

export namespace QueryResponseRecordItem {

    /**
     * Status
     */
    export enum type {
        RESPONSE = 'response',
        INFO = 'info',
        CSRINFO = 'csrinfo',
        CSRHOLD = 'csrhold',
        CSRUPDATE = 'csrupdate',
        CSRUPDATEACCEPT = 'csrupdateaccept',
        CSRUPDATEDECLINE = 'csrupdatedecline',
        PENDEDEXPERT = 'pendedexpert',
        REJECTEDEXPERT = 'rejectedexpert',
        REJECTEDEXPERTRESPONSE = 'rejectedexpertresponse',
        RETRACTEDEXPERT = 'retractedexpert',
        ATTACHMENT = 'attachment',
        ATTACHMENT_REMOVED = 'attachment_removed',
        ATTACHMENT_INHERITED = 'attachment_inherited',
    }


}
