/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Query Invite Record
 */
export type QueryInviteRecordItem = {
    /**
     * API Response Version
     */
    _ver?: number;
    /**
     * Record Unique ID
     */
    id?: number;
    /**
     * Query Header ID
     */
    queryHeaderId?: number;
    /**
     * User ID
     */
    userId?: number;
    /**
     * invited By User Id
     */
    invitedByUserId?: number;
    /**
     * type
     */
    type?: QueryInviteRecordItem.type;
    /**
     * Query Status
     */
    status?: QueryInviteRecordItem.status;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    created?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    updated?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    firstJoined?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    lastJoined?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    priorTime?: number;
    /**
     * Invite Name
     */
    name?: string;
    /**
     * Email
     */
    email?: string;
    /**
     * phone
     */
    phone?: string;
    /**
     * PIN
     */
    pin?: string;
    /**
     * extId
     */
    extId?: string;
    /**
     * Unique ID
     */
    uuid?: string;
    /**
     * Flag for is Mute invite
     */
    mute?: boolean;
    /**
     * Notify Stage
     */
    notifyStage?: number;
}

export namespace QueryInviteRecordItem {

    /**
     * type
     */
    export enum type {
        LOVEDONE = 'lovedone',
        SUBJECT = 'subject',
        PROVIDER = 'provider',
        POA = 'poa',
        BLINDED_VOICE_UP = 'blinded_voice_up',
        BLINDED_VOICE_DOWN = 'blinded_voice_down',
        BLINDED_VOICE_NONE = 'blinded_voice_none',
        BLINDED_VOICE_MUTE = 'blinded_voice_mute',
    }

    /**
     * Query Status
     */
    export enum status {
        ACTIVE = 'active',
        INACTIVE = 'inactive',
        HASJOINED = 'hasjoined',
    }


}
