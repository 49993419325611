<template>
  <o-sheet class="pa-6 secondary--text" style="height: 290px;">
    <div class="t-5 font-weight-bold">{{ $t(`discussion.index.title.queryStats`) }}</div>
    <div>
      <query-stats-item
        v-for="(item, index) in queryStatItems"
        :key="`state-${index}`"
        :title="item.title"
        :icon="item.icon"
        :iconSize="item.iconSize"
        :to="item.to"
        :divider="index != queryStatItems.length - 1"
      />
    </div>
  </o-sheet>
</template>

<script lang="ts">
import Vue from "vue";
import { QueryAggregationItem, QueryService } from "@/service";
import OSheet from "@/framework/OSheet.vue";
import QueryStatsItem from "./QueryStatsItem.vue";

type QueryStatsRecoordItem = {
  title: string,
  icon?: string,
  iconSize?: number,
  to: object
};

export default Vue.extend({
  components: {
    OSheet,
    QueryStatsItem
  },
  data: () => ({
    queryStats: {} as QueryAggregationItem
  }),
  props: {
    userId: {
      type: [String, Number],
      required: true
    },
    isExpert: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    newOpportunitiesCount(): number {
      return this.queryStats?.newOpportunities ?? 0;
    },
    newResponsesCount(): number {
      return this.queryStats?.newResponses ?? 0;
    },
    queryStatItems(): Array<QueryStatsRecoordItem> {
      let stats = [];

      if (this.isExpert) {
        stats.push({
          icon: "$textAttachment",
          title: `${this.queryStats?.requiresAction ?? 0} Requiring Action`,
          to: { name: "Discussions", hash: "#requiresAction" }
        },
        {
          icon: "$envalope",
          iconSize: 20,
          title: `${this.newOpportunitiesCount} New ${this.newOpportunitiesCount == 1 ? 'Opportunity' : 'Opportunities'}`,
          to: { name: "Discussions", hash: "#newOpportunities" }
        });
      }
      stats.push({
        icon: "$textAttachment",
        title: `${this.newResponsesCount} New ${this.newResponsesCount == 1 ? 'Response' : 'Responses'}`,
        to: { name: "Discussions", hash: "#ongoing" }
      });

      return stats;
    }
  },
  methods: {
    async getQueryStats() {
      if (!this.userId) return;
      try {
        let stats = await QueryService.getQueryStatsUser({ id: Number(this.userId) });
        if (stats && stats.queryStats) {
          this.queryStats = stats.queryStats;
        }
      } catch (e) {
        console.log(e);
      }
    }
  },
  mounted() {
    this.getQueryStats();
  }
});
</script>
