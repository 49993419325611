<template>
  <review-item :tag="tag" v-bind="$attrs">
    <div class="d-flex flex-column align-start">
      <div class="primary--text t-5">
        <strong>{{ $t("wizard.review." + tag) }}</strong>
      </div>
      <div :class="{'t-2': true, 'ml-4': !isMobile}" v-html="value" />
    </div>
  </review-item>
</template>

<script lang="ts">
import Vue from "vue";
import ReviewItem from "./ReviewItem.vue";

export default Vue.extend({
  components: {
    ReviewItem
  },
  props: {
    tag: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    }
  },
  computed: {
    isMobile(): boolean {
      // True when the viewport is under the breakpoint for mobile behavior
      return this.$vuetify.breakpoint.smAndDown;
    },
  }
});
</script>
