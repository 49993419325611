/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { meta } from '../models/meta';
import type { UserInviteRecordItem } from '../models/UserInviteRecordItem';
import { request as __request } from '../core/request';

export class UserInviteService {

    /**
     * Find User Invite by ID.
     * Returns matching record
     * @result UserInviteRecordItem successful operation
     * @throws ApiError
     */
    public static async getUserInviteById({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<UserInviteRecordItem> {
        const result = await __request({
            method: 'GET',
            path: `/server/user/invite/${id}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update an existing user invite
     * Update a record based on data given
     * @result UserInviteRecordItem Successful operation
     * @throws ApiError
     */
    public static async updateUserinvite({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** UserInvite object that needs to be updated **/
        requestBody: UserInviteRecordItem,
    }): Promise<UserInviteRecordItem> {
        const result = await __request({
            method: 'PUT',
            path: `/server/user/invite/${id}`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Revoke a user invitation
     * Revokes a user invitation.
     * @result UserInviteRecordItem Successful operation
     * @throws ApiError
     */
    public static async deleteUserInviteById({
        id,
    }: {
        /** Record ID **/
        id: number,
    }): Promise<UserInviteRecordItem> {
        const result = await __request({
            method: 'DELETE',
            path: `/server/user/invite/${id}`,
            errors: {
                400: `Error: Update Failed`,
                401: `Authentication error response`,
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Filterable by user Id, plan Id, master Account Id, status,  firs, last
     * Returns one or more matching records
     * @result any successful operation
     * @throws ApiError
     */
    public static async getUserInviteList({
        userId,
        planId,
        status,
        userType,
        search,
        page = 1,
        pageSize = 10,
        sort,
    }: {
        /** Find records associated with a given User **/
        userId?: number,
        /** Find records associated with a given Plan **/
        planId?: number,
        /** Find records searching by Status **/
        status?: 'new' | 'sent' | 'accepted' | 'declined' | 'cancelled' | 'expired' | 'new_or_sent',
        /** Find records associated with a given User Type **/
        userType?: number,
        /** Find records matching the search string **/
        search?: string,
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
        /** Sort Options - Comma delimited list of key:order. order optional - default ASC  **/
        sort?: string,
    }): Promise<{
        data?: Array<UserInviteRecordItem>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/server/user/invite/list`,
            query: {
                'userId': userId,
                'planId': planId,
                'status': status,
                'userType': userType,
                'search': search,
                'page': page,
                'pageSize': pageSize,
                'sort': sort,
            },
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Create a new user invite
     * Creates a record based on data given
     * @result UserInviteRecordItem Successful operation
     * @throws ApiError
     */
    public static async createUserInvite({
        requestBody,
    }: {
        /** UserInvite object to be created **/
        requestBody: UserInviteRecordItem,
    }): Promise<UserInviteRecordItem> {
        const result = await __request({
            method: 'POST',
            path: `/server/user/invite`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update and reissue existing user invite
     * Update a record based on data given
     * @result UserInviteRecordItem Successful operation
     * @throws ApiError
     */
    public static async reissueUserinvite({
        id,
        requestBody,
    }: {
        /** Record ID **/
        id: number,
        /** UserInvite object that needs to be updated **/
        requestBody: UserInviteRecordItem,
    }): Promise<UserInviteRecordItem> {
        const result = await __request({
            method: 'PUT',
            path: `/server/user/invite/${id}/reissue`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

}