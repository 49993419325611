<template>
  <action :action="action" showText v-on="$listeners" v-resize="onResize">
    <template v-slot:default="{ show }">
      <v-expand-transition>
        <div v-if="show && !event" class="d-flex justify-end">
          <!-- SHow indication if there is text or not and ake ot clickable to activate the dialog -->
          <v-btn color="primary" text @click.stop="showDisclosureDialog = true">Review and Acknowledge</v-btn>
          <cme-info-dialog
            ref="cmeInfoDlg"
            v-model="showDisclosureDialog"
            consent
            :title="action.label || 'CME Info'"
            :fullscreen="smallScreen"
            :docId="cmsPageId"
            :frontMatter="frontMatter"
            :diagnosisId="diagnosisId"
            :grantId="grantId"
            :planId="planId"
            @agreed="onAgreed"
          />
        </div>
      </v-expand-transition>
    </template>
  </action>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from "vue";
import { ChatBotAction, ChatBotEvent } from "@/service";
import CmeInfoDialog from "@/components/Discussion/CmeInfoDialog.vue";
import Action from "./Action.vue";

export default (Vue as VueConstructor<
  Vue & {
    $refs: {
      
    };
  }
>).extend({
  name: "DisclosureActionComponent",
  components: {
    Action,
    CmeInfoDialog
  },
  data: () => ({
    event: undefined as ChatBotEvent | undefined,
    text: "",
    windowHeight: 0,
    showDisclosureDialog: true,
    diagnosisId: undefined as number | undefined,
    grantId: undefined as number | undefined,
    planId: undefined as number | undefined
  }),
  props: {
    action: {
      type: Object as PropType<ChatBotAction>,
      required: true
    }
  },
  computed: {
    smallScreen(): boolean {
      return this.windowHeight < 400;
    },
    cmsPageId(): string | undefined {
      if (this.action.choices && this.action.choices.length) {
        const t = String(this.action.choices[0]).split(/\n/)[0].trim();
        if (t.toLowerCase() == "default") {
          return "default";
        }
        if (t.length < 50 && t.substr(0, 4).toLowerCase() == "cms:") {
          return t.substr(4).toLowerCase();
        }
      }
      return undefined;
    },
    frontMatter(): string | undefined {
      return this.action.choices && this.action.choices.length ? String(this.action.choices[0]) : undefined;
    }
  },
  methods: {
    onResize(): void {
      // TODO This Value is treated differently by mobile safari and mobile chrome. Try to standardize on it
      this.windowHeight = window.innerHeight;
    },
    onAgreed() {
      this.showDisclosureDialog = false;
      this.event = {
        type: "respond",
        ts: Date.now(),
        clientData: this.action.clientData,
        responses: ["1"]
      }
      this.$emit("callback", this.event);
    }
  },
  created() {
    if (this.action.responses?.length) {
      this.text = this.action.responses[0];
    }
    if (this.action.clientData) {
      this.diagnosisId = this.action.clientData.diagnosisId;
      this.grantId = this.action.clientData.grantId;
      this.planId = this.action.clientData.planId;
    }
  }
});
</script>
