<template>
  <review-item :tag="tag" v-bind="$attrs">
    <div class="d-flex flex-column align-start">
      <div class="primary--text t-5">
        <strong>{{ $t("wizard.review." + tag) }}</strong>
      </div>
      <div :class="{'t-1 d-flex flex-row flex-wrap' : true, 'mx-4': !isMobile, 'mr-2': isMobile}">
        <div v-for="(t, index) in times" :key="index" class="mr-2">
          {{ t }}{{ index == times.length - 1 ?  "" : ', ' }}
        </div>
      </div>
    </div>
  </review-item>
</template>

<script lang="ts">
import Vue from "vue";
import ReviewItem from "./ReviewItem.vue";

export default Vue.extend({
  components: {
    ReviewItem
  },
  props: {
    tag: {
      type: String,
      required: true
    },

    times: {
      type: Array,
      required: true
    }
  },
  computed: {
    isMobile(): boolean {
      // True when the viewport is under the breakpoint for mobile behavior
      return this.$vuetify.breakpoint.smAndDown;
    },
  }
});
</script>
