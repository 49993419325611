/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Communication Log Item
 */
export type CommLogRecordItem = {
    /**
     * API Response Version
     */
    _ver?: number;
    /**
     * Record Unique ID
     */
    id?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    created?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    updated?: number;
    /**
     * Timestamp - if sent is blank returns created
     */
    sent?: number;
    /**
     * Query Header Text
     */
    body?: string;
    /**
     * Log Reference Type
     */
    contextTable?: string;
    /**
     * Log Reference ID
     */
    contextId?: number;
    /**
     * Error Message
     */
    error?: string;
    /**
     * Recipient Name
     */
    name?: string;
    /**
     * Subject
     */
    subject?: string;
    /**
     * Notification Destination
     */
    destination?: string;
    /**
     * Notification Sender Name
     */
    sentName?: string;
    /**
     * Notification Sender Address
     */
    sentAddress?: string;
    /**
     * Type
     */
    type?: CommLogRecordItem.type;
    /**
     * Status
     */
    status?: CommLogRecordItem.status;
}

export namespace CommLogRecordItem {

    /**
     * Type
     */
    export enum type {
        EMAIL = 'email',
        SMS = 'sms',
        APP = 'app',
    }

    /**
     * Status
     */
    export enum status {
        NEW = 'new',
        SENT = 'sent',
        VERIFIED = 'verified',
        ERROR = 'error',
    }


}
